import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../Components/Buttons/primary-button";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken";

import { useDeals, useStakePay3PaymentMonths } from "../../CustomHooks/Queries";

import { PersonWithKeyIllustration } from "../../Assets/Images";

export default function PayRentWithStakePay3() {
  const navigate = useNavigate();

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const sfid = deals?.data?.data?.currentDeal?.sfid;

  const { isLoading: isLoadingPaymentMonths, data: paymentMonths } = useStakePay3PaymentMonths(sfid, {
    enabled: !isLoadingAuthToken && !!sfid,
  });

  const paymentMonthsList = paymentMonths?.data?.data?.paymentMonthDetail;

  return isLoadingAuthToken || isLoadingDeals || isLoadingPaymentMonths ? (
    <></>
  ) : (
    <div className="pay-rent-with-stake mt-6">
      <img className="person-with-key-illustration" src={PersonWithKeyIllustration} alt="" />
      <div className="content">
        <h3 className="title mb-2">Easier to pay rent, easier to get Cash Back!</h3>
        <span className="description mb-6">
          StakePay is here, making your Stake experience better than ever. Pay on time and get instant Cash Back.
        </span>
        <PrimaryButton
          className="blue-theme mt-6"
          onClick={navigate.bind(this, `/renting/stakepay-3${paymentMonthsList?.length > 0 ? "/manage" : ""}`)}
        >
          {paymentMonthsList?.length === 0 ? "View New Payment Experience" : "Manage rent payment"}
        </PrimaryButton>
      </div>
    </div>
  );
}
