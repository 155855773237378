import { ConnectedAccounts } from "../../../Pages/Connected Accounts";

import { modalVariants } from "../../../Utils/constants";

export default function renderSelectConnectedAccounts(modalContext, props) {
  const { updateModalComponent, setModalTitle, setModalClassName, setModalVariant, pushModalStack } = modalContext;

  pushModalStack(() => renderSelectConnectedAccounts(modalContext, props));
  updateModalComponent(() => <ConnectedAccounts presentedForSelection {...props} />);
  setModalTitle("Connected Accounts");
  setModalClassName("rendering-page-content");
  setModalVariant(modalVariants.formDialog);
}
