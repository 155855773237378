import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useFormContext } from "react-hook-form";

import SetupPayment from "./setup-payment";

import renderCancelRentPayment from "../../Organisms/Modals/Stake Pay 3/cancel-rent-payment";

import withFormProvider from "../../HOC/withFormProvider";

import { useStakePay3PaymentDetailsForSelectedMonth } from "../../CustomHooks/Queries";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext";

import { formatDate } from "../../Utils/helpers";
import { fullMonthFormat } from "../../Utils/constants";

import { ArrowLeftCircle } from "../../Assets/Icons";

const EditPayment = () => {
  const [rentPaymentItem, setRentPaymentItem] = useState(null);

  const { setAppUtilityProps } = useAppUtilityContext();

  const navigate = useNavigate();

  const { paymentId } = useParams();

  const { setValue } = useFormContext();

  const modalContext = useModalContext();

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const isAutoPay = useMemo(() => Boolean(new URLSearchParams(window.location.search).get("autopay")) === true, []);

  const { isLoading: isLoadingPaymentDetailsForSelectedMonth, data: paymentDetailsForSelectedMonth } =
    useStakePay3PaymentDetailsForSelectedMonth(paymentId, {
      enabled: !isLoadingAuthToken && !!paymentId,
      onSuccess: ({ data }) => {
        setRentPaymentItem(data?.data?.rentPaymentItems);

        if (data?.data?.paymentMethod === "ACH") {
          setValue(
            "payment-method",
            data?.data?.achDetails?.isStakeChecking === "Y" ? "stake-checking" : "bank-account"
          );
          if (data?.data?.achDetails?.isStakeChecking === "N") {
            setAppUtilityProps((p) => ({ ...p, selectedAccountId: data?.data?.achDetails?.plaidAccountId }));
          }
        } else {
          setValue("payment-method", "card");
          setAppUtilityProps((p) => ({ ...p, selectedFinixCardId: data?.data?.cardDetails?.cardId }));
        }

        setValue("payment-date", data?.data?.paymentDate);

        if (isAutoPay) {
          setValue("autopay", true);
        }
      },
      onError: () => navigate("/renting"),
    });

  const onCancelRentPayment = () =>
    renderCancelRentPayment(modalContext, {
      isAutoPay,
      paymentMonth: paymentDetailsForSelectedMonth?.data?.data?.rentPaymentItems?.rentPaymentMonth,
      contractPaymentId: paymentDetailsForSelectedMonth?.data?.data?.contractPaymentId,
      onSuccess: () => navigate("/renting/stakepay-3/manage"),
    });

  return (
    <div className="stake-pay-3 pay">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={navigate.bind(this, -1)}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">
                Edit {isAutoPay ? "Autopay" : formatDate(rentPaymentItem?.rentPaymentMonth, fullMonthFormat)} Rent
                Payment
              </h3>
              <div className="dummy" />
            </div>
            {isLoadingAuthToken || isLoadingPaymentDetailsForSelectedMonth ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <SetupPayment
                rentPaymentItem={rentPaymentItem}
                customerPaymentId={paymentDetailsForSelectedMonth?.data?.data?.customerPaymentId}
                onCancelRentPayment={onCancelRentPayment}
                isEditing
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withFormProvider(EditPayment);
