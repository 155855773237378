import { useNavigate } from "react-router-dom";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { getHasSeenStakePay3Experience, LocalStorage } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

import { PersonWithKeyIllustration } from "../../../Assets/Images";

export default function renderStakePay3Experience(modalContext) {
  const {
    updateModalComponent,
    setModalVariant,
    setModalPromoComponent,
    setModalTitle,
    setModalClassName,
    updateModalOnCloseCallBack,
    setPreventCloseOnBackdropClick,
  } = modalContext;

  updateModalComponent(StakePay3Experience);
  setModalPromoComponent(null);
  setModalTitle("");
  setModalClassName("hide-header");
  setModalVariant(modalVariants.flexDialog);
  setPreventCloseOnBackdropClick(true);
  updateModalOnCloseCallBack(() => LocalStorage.write(getHasSeenStakePay3Experience(), true));
}

const StakePay3Experience = () => {
  const { onModalClose } = useModalContext();

  const navigate = useNavigate();

  return (
    <>
      <img className="modal-image person-with-key-illustration" src={PersonWithKeyIllustration} alt="" />
      <div className="modal-dialog-content">
        <h3 className="modal-status-title mt-6 mb-0">Easier to pay rent, easier to get Cash Back!</h3>
        <span className="modal-content-text mt-3">
          StakePay is here, making your Stake experience better than ever. Pay on time and get instant Cash Back.
        </span>
      </div>
      <div className="action-container">
        <PrimaryButton
          className="blue-theme"
          onClick={() => onModalClose({ callOnClose: () => navigate("/renting/stakepay-3") })}
        >
          View New Payment Experience
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={() => onModalClose()}>
          Skip for Now
        </PrimaryButton>
      </div>
    </>
  );
};
