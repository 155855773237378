import { useIntercom } from "react-use-intercom";

import PrimaryButton from "../Buttons/primary-button";
import OutlineButton from "../Buttons/outline-button";

import { StakeLogo } from "../../Assets/Images";

const ErrorPage = ({ errorStatus = 500, isPageNotFound }) => {
  const { show: showIntercom } = useIntercom();

  const errorTitle = isPageNotFound
    ? "Page not found"
    : errorStatus >= 500
    ? "Internal Server Error"
    : "Something went wrong";

  const errorDescription = isPageNotFound
    ? "The page doesn't exist or has been moved. Please check the URL or go back to the homepage."
    : "We're experiencing technical difficulties. Please try again later or contact support if the issue persists.";

  const onCTAClick = () => {
    if (isPageNotFound) {
      window.location.pathname = "/login";
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="error-page">
      <header>
        <StakeLogo className="logo" />
      </header>
      <div className="max-width-container error-container">
        <div className="error-code-text mb-4">{isPageNotFound ? "404" : errorStatus}</div>
        <div className="error-title">{errorTitle}</div>
        <div className="error-description mt-2">{errorDescription}</div>
        <div className="action-container split-equally mt-6">
          <PrimaryButton onClick={onCTAClick}>{isPageNotFound ? "Back to Home Page" : "Try Again"}</PrimaryButton>
          <OutlineButton className="light-theme" onClick={showIntercom}>
            Contact Support
          </OutlineButton>
        </div>
      </div>
      <div className="dummy-footer"></div>
    </div>
  );
};

export default ErrorPage;
