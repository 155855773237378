import { useEffect, useState, useMemo } from "react";
import { CircularProgress, Divider } from "@mui/material";

import CopyButton from "../../../Components/Others/copy-button";
import PrimaryButton from "../../../Components/Buttons/primary-button";
import StepIndicator from "../../../Components/Others/step-indicator";
import CustomStepper from "../../../Components/Others/custom-stepper";
import AmountOdometer from "../../../Components/Others/amount-odometer";
import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";

import renderGetBankAmountDetails from "../Add Funds/get-bank-amount-details";

import useApplyStakeDebit from "../../../CustomHooks/useApplyStakeDebit";
import useConnectBankAccount from "../../../CustomHooks/useConnectBankAccount";

import { useActivities, useConnectedAccounts, useDeals } from "../../../CustomHooks/Queries";

import { useModalContext } from "../../../Contexts/ModalContext";
import { useViewport } from "../../../Contexts/ViewportContext";

import { mediumScreenStartFrom, modalVariants, nilYearLongMonthDateFormat } from "../../../Utils/constants";

import {
  formatCurrency,
  isSVDApproved,
  isSVDPending,
  isSVDEligible,
  isSVDCardRequested,
  isSVDDebitCardActivated,
  isSVDDenied,
  dispatchSuccessToast,
  hasSVDPhysicalCardArrived,
  formatDate,
  dispatchAPIErrorToast,
  appendISOTime,
} from "../../../Utils/helpers";

import { StakeCardFrontSide } from "../../../Assets/Images";
import { ArrowRight } from "../../../Assets/Icons";

import "./setup-stake-pay-2.scss";

const stakePay2Steps = { totalSteps: 3, getRemainingSetps: (a) => 3 - a + 1 };

const stakePay2SetupStatuses = {
  steps1: "steps-1",
  steps2: "steps-2",
  steps3: "steps-3",
};

const depositAmountOdoValue = 1100.0;

export default function renderSetupStakePay2(modalContext, defaultStep = stakePay2SetupStatuses.steps1) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalClassName, setModalStep } = modalContext;

  updateModalComponent(() => <SetupStakePay2 defaultStep={defaultStep} />);
  pushModalStack(() => renderSetupStakePay2(modalContext, defaultStep));
  setModalVariant(modalVariants.stepByStepDialog);
  setModalClassName("setup-stake-pay-2");
  setModalStep(Object.values(stakePay2SetupStatuses).findIndex((key) => key === defaultStep) + 1);
}

const SetupStakePay2 = ({ defaultStep }) => {
  const { isFetching: isFetchingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();
  const { isLoading: isLoadingDeals, data: deals } = useDeals();
  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();

  const {
    applyingSVD,
    handleStakeCheckingModalNotApproved,
    stakeDebitQuery: { isFetching: isFetchingStakeDebit, data: stakeDebit },
  } = useApplyStakeDebit();

  const modalContext = useModalContext();
  const {
    modalStep,
    setModalClassName,
    setModalStep,
    setModalTitle,
    setModalPromoComponent,
    onModalClose,
    setModalStack,
    pushModalStack,
  } = modalContext;

  const { rentAmount, cashBackAmount } = useMemo(() => {
    const currentDeal = deals?.data?.data?.currentDeal || {};
    const rentAmount = currentDeal?.rent_amount || 0;
    // Calculate rent match
    const cashBackPercent = currentDeal.cash_back_percent || 0;
    const cashBackAmount = rentAmount * (cashBackPercent / 100);
    return { rentAmount, cashBackAmount };
  }, [deals]);

  const currentBalance = useMemo(() => {
    return activitiesData?.data?.data?.currentBalance || 0;
  }, [activitiesData]);

  const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount({
    onError: () => {
      onModalClose();
      dispatchAPIErrorToast({ alignToAppContent: true });
    },
    onInitiatingSave: () => setModalClassName(null),
    onExit: () => setModalClassName(null),
  });

  const isBankConnected = useMemo(() => {
    return (connectedAccounts?.data?.data?.accounts?.length ?? 0) > 0;
  }, [connectedAccounts?.data?.data?.accounts?.length]);

  const [stakePay2SetupStatus, setStakePay2SetupStatus] = useState(defaultStep);

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const IsSVDDenied = useMemo(() => isSVDDenied(stakeDebit?.data), [stakeDebit?.data]);

  const handleStakePay2SetupStatusChange = (nextStep) => {
    setModalStep(Object.values(stakePay2SetupStatuses).findIndex((key) => key === nextStep) + 1);
  };
  useEffect(() => {
    if (modalStep === 1) {
      setModalTitle("Setup Stake");
      setStakePay2SetupStatus(stakePay2SetupStatuses.steps1);
    } else if (modalStep === 2) {
      setModalTitle("Pay rent with Stake");
      setStakePay2SetupStatus(stakePay2SetupStatuses.steps2);
    } else if (modalStep === 3) {
      setModalTitle("Pay rent with Stake");
      setStakePay2SetupStatus(stakePay2SetupStatuses.steps3);
    }
  }, [modalStep, setModalTitle]);

  useEffect(() => {
    setModalPromoComponent(() => () => {
      const remainingSetps = stakePay2Steps.getRemainingSetps(modalStep);
      return (
        <>
          {remainingSetps} more step{remainingSetps > 1 ? "s" : ""}
          <ArrowRight className="arrow" />
          Earn {formatCurrency(cashBackAmount)} Cash Back on rent
        </>
      );
    });
  }, [stakePay2SetupStatus, modalStep, cashBackAmount, setModalPromoComponent]);

  const handleAddCash = () => {
    setModalStack([]);
    setModalPromoComponent(null);
    pushModalStack(() => renderSetupStakePay2(modalContext, stakePay2SetupStatuses.steps2));
    renderGetBankAmountDetails(modalContext, {
      onSuccess: () => {
        setModalStack([]);
        renderSetupStakePay2(modalContext, stakePay2SetupStatuses.steps3);
      },
    });
  };

  const isLoadingAPIData = isLoadingDeals || isFetchingStakeDebit || isLoadingActivities || isFetchingConnectedAccounts;

  return isLoadingAPIData ? (
    <ModalSkeleton />
  ) : (
    <>
      <StepIndicator activeStep={modalStep} steps={stakePay2Steps.totalSteps} />

      {stakePay2SetupStatus === stakePay2SetupStatuses.steps1 && (
        <RequestSVD
          isApplyingSVD={applyingSVD}
          stakeDebit={stakeDebit}
          IsSVDApproved={IsSVDApproved}
          IsSVDDenied={IsSVDDenied}
          onPrimaryButtonClick={() => {
            if (IsSVDDenied) {
              onModalClose();
            } else if (IsSVDApproved) {
              handleStakePay2SetupStatusChange(stakePay2SetupStatuses.steps2);
            } else {
              handleStakeCheckingModalNotApproved(stakeDebit?.data);
            }
          }}
        />
      )}

      {stakePay2SetupStatus === stakePay2SetupStatuses.steps2 && (
        <DepositAmount
          onAddBank={() => {
            setModalClassName("d-none");
            openPlaid();
          }}
          onAddCash={handleAddCash}
          proceedToNextStep={handleStakePay2SetupStatusChange}
          isBankConnected={isBankConnected}
          currentBalance={currentBalance}
          rentAmount={rentAmount}
          isLoading={isSavingAccount || !plaidReady}
        />
      )}

      {stakePay2SetupStatus === stakePay2SetupStatuses.steps3 && (
        <UpdatePortal stakeDebit={stakeDebit} deals={deals} onComplete={onModalClose} />
      )}
    </>
  );
};

const RequestSVD = ({ isApplyingSVD, stakeDebit, IsSVDApproved, IsSVDDenied, onPrimaryButtonClick }) => {
  const { width } = useViewport();

  const subtitle = useMemo(() => {
    const IsSVDEligible = isSVDEligible(stakeDebit?.data);
    const IsSVDPending = isSVDPending(stakeDebit?.data);
    const IsSVDRequested = isSVDCardRequested(stakeDebit?.data);
    const IsSVDDebitCardActivated = isSVDDebitCardActivated(stakeDebit?.data);

    if (IsSVDPending) {
      return "Your application is pending, additional steps are needed to continue.";
    } else if (IsSVDDebitCardActivated) {
      return "Woohoo! You already activated your card.";
    } else if (IsSVDRequested) {
      return (
        <>
          Your card was requested! You <b>don't</b> need to wait for your card to arrive to continue.
        </>
      );
    } else if (IsSVDApproved) {
      return "Your virtual Stake Visa Debit card is now available for use.";
    } else if (IsSVDEligible) {
      return `Your checking account comes with a virtual debit card`;
    } else {
      return null;
    }
  }, [stakeDebit, IsSVDApproved]);

  const ctaLabel = useMemo(() => {
    const IsSVDEligible = isSVDEligible(stakeDebit?.data);
    const IsSVDPending = isSVDPending(stakeDebit?.data);

    if (IsSVDEligible || isApplyingSVD) {
      return `Request${isApplyingSVD ? "ing..." : ""}`;
    } else if (IsSVDPending) {
      return "Continue";
    } else if (IsSVDDenied) {
      return "Return";
    } else {
      return "Next";
    }
  }, [isApplyingSVD, stakeDebit, IsSVDDenied]);

  const svdCardProgress = useMemo(() => {
    const IsSVDCardRequested = isSVDCardRequested(stakeDebit?.data);

    if (IsSVDCardRequested) {
      const physicalCardArrivesByDate = new Date(appendISOTime(stakeDebit?.data?.data?.physical_card_arrives_by));

      const formattedPhysicalCardArrivesByDate = formatDate(physicalCardArrivesByDate, nilYearLongMonthDateFormat);

      const HasSVDPhysicalCardArrived = hasSVDPhysicalCardArrived(physicalCardArrivesByDate);

      return (
        <CustomStepper
          className="my-sm-10 my-8 mx-auto"
          steps={["Approved", `Arrives by ${formattedPhysicalCardArrivesByDate}`, "Call to activate your card"]}
          activeStep={!HasSVDPhysicalCardArrived ? 1 : 2}
          orientation={width > mediumScreenStartFrom ? "horizontal" : "vertical"}
          alternativeLabel={width > mediumScreenStartFrom ? true : false}
        />
      );
    } else {
      return <img className="stake-card-front-side mt-4" src={StakeCardFrontSide} alt="" />;
    }
  }, [stakeDebit, width]);

  return (
    <>
      <div className="modal-dialog-content">
        <h3
          className={`${IsSVDDenied ? "text-lg-medium" : "text-lg-regular"} ${
            IsSVDApproved ? "color--success-green" : "color--charcoal"
          } white-space-pre-line text-center mt-5 mb-0`}
        >
          {IsSVDDenied && "Sorry, you are unable to pay rent\nwith Stake Checking"}
          {!IsSVDDenied && <>{IsSVDApproved ? "✅" : "1️⃣"}&nbsp;&nbsp;Open a Stake Checking Account</>}
        </h3>
        {!!subtitle && <span className="text-base-regular color--medium-grey text-center mt-2">{subtitle}</span>}
        {svdCardProgress}
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={onPrimaryButtonClick} disabled={isApplyingSVD}>
          {ctaLabel} {isApplyingSVD && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};

const DepositAmount = ({
  onAddBank,
  onAddCash,
  proceedToNextStep,
  isBankConnected,
  currentBalance,
  rentAmount,
  isLoading,
}) => {
  const isAmountEnough = useMemo(() => {
    return currentBalance >= rentAmount;
  }, [currentBalance, rentAmount]);

  const { subtitle, buttonLabel } = useMemo(() => {
    const subtitle =
      "You will pay rent via your Stake balance. Please include your normal utility and processing charges.";

    if (!isBankConnected) {
      return {
        subtitle: "You will pay rent via your Stake balance.\nFirst, add a bank.",
        buttonLabel: "Add Bank",
      };
    } else if (!isAmountEnough) {
      return {
        subtitle: subtitle,
        buttonLabel: "Deposit Rent Amount",
      };
    } else {
      return {
        subtitle: subtitle,
        buttonLabel: "Next",
      };
    }
  }, [isBankConnected, isAmountEnough]);

  return (
    <>
      <div className="modal-dialog-content">
        <h3
          className={`text-lg-regular ${
            isBankConnected && isAmountEnough ? "color--success-green" : "color--charcoal"
          } text-center mt-5 mb-0`}
        >
          {isBankConnected && isAmountEnough ? "✅ Deposit rent amount" : "2️⃣ Deposit rent amount"}
        </h3>
        <span className="text-base-regular color--medium-grey white-space-pre-line text-center mt-2">{subtitle}</span>
        <div className="stake-banking-widget-mockup mt-4">
          <div className="account-info">
            <span className="balance-label mx-auto">Balance</span>
            <AmountOdometer
              className="my-1 mx-auto"
              initialValue={depositAmountOdoValue}
              value={depositAmountOdoValue}
              size="28px"
              fontWeight={500}
              color="var(--text-primary)"
            />
            <Divider />
            <div className="account-number">
              <span className="label">Account</span>
              <span className="value">...2345</span>
            </div>
            <div className="routing-number">
              <span className="label">Routing</span>
              <span className="value">012341239</span>
            </div>
          </div>
          <div className="banking-action-buttons">
            <div className="add-cash-button">Add Cash</div>
            <div className="cash-out-button">Cash Out</div>
          </div>
        </div>
        {isBankConnected && !isAmountEnough && (
          <span className="text-base-regular color--medium-grey text-center mt-4">
            ⚠️ Transfers from your bank to Stake take up to 1-2 business days to process.
          </span>
        )}
      </div>
      <div className="action-container">
        <PrimaryButton
          className="blue-theme"
          onClick={() => {
            if (!isBankConnected) {
              onAddBank();
            } else if (!isAmountEnough) {
              onAddCash();
            } else {
              proceedToNextStep(stakePay2SetupStatuses.steps3);
            }
          }}
          disabled={isLoading}
        >
          {buttonLabel} {isLoading && <CircularProgress />}
        </PrimaryButton>
        {isBankConnected && !isAmountEnough && (
          <PrimaryButton className="secondary-colors" onClick={() => proceedToNextStep(stakePay2SetupStatuses.steps3)}>
            Skip for now
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

const UpdatePortal = ({ stakeDebit, deals, onComplete }) => {
  const portalLink = useMemo(() => {
    return deals?.data?.data?.currentDeal?.resident_payment_portal_link;
  }, [deals]);

  const { accountNumber, routingNumber, isStakePay25Enabled, stake25iFrameURL } = useMemo(() => {
    return {
      accountNumber: stakeDebit?.data?.data?.account_number,
      routingNumber: stakeDebit?.data?.data?.routing_number,
      isStakePay25Enabled: stakeDebit?.data?.data?.enabled_for_stakepay_2_5__c,
      stake25iFrameURL: stakeDebit?.data?.data?.stakepay_2_5_iframe_widget_url,
    };
  }, [stakeDebit]);

  return (
    <>
      {isStakePay25Enabled && (
        <>
          <h3 className="text-lg-regular color--charcoal text-center mt-5 mb-0">3️⃣ Set your payment schedule</h3>
          <iframe
            src={stake25iFrameURL} // Use the URL from stake25iFrameURL
            title="Payment Schedule"
            style={{ width: "100%", height: "600px", border: "none" }} // Adjust width and height as needed
          />
          <span className="text-base-regular color--medium-grey text-center mt-6">
            ⚠️ Stake charges no fees, and refunds all processing fees
          </span>
          {!!portalLink && (
            <div className="action-container">
              <PrimaryButton className="blue-theme">Finish</PrimaryButton>
            </div>
          )}
        </>
      )}
      {!isStakePay25Enabled && (
        <>
          <div className="modal-dialog-content">
            <h3 className="text-lg-regular color--charcoal text-center mt-5 mb-0">3️⃣ Update payment portal</h3>
            <span className="text-base-regular color--medium-grey text-center mt-2">
              Change your payment method. Paste these into your portal.
            </span>
            <span className="text-base-regular color--medium-grey text-center mt-2">
              This step will be marked complete after your rent payment is processed.
            </span>
            <div className="account-detail-card mt-4">
              <div className="account-detail">
                <div className="account-detail-label">Account Number</div>
                <div className="account-detail-value">{accountNumber}</div>
              </div>
              <CopyButton
                text={accountNumber}
                onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
              />
            </div>
            <div className="account-detail-card mt-4">
              <div className="account-detail">
                <div className="account-detail-label">Routing Number</div>
                <div className="account-detail-value">{routingNumber}</div>
              </div>
              <CopyButton
                text={routingNumber}
                onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
              />
            </div>
            <span className="text-base-regular color--medium-grey text-center mt-6">
              ⚠️ Stake charges no fees, and refunds all processing fees
            </span>
          </div>
          <div className="action-container">
            {!!portalLink ? (
              <PrimaryButton className="blue-theme" onClick={() => window.open(portalLink, "_blank")}>
                Open portal
              </PrimaryButton>
            ) : (
              <PrimaryButton className="blue-theme" onClick={onComplete}>
                Done
              </PrimaryButton>
            )}
          </div>
        </>
      )}
    </>
  );
};
