import { useQueryClient } from "react-query";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useDeleteAstraCard } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

export default function renderDeleteAstraCard(modalContext, card) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <DeleteAstraCard card={card} />);
  pushModalStack(() => renderDeleteAstraCard(modalContext));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
  setModalClassName("");
}

const DeleteAstraCard = ({ card }) => {
  const queryClient = useQueryClient();

  const { popModalStack } = useModalContext();

  const { isLoading: isDeleingAstraCard, mutate: DeleteAstraCard } = useDeleteAstraCard({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.astraCards);
      popModalStack(1);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  return (
    <AlertConfirmationModal
      title="Remove Card"
      description={`Are you sure you want to delete ${card?.cardNickName}?`}
      CTAButtonText="Remove"
      secondaryCTAButtonText="Cancel"
      onCTAButtonClick={() => DeleteAstraCard(card.id)}
      onSecondaryCTAButtonClick={() => popModalStack(1)}
      isCTALoading={isDeleingAstraCard}
    />
  );
};
