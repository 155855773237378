import { useEffect, useMemo, useRef } from "react";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderStakeCheckingPending from "./stake-checking-pending";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useStakeDebit } from "../../../CustomHooks/Queries";

import {
  isSVDPendingAddressVerification,
  isSVDPendingIDVerification,
  isSVDPendingSSNVerification,
  isSVDPendingVerification,
} from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderStakeCheckingVerificationPending(modalContext, unitTabRef) {
  const {
    updateModalComponent,
    pushModalStack,
    setModalVariant,
    setModalPromoComponent,
    setModalTitle,
    setModalClassName,
  } = modalContext;

  updateModalComponent(() => <StakeCheckingVerificationPending unitTabRef={unitTabRef} />);
  pushModalStack(() => renderStakeCheckingVerificationPending(modalContext));
  setModalPromoComponent(null);
  setModalTitle("");
  setModalClassName("position-close-button-over-content");
  setModalVariant(modalVariants.flexDialog);
}

const StakeCheckingVerificationPending = ({ unitTabRef }) => {
  const SVDApplicationSession = useRef(unitTabRef);

  const modalContext = useModalContext();
  const { modalStack, setModalStack, popModalStack } = modalContext;

  const { data: stakeDebit } = useStakeDebit({ refetchInterval: 3 * 1000, refetchIntervalInBackground: true });

  useEffect(() => {
    if (isSVDPendingVerification(stakeDebit?.data)) {
      if (!SVDApplicationSession?.current?.closed) {
        SVDApplicationSession?.current?.close();
      }
      setModalStack((p) => p.slice(0, modalStack.length - 1));
      renderStakeCheckingPending(modalContext);
    } //eslint-disable-next-line
  }, [stakeDebit?.data]);

  const verificationTypeText = useMemo(() => {
    if (isSVDPendingSSNVerification(stakeDebit?.data)) {
      return "Social security verification";
    } else if (isSVDPendingAddressVerification(stakeDebit?.data)) {
      return "Address verification";
    } else if (isSVDPendingIDVerification(stakeDebit?.data)) {
      return "Identity verification";
    }
    return "";
  }, [stakeDebit?.data]);

  const openUnitOnNewTab = () => {
    const newWindow = window.open(stakeDebit?.data?.data?.application_link ?? "", "_blank");
    SVDApplicationSession.current = newWindow;
  };

  return (
    <>
      <div className="modal-image svd-single-card" src={SVD_Single_Card} alt="">
        <img src={SVD_Single_Card} alt="" />
      </div>
      <div className="modal-dialog-content">
        <h3 className="modal-status-title mt-6 mb-5">
          Your virtual Stake Visa Debit application requires verification
        </h3>
        <ul className="benefits-list">
          <li className="alert-list-style">{verificationTypeText} required</li>
        </ul>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={openUnitOnNewTab}>
          Check Status
        </PrimaryButton>
        {modalStack.length > 1 && (
          <PrimaryButton className="secondary-colors" onClick={() => popModalStack(1)}>
            Back
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
