import PropTypes from "prop-types";
import React from "react";
import { OutlinedInput } from "@mui/material";

const TextField = React.forwardRef(
  ({ className = "", label, description, RightIcon, onRightIconClick, errorMessage, helperText, ...props }, ref) => {
    return (
      <div className={`text-field ${className}`}>
        {!!label && <label>{label}</label>}
        {!!description && <span className="description">{description}</span>}
        <div className="input-icon-wrapper">
          {!!RightIcon && <RightIcon className="right-icon" onClick={onRightIconClick} />}
          <OutlinedInput {...props} inputRef={ref} />
        </div>
        {!!errorMessage && <span className="error-message">{errorMessage}</span>}
        {!!helperText && <span className="helper-text">{helperText}</span>}
      </div>
    );
  }
);

TextField.prototype = {
  className: PropTypes.string,
  label: PropTypes.string,
  RightIcon: PropTypes.node,
  onRightIconClick: PropTypes.func,
};

export default TextField;
