import SkeletonLoader from "./skeleton-loader";
import { Stack } from "@mui/material";

export default function ModalSkeleton({ className = "" }) {
  return (
    <div className={`modal-skeleton ${className}`}>
      <SkeletonLoader className="image-skeleton border--rounded-sm" width="100%" height={200} />
      <Stack className="py-6 gap-2" direction="column" alignItems="center" justifyContent="space-between">
        <SkeletonLoader className="border--rounded-full" width="100%" height={12} sx={{ maxWidth: "160PX" }} />
        <SkeletonLoader className="border--rounded-full" width="100%" height={12} sx={{ maxWidth: "240PX" }} />
      </Stack>
      <SkeletonLoader className="border--rounded-sm" width="100%" height={48} />
    </div>
  );
}
