import { useMemo } from "react";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton.js";
import PrimaryButton from "../../../Components/Buttons/primary-button.js";
import CustomRadioButton from "../../../Components/Form Fields/radio-button.js";

import renderAddStripeCardForm from "./add-stripe-card-form.js";
import renderDeleteFinixCardConfirmationModal from "./delete-finix-card-confirmation.js";

import { useFinixConnectedCards } from "../../../CustomHooks/Queries/index.js";

import { useModalContext } from "../../../Contexts/ModalContext.js";
import { useAppUtilityContext } from "../../../Contexts/AppUtilityContext.js";

import { modalVariants } from "../../../Utils/constants.js";

import { AddCircleFilled, Trash } from "../../../Assets/Icons/index.js";
import {
  AmericanExpress,
  Discover,
  Mastercard,
  VisaLogo,
  DebitCards as DebitCardsImage,
} from "../../../Assets/Images/index.js";

export default function renderManageFinixCards(modalContext, props) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  pushModalStack(() => renderManageFinixCards(modalContext, props));
  updateModalComponent(() => <ManageFinixCards presentedForSelection {...props} />);
  setModalVariant(modalVariants.formDialog);
  setModalClassName("rendering-page-content");
  setModalTitle("Manage Cards");
}

const CardItem = ({ card, selected, selectable = false, onSelect }) => {
  const modalContext = useModalContext();

  const handleDeleteCard = (e, card) => {
    e.stopPropagation();
    renderDeleteFinixCardConfirmationModal(modalContext, { card });
  };

  const cardCompanyLogo = useMemo(() => {
    if (card.cardBrand === "VISA") {
      return <VisaLogo />;
    } else if (card.cardBrand === "AMERICANEXPRESS") {
      return <AmericanExpress />;
    } else if (card.cardBrand === "DISCOVER") {
      return <Discover />;
    } else if (card.cardBrand === "MASTERCARD") {
      return <Mastercard />;
    } else {
      return <VisaLogo />;
    }
  }, [card.cardBrand]);

  const selectCardForPayment = () => {
    if (selectable) {
      onSelect?.(card.cardId);
    }
  };

  return (
    <div
      className={`debit-card-item${selected ? " selected" : ""}${selectable ? " selectable" : ""}`}
      onClick={selectCardForPayment}
    >
      <div className="card-details-container">
        <div className="card-info">
          {selectable && <CustomRadioButton checked={selected} onChange={selectCardForPayment} />}
          <div className="card-logo">{cardCompanyLogo}</div>
          <div className="card-details">
            <span className="card-type">{`********${card.cardLast4Digits}`}</span>
            <span className="card-expiry">Exp. date: {`${card.expMonth}/${card.expYear}`}</span>
          </div>
        </div>
        <div className="actions">
          <button className="delete" onClick={(e) => handleDeleteCard(e, card)}>
            <Trash />
          </button>
        </div>
      </div>
    </div>
  );
};

const ManageFinixCards = ({ presentedForSelection = false }) => {
  const { isLoading: isLoadingConnectedCards, data: connectedFinixCards } = useFinixConnectedCards();

  const { appUtilityProps, setAppUtilityProps } = useAppUtilityContext();

  const modalContext = useModalContext();
  const { popModalStack } = modalContext;

  const connectedCards = useMemo(() => {
    const cards = connectedFinixCards?.data?.data?.data ?? [];
    return cards.map((card) => (
      <CardItem
        key={card.cardId}
        card={card}
        selectable={presentedForSelection}
        selected={card.cardId === (appUtilityProps?.selectedFinixCardId ?? cards[0]?.cardId)}
        onSelect={(cardId) => {
          setAppUtilityProps((p) => ({ ...p, selectedFinixCardId: cardId }));
        }}
      />
    )); //eslint-disable-next-line
  }, [connectedFinixCards, presentedForSelection, appUtilityProps?.selectedFinixCardId]);

  const openFinixCardForm = () => renderAddStripeCardForm(modalContext);

  return (
    <>
      {isLoadingConnectedCards ? (
        <ModalSkeleton />
      ) : connectedCards.length > 0 ? (
        <div className="content connected-bank-accounts">
          <div className="top-container">
            {connectedCards}
            <button className="add-account" onClick={openFinixCardForm}>
              <AddCircleFilled />
              Add New Card
            </button>
          </div>
          <div className="bottom-container stick-to-modal-bottom">
            <PrimaryButton className="blue-theme" onClick={popModalStack.bind(this, 1)}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      ) : (
        <div className="no-debit-cards">
          <div className="card-icon my-8">
            <DebitCardsImage />
          </div>
          <h2 className="no-cards-title mb-1">No Cards Linked</h2>
          <p className="no-cards-message">You don’t have any cards connected to your account.</p>
          <PrimaryButton className="blue-theme" onClick={openFinixCardForm}>
            Link a Card
          </PrimaryButton>
        </div>
      )}
    </>
  );
};
