import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../Components/Buttons/primary-button.js";
import CustomRadioButton from "../../Components/Form Fields/radio-button.js";

import renderDeleteAstraCard from "../../Organisms/Modals/Cards/delete-astra-card.js";

import useAstra from "../../CustomHooks/useAstra.js";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext.js";

import { AmericanExpress, Discover, Mastercard, VisaLogo, DebitCards as DebitCardsImage } from "../../Assets/Images/";
import { ArrowLeftCircle, AddCircle, Trash, AddCircleFilled } from "../../Assets/Icons";

import { getIsDebitCardExpired } from "../../Utils/helpers.js";

import "./debit-cards.scss";

const DebitCardItem = ({ card, selected, selectable = false, onSelect }) => {
  const modalContext = useModalContext();

  const capitalizedStatus = card.status.charAt(0).toUpperCase() + card.status.slice(1);

  const handleDeleteAstraDebitCard = (e, card) => {
    e.stopPropagation();
    renderDeleteAstraCard(modalContext, card);
  };

  const cardCompanyLogo = useMemo(() => {
    if (card.card_company === "Visa") {
      return <VisaLogo />;
    } else if (card.card_company === "AmericanExpress") {
      return <AmericanExpress />;
    } else if (card.card_company === "Discover") {
      return <Discover />;
    } else if (card.card_company === "Mastercard") {
      return <Mastercard />;
    } else {
      return <VisaLogo />;
    }
  }, [card.card_company]);

  const isDebitCardExpired = useMemo(() => getIsDebitCardExpired(card.expiration_date), [card.expiration_date]);

  const selectCardForPayment = () => {
    if (selectable) {
      onSelect?.(card.id);
    }
  };

  return (
    <div
      className={`debit-card-item${selected ? " selected" : ""}${selectable ? " selectable" : ""}`}
      onClick={selectCardForPayment}
    >
      <div className="card-details-container">
        <div className="card-info">
          {selectable && <CustomRadioButton checked={selected} onChange={selectCardForPayment} />}
          <div className="card-logo">{cardCompanyLogo}</div>
          <div className="card-details">
            <span className="card-type">{`${card.card_company} (...${card.last_four_digits})`}</span>
            <span className="card-expiry">Exp. date: {`${card.expiration_date}`}</span>
          </div>
        </div>

        <div className="actions">
          <div className="status-container">
            <div className={`status-badge ${card.status} ${isDebitCardExpired ? "expired" : ""}`}>
              {capitalizedStatus}
            </div>
          </div>
          <button className="delete" onClick={(e) => handleDeleteAstraDebitCard(e, card)}>
            <Trash />
          </button>
        </div>
      </div>
    </div>
  );
};

export default function DebitCardsPage() {
  return (
    <div className="debit-cards">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <DebitCards />
          </div>
        </div>
      </section>
    </div>
  );
}

export function DebitCards({ presentedForSelection = false }) {
  const navigate = useNavigate();

  const { popModalStack, setModalClassName } = useModalContext();

  const { appUtilityProps, setAppUtilityProps } = useAppUtilityContext();

  const {
    isInitialisingAstra,
    isCreatingUserIntent,
    isCreatingTrustedAuthenticationToken,
    isFetchingAstraCards,
    openAstraPortal,
    astraDebitCards,
  } = useAstra({
    onAstraPortalClose: () => {
      if (presentedForSelection) {
        setModalClassName("rendering-page-content");
      }
    },
  });

  const connectedDebitCards = useMemo(() => {
    const cards = astraDebitCards ?? [];
    const filteredCards = cards.filter((card) => !card.is_system_card);
    return filteredCards.map((card) => (
      <DebitCardItem
        key={card.id}
        card={card}
        selectable={presentedForSelection}
        selected={card.id === (appUtilityProps?.selectedCardId ?? cards[0]?.id)}
        onSelect={(cardId) => {
          setAppUtilityProps((p) => ({ ...p, selectedCardId: cardId }));
        }}
      />
    )); //eslint-disable-next-line
  }, [astraDebitCards, presentedForSelection, appUtilityProps?.selectedCardId]);

  const handleAddButtonClick = () => {
    if (presentedForSelection) {
      setModalClassName("d-none");
    }
    openAstraPortal();
  };

  const isSettingUpAstra = isCreatingUserIntent || isCreatingTrustedAuthenticationToken;

  return (
    <>
      {!presentedForSelection && (
        <div className="section-header">
          <button className="go-back-button" onClick={() => navigate(-1)}>
            <ArrowLeftCircle />
          </button>
          <h3 className="section-title">Debit Cards</h3>
          {isSettingUpAstra ? (
            <CircularProgress />
          ) : (
            <button className="add-button" onClick={handleAddButtonClick}>
              <AddCircle />
            </button>
          )}
        </div>
      )}
      {isInitialisingAstra || isFetchingAstraCards ? (
        <div className="loading-content">
          <CircularProgress />
        </div>
      ) : connectedDebitCards.length > 0 ? (
        <div className="content connected-debit-cards">
          <div className="top-container">
            {connectedDebitCards}
            {presentedForSelection && (
              <button className="add-account" onClick={handleAddButtonClick} disabled={isSettingUpAstra}>
                {isSettingUpAstra ? <CircularProgress /> : <AddCircleFilled />}
                Add New Account
              </button>
            )}
          </div>
          {presentedForSelection && (
            <div className="bottom-container stick-to-modal-bottom">
              <PrimaryButton className="blue-theme" onClick={() => popModalStack(1)}>
                Confirm
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <div className="no-debit-cards">
          <div className="card-icon my-8">
            <DebitCardsImage />
          </div>
          <h2 className="no-cards-title mb-1">No Debit Cards Linked</h2>
          <p className="no-cards-message mb-sm-6 mb-5">You don’t have any debit cards connected to your account.</p>
          <PrimaryButton className="blue-theme" onClick={openAstraPortal} disabled={isCreatingUserIntent}>
            Link a Debit Card {isCreatingUserIntent && <CircularProgress />}
          </PrimaryButton>
        </div>
      )}
    </>
  );
}
