import { useQueryClient } from "react-query";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useStakePay3CancelPayment } from "../../../CustomHooks/Mutations";

import { dispatchSuccessToast, dispatchErrorToast, formatDate } from "../../../Utils/helpers";
import { APIQueryKeys, fullMonthFormat, modalVariants } from "../../../Utils/constants";

export default function renderCancelRentPayment(modalContext, props) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <CancelRentPayment {...props} />);
  pushModalStack(() => renderCancelRentPayment(modalContext));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
  setModalClassName("");
}

const CancelRentPayment = ({ isAutoPay = false, paymentMonth, contractPaymentId, onSuccess }) => {
  const queryClient = useQueryClient();

  const { popModalStack, onModalClose } = useModalContext();

  const formattedPaymentMonth = formatDate(paymentMonth, fullMonthFormat);

  const { isLoading: isCancellingpayment, mutate: CancelPayment } = useStakePay3CancelPayment({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentDetails);
      queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentMonths);
      queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentSetupSummary);
      queryClient.refetchQueries(APIQueryKeys.stakePay3AutopayPaymentSetupSummary);
      dispatchSuccessToast(`${isAutoPay ? "Autopay" : formattedPaymentMonth} rent payment cancelled successfully`);
      onSuccess?.();
      onModalClose();
    },
    onError: () =>
      dispatchErrorToast(`Failed to cancel the ${isAutoPay ? "autopay" : formattedPaymentMonth} rent payment`),
  });

  return (
    <AlertConfirmationModal
      variant="alert-error"
      title={`Are you sure you want to cancel ${isAutoPay ? "autopay" : formattedPaymentMonth} rent payment?`}
      description={`Cancelling the scheduled ${
        isAutoPay ? "autopay" : formattedPaymentMonth
      } rent payment will remove it, and it cannot be set up again. If you need to reschedule, reach out to support`}
      CTAButtonText="Confirm"
      onCTAButtonClick={() => CancelPayment({ isAutoPay, contractPaymentId })}
      isCTALoading={isCancellingpayment}
      secondaryCTAButtonText="Cancel"
      onSecondaryCTAButtonClick={() => popModalStack(1)}
    />
  );
};
