import { useEffect, useState } from "react";

import { ChevronRight } from "../Assets/Icons";

export default function useCollapseComponents(state) {
  const [showListItem, setShowListItem] = useState(state);

  useEffect(() => setShowListItem(state), [state]);

  const CollapseButton = ({ className = "", children, ...props }) => (
    <button
      className={`collapse-button ${showListItem ? "open" : ""} ${className}`}
      onClick={() => setShowListItem((p) => !p)}
      {...props}
    >
      {children}
      <ChevronRight className="chevron" />
    </button>
  );

  return { showListItem, CollapseButton };
}
