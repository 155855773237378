import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment";

import PrimaryButton from "../../Components/Buttons/primary-button.js";
import FormSwitch from "../../Components/Form Fields/switch.js";

import renderRentPaymentDetails from "../../Organisms/Modals/Transfer/rent-payment-details.js";
import renderCancelRentPayment from "../../Organisms/Modals/Stake Pay 3/cancel-rent-payment.js";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken.js";

import { useModalContext } from "../../Contexts/ModalContext.js";

import {
  useAutopayPaymentSetupSummary,
  useDeals,
  useStakeDebit,
  useStakePay3PaymentDetailsForSelectedMonth,
  useStakePay3PaymentMonths,
  useStakePay3PaymentSetupSummary,
} from "../../CustomHooks/Queries/index.js";

import { formatCurrency, formatDate, getNumberWithOrdinal } from "../../Utils/helpers.js";
import { longMonthDateFormat } from "../../Utils/constants.js";

import { Receipt } from "../../Assets/Images";
import { ArrowLeftCircle, Tick, WarningTriangle } from "../../Assets/Icons";

function ManagePayments() {
  const [selectedPayment, setSelectedPayment] = useState(null);

  const navigate = useNavigate();

  const modalContext = useModalContext();
  const { onModalClose } = modalContext;

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const sfid = deals?.data?.data?.currentDeal?.sfid;

  const { isLoading: isLoadingPaymentMonths, data: paymentMonths } = useStakePay3PaymentMonths(sfid, {
    enabled: !isLoadingAuthToken && !!sfid,
  });

  const { isLoading: isLoadingPaymentSetupSummary, data: paymentSetupSummary } = useStakePay3PaymentSetupSummary(sfid, {
    enabled: !isLoadingAuthToken && !!sfid,
  });

  const { isLoading: isLoadingAutopayPaymentSetupSummary, data: autopayPaymentSetupSummary } =
    useAutopayPaymentSetupSummary(sfid, {
      enabled: !isLoadingAuthToken && !!sfid,
    });

  const { isLoading: isLoadingPaymentDetailsForSelectedMonth, data: paymentDetailsForSelectedMonth } =
    useStakePay3PaymentDetailsForSelectedMonth(selectedPayment?.contractPaymentId, {
      enabled: !isLoadingAuthToken && !!selectedPayment?.contractPaymentId,
    });

  const paymentMonthsList = paymentMonths?.data?.data?.paymentMonthDetail;

  const selectedMonthPaymentDetails = paymentDetailsForSelectedMonth?.data?.data;

  const autopayPaymentDetails = paymentSetupSummary?.data?.data;

  useEffect(() => {
    if (!!paymentMonths) {
      setSelectedPayment(paymentMonths?.data?.data?.paymentMonthDetail?.[0] ?? null);
    }
  }, [paymentMonths]);

  const handleEditPayment = ({ isAutoPay, contractPaymentId }) => {
    onModalClose();
    navigate(`/renting/stakepay-3/edit/${contractPaymentId}${isAutoPay ? "?autopay=true" : ""}`);
  };

  const handleToggleAutopay = () => {
    if (autopayPaymentDetails?.isAutopaySetup) {
      renderCancelRentPayment(modalContext, {
        isAutoPay: true,
        paymentMonth: autopayPaymentDetails?.rentPaymentItems?.rentPaymentMonth,
        contractPaymentId: autopayPaymentDetails?.contractPaymentId,
      });
    } else {
      navigate("/renting/stakepay-3/setup-autopay");
    }
  };

  // const StatusIcon = getRentPaymentStatusIcon("scheduled");

  const isLoading =
    isLoadingAuthToken ||
    isLoadingDeals ||
    isLoadingStakeDebit ||
    isLoadingPaymentMonths ||
    isLoadingPaymentSetupSummary ||
    isLoadingPaymentDetailsForSelectedMonth ||
    isLoadingAutopayPaymentSetupSummary;

  const showNoContent =
    !autopayPaymentDetails?.isAutopayEligible &&
    !autopayPaymentDetails?.isAutopaySetup &&
    paymentMonthsList?.length === 0;

  return (
    <div className="stake-pay-3 manage">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            {!showNoContent && (
              <div className="section-header">
                <button className="go-back-button" onClick={navigate.bind(this, "/renting")}>
                  <ArrowLeftCircle />
                </button>
                <h3 className="section-title">Manage Rent Payment</h3>
                <div className="dummy" />
              </div>
            )}
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : showNoContent ? (
              <div className="content error">
                <Receipt />
                <h3 className="error-title">No scheduled payment found</h3>
                <p className="error-description">
                  We couldn't find any scheduled payment linked to your account. If you believe this is an error, please
                  contact support.
                </p>
                <PrimaryButton className="blue-theme" onClick={navigate.bind(this, "/renting")}>
                  Back to renting
                </PrimaryButton>
              </div>
            ) : (
              <>
                {selectedMonthPaymentDetails?.achDetails?.isStakeChecking === "Y" &&
                  selectedMonthPaymentDetails?.paymentAmount > stakeDebit?.data?.data?.available && (
                    <div className="info-alert error mb-4">
                      <WarningTriangle />
                      You do not have funds, make a deposit before{" "}
                      {moment(selectedMonthPaymentDetails?.paymentDate).format("MMMM Do")} or your rent payment will not
                      be successful and you may incur fees.
                    </div>
                  )}
                {paymentMonthsList?.length > 0 && (
                  <div className="payment-card mb-5">
                    <div className="card-row">
                      {paymentMonthsList?.length > 1 ? (
                        <Dropdown className="custom-menu">
                          <Dropdown.Toggle className="border-less" as="button" type="button">
                            Rent Payment - {selectedPayment?.paymentMonth}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {paymentMonthsList?.map((month, idx) => {
                              const isSelected = selectedPayment?.contractPaymentId === month?.contractPaymentId;
                              return (
                                <Dropdown.Item
                                  key={idx}
                                  className={isSelected ? "selected" : ""}
                                  onClick={() => setSelectedPayment(month)}
                                >
                                  {month?.paymentMonth}
                                  {isSelected && <Tick className="tick" />}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <div className="text-base-medium color--charcoal">
                          Rent Payment - {selectedPayment?.paymentMonth}
                        </div>
                      )}
                      {/* <span className={`status-badge scheduled`}>
                        <StatusIcon className="badge-icon" />
                        Scheduled
                      </span> */}
                    </div>
                    <Divider />
                    <div className="row row-gap-4">
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Scheduled payment amount</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!!selectedPayment && formatCurrency(selectedMonthPaymentDetails?.paymentAmount)}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Scheduled payment date</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!!selectedPayment &&
                            formatDate(selectedMonthPaymentDetails?.paymentDate, longMonthDateFormat)}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Payment method</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!!selectedPayment
                            ? selectedMonthPaymentDetails?.paymentMethod === "ACH"
                              ? `${
                                  selectedMonthPaymentDetails?.achDetails?.bankAccountName
                                } (...${selectedMonthPaymentDetails?.achDetails?.bankAccountNumber?.slice(-4)})`
                              : `${selectedMonthPaymentDetails?.cardDetails?.cardBrand} (...${selectedMonthPaymentDetails?.cardDetails?.cardLast4Digits})`
                            : ""}
                        </div>
                      </div>
                      {selectedMonthPaymentDetails?.paymentMethod === "ACH" && (
                        <div className="col-6">
                          <div className="text-xs-regular color--medium-grey mb-1">Routing number</div>
                          <div className="text-sm-medium color--charcoal mb-0">
                            {selectedMonthPaymentDetails?.achDetails?.routingNumber}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="action-container d-flex flex-row-reverse column-gap-4 mt-4">
                      <PrimaryButton
                        className="blue-theme flex-grow-1"
                        onClick={() => handleEditPayment(selectedMonthPaymentDetails)}
                      >
                        Edit Payment
                      </PrimaryButton>
                      <PrimaryButton
                        className="secondary-colors flex-grow-1"
                        onClick={renderRentPaymentDetails.bind(this, modalContext, {
                          rentPaymentDetails: selectedMonthPaymentDetails,
                          onEditClick: () => handleEditPayment(selectedMonthPaymentDetails),
                        })}
                      >
                        View Details
                      </PrimaryButton>
                    </div>
                  </div>
                )}
                {(autopayPaymentDetails?.isAutopayEligible || autopayPaymentDetails?.isAutopaySetup) && (
                  <div className="payment-card">
                    <div className="card-row">
                      <div className="text-base-medium color--charcoal">Autopay Rent Payment</div>
                      <FormSwitch checked={autopayPaymentDetails?.isAutopaySetup} onClick={handleToggleAutopay} />
                    </div>
                    <Divider />
                    <div className="row row-gap-4">
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Autopay rent amount</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!autopayPaymentDetails?.isAutopaySetup
                            ? formatCurrency(autopayPaymentSetupSummary?.data?.data?.paymentDetails?.itemTotal)
                            : formatCurrency(autopayPaymentDetails?.paymentDetails?.rentPaymentItems?.itemTotal)}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Frequency</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!autopayPaymentDetails?.isAutopaySetup
                            ? "-"
                            : `${getNumberWithOrdinal(
                                parseInt(autopayPaymentDetails?.autoPaySetupDetails?.frequency)
                              )} of every month`}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Next payment date</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!autopayPaymentDetails?.isAutopaySetup
                            ? "-"
                            : formatDate(
                                autopayPaymentDetails?.autoPaySetupDetails?.nextPaymentDate,
                                longMonthDateFormat
                              )}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-xs-regular color--medium-grey mb-1">Payment method</div>
                        <div className="text-sm-medium color--charcoal mb-0">
                          {!autopayPaymentDetails?.isAutopaySetup
                            ? "-"
                            : autopayPaymentDetails?.paymentDetails?.paymentMethod === "ACH"
                            ? `${
                                autopayPaymentDetails?.paymentDetails?.achDetails?.bankAccountName
                              } (...${autopayPaymentDetails?.paymentDetails?.achDetails?.bankAccountNumber?.slice(-4)})`
                            : `${autopayPaymentDetails?.paymentDetails?.cardDetails?.cardBrand} (...${autopayPaymentDetails?.paymentDetails?.cardDetails?.cardLast4Digits})`}
                        </div>
                      </div>
                      {autopayPaymentDetails?.paymentDetails?.paymentMethod === "ACH" && (
                        <div className="col-6">
                          <div className="text-xs-regular color--medium-grey mb-1">Routing number</div>
                          <div className="text-sm-medium color--charcoal mb-0">
                            {autopayPaymentDetails?.paymentDetails?.achDetails?.routingNumber}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="action-container d-flex flex-row-reverse column-gap-4 mt-4">
                      {autopayPaymentDetails?.isAutopaySetup ? (
                        <>
                          <PrimaryButton
                            className="blue-theme flex-grow-1"
                            onClick={() =>
                              handleEditPayment({
                                contractPaymentId: autopayPaymentDetails?.paymentDetails?.contractPaymentId,
                                isAutoPay: true,
                              })
                            }
                          >
                            Edit Payment
                          </PrimaryButton>
                          <PrimaryButton
                            className="secondary-colors flex-grow-1"
                            onClick={renderRentPaymentDetails.bind(this, modalContext, {
                              rentPaymentDetails: {
                                ...autopayPaymentDetails?.autoPaySetupDetails,
                                ...autopayPaymentDetails?.paymentDetails,
                              },
                              isAutoPay: true,
                              onEditClick: () =>
                                handleEditPayment({
                                  contractPaymentId: autopayPaymentDetails?.paymentDetails?.contractPaymentId,
                                  isAutoPay: true,
                                }),
                            })}
                          >
                            View Details
                          </PrimaryButton>
                        </>
                      ) : (
                        <PrimaryButton className="blue-theme flex-grow-1" onClick={handleToggleAutopay}>
                          Set Up Autopay
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ManagePayments;
