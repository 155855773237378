import moment from "moment";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { formatCurrency } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

const getRentPaymentItemToolTipContent = (paymentItem) => {
  const pastDueBalance =
    paymentItem?.itemDetails?.find((item) => item?.subItemName === "Past Due Balance")?.subItemTotal ?? 0;

  switch (paymentItem?.paymentOption) {
    case "Current Balance Due":
      return {
        title: "Current balance due",
        description: `Your current balance due on ${moment(paymentItem?.lastPaymentDate).format(
          "Do MMMM"
        )} is ${formatCurrency(paymentItem?.itemTotal)}.`,
      };
    case "Current Balance Due & Past Due":
      return {
        title: "Current balance due including past due balance",
        description: `Your current balance due includes your past due amount of ${formatCurrency(
          pastDueBalance
        )}.\nThe total balance due on ${moment(paymentItem?.lastPaymentDate).format("Do MMMM")} is ${formatCurrency(
          paymentItem?.itemTotal
        )}.`,
      };
    case "Upcoming Balance Due":
      return {
        title: "Upcoming balance due",
        description: `Your upcoming balance due on ${moment(paymentItem?.lastPaymentDate).format(
          "Do MMMM"
        )} is ${formatCurrency(paymentItem?.itemTotal)}.`,
      };
    case "Upcoming Balance Due & Past Due":
      return {
        title: "Upcoming balance due including past due balance",
        description: `Your upcoming balance due includes your past due amount of ${formatCurrency(
          pastDueBalance
        )}.\nThe total balance due on ${moment(paymentItem?.lastPaymentDate).format("Do MMMM")} is ${formatCurrency(
          paymentItem?.itemTotal
        )}.`,
      };
    case "Past Due Balance":
      return {
        title: "Past due balance",
        description: `Your past due balance is\n${formatCurrency(paymentItem?.itemTotal)}`,
      };
    default:
      return null;
  }
};

export default function renderPaymentItemTooltip(modalContext, paymentItem) {
  const { updateModalComponent, setModalVariant, setModalClassName, onModalClose } = modalContext;

  updateModalComponent(() => {
    const content = getRentPaymentItemToolTipContent(paymentItem);
    return (
      <>
        <div className="modal-dialog-content mt-6">
          <h3 className="text-xl-semibold color--charcoal text-center mb-2">{content?.title}</h3>
          <span className="modal-content-text text-center mb-0">{content?.description}</span>
        </div>
        <div className="action-container">
          <PrimaryButton className="blue-theme" onClick={onModalClose}>
            Got it
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.tooltipDialog);
  setModalClassName("hide-header");
}
