import { useCallback, useEffect } from "react";

import PrimaryButton from "../../Components/Buttons/primary-button";

import renderCreditBuilderOptIn from "../../Organisms/Modals/Credit-Builder/credit-builder-opt-in";
import renderCreditBuilderOptOut from "../../Organisms/Modals/Credit-Builder/credit-builder-opt-out";

import { useModalContext } from "../../Contexts/ModalContext";

import { CreditBuilderCoin, CreditBuilderSparkle } from "../../Assets/Images";

export default function CreditBuilder({ optedIn }) {
  const modalContext = useModalContext();

  const handleOpenCreditBuilder = useCallback(() => {
    if (optedIn) {
      renderCreditBuilderOptOut(modalContext);
    } else {
      renderCreditBuilderOptIn(modalContext);
    }
  }, [modalContext, optedIn]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get("openCreditBuilder") === "true") {
      handleOpenCreditBuilder();
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, [handleOpenCreditBuilder]);
  return (
    <div className="credit-builder mt-6">
      <img className="coin" src={CreditBuilderCoin} alt=""></img>
      <img className="sparkle" src={CreditBuilderSparkle} alt=""></img>
      <h3 className="title mb-sm-2 mb-1">Credit Builder</h3>
      <span className="description mb-6">
        Stake only reports on-time rent payments that help build your credit. Learn more.
      </span>
      <PrimaryButton className="blue-theme" onClick={handleOpenCreditBuilder}>
        {optedIn ? "Edit my preferences" : "Start"}
      </PrimaryButton>
    </div>
  );
}
