import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import CopyButton from "../../../Components/Others/copy-button";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useUserAccount } from "../../../CustomHooks/Queries";

import { validation } from "../../../Utils/validations";
import { dispatchSuccessToast, transformCountryCodeFormatToUSNumber } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

export default function renderMakeCashPayment(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(MakeCashPayment);
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("no-full-screen");
  setModalTitle("Get Started with Cash Payments");
}

const MakeCashPayment = () => {
  const { isLoading: isLoadingUserAccount, data: userAccount } = useUserAccount();

  const email = userAccount?.data?.data?.user?.email;
  const phoneNumber = userAccount?.data?.data?.user?.phoneNumber;
  const zipcode = userAccount?.data?.data?.user?.zipCode?.split("-")[0];

  const hasEmailAddress = validation.email.isAValidEmail(email) && !validation.email.isRandomEmail(email);

  return isLoadingUserAccount ? (
    <ModalSkeleton />
  ) : (
    <>
      <div className="modal-dialog-content">
        <div className="numbered-list mb-4">
          <div className="list-item">
            <span className="list-item-badge">1️⃣</span>
            <div className="list-item-content">
              <p className="title">Tap the Button Below</p>
              <p className="description">You'll be redirected to our Cash Payment portal.</p>
            </div>
          </div>
          <div className="list-item">
            <span className="list-item-badge">2️⃣</span>
            <div className="list-item-content">
              <p className="title">Log In Securely</p>
              <p className="description">Use your email or phone number and zip code to sign in.</p>
            </div>
          </div>
          <div className="list-item">
            <span className="list-item-badge">3️⃣</span>
            <div className="list-item-content">
              <p className="title">Get Your Barcode</p>
              <p className="description">
                Take the barcode to the cashier at your selected retailer along with the cash and make your rent
                payment. That's it!
              </p>
            </div>
          </div>
        </div>
        <p className="modal-content-text mb-3">Copy this info, you will need it to login in</p>
        <div className="account-detail-card mb-4">
          <div className="account-detail">
            <div className="account-detail-label">{hasEmailAddress ? "Email" : "Phone Number"}</div>
            <div className="account-detail-value">
              {hasEmailAddress ? email : transformCountryCodeFormatToUSNumber(phoneNumber)}
            </div>
          </div>
          <CopyButton
            text={hasEmailAddress ? email : phoneNumber}
            onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "user_identifier" })}
          />
        </div>
        <div className="account-detail-card">
          <div className="account-detail">
            <div className="account-detail-label">Zip Code</div>
            <div className="account-detail-value">{zipcode}</div>
          </div>
          <CopyButton text={zipcode} onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "zip_code" })} />
        </div>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={window.open.bind(this, "https://cash.stake.rent/", "_blank")}>
          Open Cash Payment Portal
        </PrimaryButton>
      </div>
    </>
  );
};
