import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import useStakePay3AuthToken from "../../../CustomHooks/useStakePay3AuthToken";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useStripePublicKey } from "../../../CustomHooks/Queries";
import { useCreateStripeSetupIntent } from "../../../CustomHooks/Mutations";

import { APIQueryKeys, contactStakeIfErrorPersistsMessage, modalVariants } from "../../../Utils/constants";

export default function renderAddStripeCardForm(modalContext) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  pushModalStack(() => renderAddStripeCardForm(modalContext));
  updateModalComponent(AddStripeCardForm);
  setModalVariant(modalVariants.formDialog);
  setModalTitle("Add New Card");
  setModalClassName("hide-back-button");
}

const AddStripeCardForm = () => {
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const { isLoading: isLoadingStripePublicKey } = useStripePublicKey({
    enabled: !isLoadingAuthToken,
    onSuccess: ({ data }) => setStripePromise(loadStripe(data?.data?.publicKey)),
  });

  const {
    isLoading: isCreatingStripeSetupIntent,
    mutate: CreateStripeSetupIntent,
    isError: isCreatingStripeSetupIntentError,
  } = useCreateStripeSetupIntent({
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        setStripeClientSecret(data?.data?.clientSecret);
      }
    },
  });

  const isLoading = isLoadingStripePublicKey || isCreatingStripeSetupIntent;

  useEffect(() => {
    if (
      !isLoadingAuthToken &&
      !isCreatingStripeSetupIntent &&
      !stripeClientSecret &&
      !isCreatingStripeSetupIntentError
    ) {
      CreateStripeSetupIntent();
    } //eslint-disable-next-line
  }, [isLoadingAuthToken, isCreatingStripeSetupIntent, stripeClientSecret, isCreatingStripeSetupIntentError]);

  const stripeElementOptions = {
    mode: "setup",
    currency: "usd",
  };

  return isLoading ? (
    <ModalSkeleton />
  ) : !isCreatingStripeSetupIntentError ? (
    <Elements stripe={stripePromise} options={stripeElementOptions}>
      <AddCardForm stripeClientSecret={stripeClientSecret} />
    </Elements>
  ) : (
    <div className="modal-dialog-content mb-6">
      <div className="modal-content-text">{contactStakeIfErrorPersistsMessage}</div>
    </div>
  );
};

const AddCardForm = ({ stripeClientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const queryClient = useQueryClient();

  const { popModalStack } = useModalContext();

  const [isSubmittingCard, setIsSubmittingCard] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState(null);

  const [refetchingCardsWithDebounce, setRefetchingCardsWithDebounce] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    setIsSubmittingCard(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setIsSubmittingCard(false);
      setStripeErrorMessage(submitError?.message);
      return;
    }

    const stripeResponse = await stripe.confirmSetup({
      elements,
      clientSecret: stripeClientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    setIsSubmittingCard(false);

    if (stripeResponse?.error) {
      setStripeErrorMessage(stripeResponse?.error?.message);
    } else {
      setRefetchingCardsWithDebounce(true);
      setTimeout(() => {
        queryClient.refetchQueries(APIQueryKeys.finixConnectedCards);
        popModalStack(1);
      }, 5000);
    }
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="d-flex flex-column modal-dialog-content" style={{ minHeight: "280px" }}>
        {!refetchingCardsWithDebounce ? (
          <>
            <PaymentElement />
            {!!stripeErrorMessage && (
              <div className="d-block text-xs-regular color--error-red mt-2">{stripeErrorMessage}</div>
            )}
          </>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
            <CircularProgress size={24} sx={{ color: "var(--charcoal)" }} />
            <div className="modal-content-text mt-2">Please wait while we fetch your card details...</div>
          </div>
        )}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" type="submit" disabled={isSubmittingCard || refetchingCardsWithDebounce}>
          Add Card {isSubmittingCard && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" type="button" onClick={() => popModalStack(1)}>
          Discard
        </PrimaryButton>
      </div>
    </form>
  );
};
