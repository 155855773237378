import { useState } from "react";
import { CircularProgress, Collapse } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import useCollapseComponents from "../../../CustomHooks/useCollapseComponents";

import { useModalContext } from "../../../Contexts/ModalContext";

import { formatCurrency, formatDate, getNumberWithOrdinal } from "../../../Utils/helpers";
import { longMonthDateFormat, modalVariants } from "../../../Utils/constants";

import { WarningTriangle } from "../../../Assets/Icons";

export default function renderAuthorizeRentPayment(modalContext, props) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  pushModalStack(() => renderAuthorizeRentPayment(modalContext, props));
  updateModalComponent(() => <AuthorizeRentPayment {...props} />);
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("has-transfer-info full-modal-width hide-back-button");
  setModalTitle(`Authorize${props.isAutopay ?? false ? " Autopay" : ""} Rent Payment`);
}

export const AuthorizeRentPayment = (props) => {
  const {
    stakeDebit,
    selectedAccount,
    selectedFinixCard,
    paymentTotal,
    paymentSplitUp,
    paymentDate,
    isAutopay = false,
    AuthorizeCallback,
    isInsufficientFunds,
  } = props;

  const modalContext = useModalContext();

  const { popModalStack } = modalContext;

  const [isLoading, setIsLoading] = useState(false);

  const { showListItem, CollapseButton } = useCollapseComponents(true);

  const handleAuthorize = () => {
    setIsLoading(true);
    AuthorizeCallback();
  };

  return (
    <>
      <div className="modal-dialog-content">
        {isInsufficientFunds && (
          <div className="info-alert error mb-4">
            <WarningTriangle />
            Insufficient funds for rent payment. Do you still want to proceed?
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">{isAutopay ? "Starts From" : "Debit On"}</span>
          <span className="detail-value">{formatDate(paymentDate, longMonthDateFormat)}</span>
        </div>
        {isAutopay && (
          <div className="transfer-detail">
            <span className="detail-label">Frequency</span>
            <span className="detail-value">
              {getNumberWithOrdinal(parseInt(paymentDate?.split("-")?.[2]))} of every month
            </span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Payment Method</span>
          <span className="detail-value">
            {!!stakeDebit
              ? "Stake Checking"
              : !!selectedAccount
              ? "Checking Account / ACH"
              : !!selectedFinixCard
              ? "Credit / Debit Card"
              : ""}
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Debit From</span>
          <span className="detail-value text-capitalize">
            {!!stakeDebit
              ? `Stake Checking (... ${stakeDebit?.account_number?.slice(-4)})`
              : !!selectedAccount
              ? `${selectedAccount?.name} (...${selectedAccount?.mask})`
              : !!selectedFinixCard
              ? `${selectedFinixCard.cardBrand?.toLowerCase()} (...${selectedFinixCard.cardLast4Digits})`
              : ""}
          </span>
        </div>
        {(!!stakeDebit || !!selectedAccount) && (
          <div className="transfer-detail">
            <span className="detail-label">Routing Number</span>
            <span className="detail-value">
              {!!stakeDebit ? stakeDebit.routing_number : !!selectedAccount ? selectedAccount?.routing : ""}
            </span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Total Amount</span>
          <CollapseButton className="detail-value">{formatCurrency(paymentTotal)}</CollapseButton>
        </div>
        <Collapse in={showListItem}>
          <div className="payment-item-list mt-2">
            {paymentSplitUp?.map(({ subItemName, subItemTotal }, idx) => (
              <div key={idx} className="transfer-detail">
                <span className="detail-label">{subItemName}</span>
                <span className="detail-value">{formatCurrency(subItemTotal)}</span>
              </div>
            ))}
          </div>
        </Collapse>
        {!!selectedAccount && (
          <span className="disclaimer mt-4">*Transactions will take 2-3 business days to be processed.*</span>
        )}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={handleAuthorize} disabled={isLoading}>
          Authorize {isLoading && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={() => popModalStack(1)}>
          Back
        </PrimaryButton>
      </div>
    </>
  );
};
