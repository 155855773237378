import { useState } from "react";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";
import { useFormContext } from "react-hook-form";

import DatePickerField from "../../../Components/Form Fields/date-picker-field";
import SSNField from "../../../Components/Form Fields/ssn-field";
import TextField from "../../../Components/Form Fields/text-field";
import CheckBox from "../../../Components/Form Fields/checkbox";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderOptedIn from "./Opted-In/opted-in";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useDeals, useUserAccount } from "../../../CustomHooks/Queries";
import { useOptInCreditReporting } from "../../../CustomHooks/Mutations";

import { validation } from "../../../Utils/validations";
import {
  dispatchAPIErrorToast,
  formatDate,
  getPublicIPAddress,
  replaceNonNumericCharacters,
} from "../../../Utils/helpers";
import { APIQueryKeys, longMonthDateFormat, modalVariants } from "../../../Utils/constants";

import { Credit_Builder_Opt_In } from "../../../Assets/Images";

export default function renderCreditBuilderOptIn(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(CreditBuilderOptIn);
  setModalVariant(modalVariants.flexDialog);
  setModalTitle("");
  setModalClassName("position-close-button-over-content");
}

const CreditBuilderOptIn = () => {
  const modalContext = useModalContext();
  const { setModalTitle, setModalClassName } = modalContext;

  const { watch, register, handleSubmit } = useFormContext();

  const queryClient = useQueryClient();

  const [step, setStep] = useState(1);

  const { data: deals } = useDeals();

  const { data: userAccountData } = useUserAccount();

  const { isLoading: isCreditReportingOptingIn, mutate: OptInCreditReporting } = useOptInCreditReporting({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.creditReporting);
      renderOptedIn(modalContext);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const nameOfTheUser = `${userAccountData?.data?.data?.user?.firstName} ${userAccountData?.data?.data?.user?.lastName}`;

  const dob = watch("dob");
  const ssn = watch("ssn");
  const name = watch("name");
  const agreeToTerms = watch("agreeToTerms") ?? false;

  const isTypedNameSameAsNameOfTheUser = name?.trim() === nameOfTheUser?.trim();

  const handleNextButtonClick = async () => {
    if (step < 4) {
      setStep((p) => p + 1);
      if (step === 3) {
        setModalTitle("Confirm Your Credit Builder Enrollment");
        setModalClassName("full-modal-width");
      }
    } else {
      OptInCreditReporting({
        ...(!!dob ? { dob } : {}),
        ...(!!ssn ? { ssn: replaceNonNumericCharacters(ssn) } : {}),
        user_ip: await getPublicIPAddress(),
      });
    }
  };

  const handleBackButtonClick = () => {
    if (step === 4) {
      setModalTitle("");
      setModalClassName("position-close-button-over-content");
    }
    setStep((p) => p - 1);
  };

  const birthDate = deals?.data?.data?.currentDeal?.birthdate;
  const ssn_token = deals?.data?.data?.currentDeal?.ssn_token__c;

  const disableCTAButton =
    isCreditReportingOptingIn ||
    (step === 4 &&
      ((!dob && !birthDate) || (!ssn && !ssn_token) || !name || !isTypedNameSameAsNameOfTheUser || !agreeToTerms));

  return (
    <>
      {step === 4 ? (
        <form
          className="d-flex flex-column flex-grow-1"
          onSubmit={handleSubmit(handleNextButtonClick)}
          autoComplete="off"
        >
          <div className="modal-dialog-content flex-grow-1">
            <span className="modal-content-text">Please review and agree to the declaration below.</span>
            <div className="border--rounded-md bg--smoke py-5 px-4 mt-3">
              <div className="modal-content-text mb-4">
                I consent to having Stake Network, Inc., on behalf of my rental community, report my on-time rent
                payments to major credit bureaus.
              </div>
              <div className="modal-content-text mb-4">
                I understand that this program is optional and I am able to opt-out at any time, subject to a 6-month
                waiting period to re-enroll. I also understand that a late or missed rent payment, after enrollment,
                will automatically opt me out of this service, with a 6-month waiting period to re-enroll.
              </div>
              <div className="modal-content-text mb-4">Executed {formatDate(new Date(), longMonthDateFormat)}</div>
              <div className="modal-content-text">By: {nameOfTheUser}</div>
            </div>
            {!birthDate && (
              <div className="mt-4">
                <DatePickerField
                  name="dob"
                  label="Date of Birth"
                  validations={{
                    isAValidDate: (dob) => validation.date.isAValidDate(dob) || "Please enter a valid date.",
                    isAbove18YearsOfAge: (dob) =>
                      validation.date.isAbove18YearsOfAge(dob) || "You must be at least 18 years old.",
                  }}
                  datePickerProps={{ disableFuture: true }}
                  textFieldProps={{ placeholder: "MM/DD/YYYY", fullWidth: true }}
                  required={true}
                />
              </div>
            )}
            {!ssn_token && (
              <SSNField
                className="mt-5"
                description="Your SSN will be securely handled in compliance with privacy regulations."
              />
            )}
            <span className="modal-content-text mt-4 mb-3">
              Enter your full name to agree to the above declaration and confirm your enrollment.
            </span>
            <TextField
              {...register("name", { required: true })}
              inputProps={{ style: { fontFamily: "Playball", fontSize: "28px", lineHeight: "28px" } }}
            />
            <span className="modal-content-text mt-3">Sign as {nameOfTheUser}</span>
            <div className="d-flex align-items-start gap-2 mt-4">
              <CheckBox {...register("agreeToTerms", { required: true })} checked={agreeToTerms} />
              <p className="text-base-regular color--charcoal mb-0">
                By checking this box, I attest to have read and agree to the Stake's{" "}
                <a
                  className="color--stake-blue"
                  href="https://www.stakerent.com/legal/end-user-terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  End User Terms
                </a>{" "}
                and{" "}
                <a
                  className="color--stake-blue"
                  href="https://www.stakerent.com/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
          <div className="action-container split-equally">
            <PrimaryButton className="blue-theme" disabled={disableCTAButton} type="submit">
              Confirm {isCreditReportingOptingIn && <CircularProgress />}
            </PrimaryButton>
            <PrimaryButton
              className="secondary-colors"
              onClick={handleBackButtonClick}
              disabled={step === 1}
              type="button"
            >
              Back
            </PrimaryButton>
          </div>
        </form>
      ) : (
        <>
          <div className="modal-image-carousel position-relative">
            <img className="credit-builder-opt-in" src={Credit_Builder_Opt_In} alt="" />
            <div className="carousel-indicators">
              {Array.from(new Array(3)).map((_, idx) => (
                <button
                  key={idx}
                  data-bs-target=""
                  className={step === idx + 1 ? "active" : ""}
                  onClick={() => setStep(idx + 1)}
                ></button>
              ))}
            </div>
          </div>
          <div className="modal-dialog-content">
            <span className="modal-content-text highlight mt-1">
              {step === 1
                ? "Automatically boost your credit score by letting Stake report your on-time rent payments."
                : step === 2
                ? "There's no risk. Stake will only report your payments if they are on time. You can opt out at any time."
                : step === 3
                ? "Stake is a licensed data furnisher with the credit bureaus. We help you build credit, for free."
                : ""}
            </span>
          </div>
          <div className="action-container">
            <PrimaryButton className="blue-theme" onClick={handleNextButtonClick}>
              {step <= 2 ? "Next" : "Opt In"}
            </PrimaryButton>
            <PrimaryButton className="secondary-colors" onClick={handleBackButtonClick} disabled={step === 1}>
              Back
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
};
