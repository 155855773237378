import CopyButton from "../../../Components/Others/copy-button";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

import { dispatchSuccessToast } from "../../../Utils/helpers";

export default function renderAccountDetails(modalContext, stakeDebit = {}) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  const accountNumber = stakeDebit?.data?.account_number ?? "";
  const routingNumber = stakeDebit?.data?.routing_number ?? "";

  updateModalComponent(() => (
    <>
      <div className="modal-dialog-content">
        <div className="account-detail-card mb-4">
          <div className="account-detail">
            <div className="account-detail-label">Account Number</div>
            <div className="account-detail-value">{accountNumber}</div>
          </div>
          <CopyButton
            text={accountNumber}
            onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
          />
        </div>
        <div className="account-detail-card">
          <div className="account-detail">
            <div className="account-detail-label">Routing Number</div>
            <div className="account-detail-value">{routingNumber}</div>
          </div>
          <CopyButton
            text={routingNumber}
            onCopy={() => dispatchSuccessToast("Copied to clipboard", { key: "account_number" })}
          />
        </div>
      </div>
      <div className="action-container">
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Close
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Account and Routing Number");
}
