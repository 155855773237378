import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

// import SkeletonLoader from "../../Components/Skeleton/skeleton-loader";

import { useLogout } from "../../CustomHooks/Mutations";

import { isActiveModule, onSignOut } from "../../Utils/helpers";
import { navItems } from "../../Utils/constants";

import { StakeLogo } from "../../Assets/Images";
import { Support, Logout, CircleQuestion } from "../../Assets/Icons";

export default function SideBar() {
  const { pathname } = useLocation();

  const { show: showIntercom } = useIntercom();

  const { mutate: InitLogout } = useLogout({
    onSettled: onSignOut,
  });

  return (
    <div className="app-utility-sidebar">
      <StakeLogo className="logo dark-with-accentuated-horn" />
      <div className="sidebar-nav">
        {/* {false ? (
          navItems.map((_, idx) => (
            <div className="d-flex gap-4 px-6 py-3" key={idx}>
              <SkeletonLoader className="border--rounded-sm" width={24} height={24} />
              <SkeletonLoader className="border--rounded-sm" width={120} height={24} />
            </div>
          ))
        ) : (
          <> */}
        {navItems.map(({ Icon, navItemName, navRoute }, idx) => (
          <Link
            key={idx}
            className={`sidebar-nav-item${isActiveModule(navRoute, pathname) ? " active" : ""}`}
            to={navRoute}
          >
            <Icon className="nav-icon" />
            {navItemName}
          </Link>
        ))}
        <span className="spacer"></span>
        <button className="sidebar-nav-item" onClick={window.open.bind(this, "https://www.stake.rent/faq", "_blank")}>
          <CircleQuestion className="nav-icon" />
          FAQ
        </button>
        <button className="sidebar-nav-item" onClick={showIntercom}>
          <Support className="nav-icon" />
          Support
        </button>
        <button className="sidebar-nav-item" onClick={InitLogout}>
          <Logout className="nav-icon" />
          Logout
        </button>
        {/* </>
        )} */}
      </div>
    </div>
  );
}
