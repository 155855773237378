import { useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { RentPaymentItem } from "./components";
import PrimaryButton from "../../Components/Buttons/primary-button";

import renderPaymentItemTooltip from "../../Organisms/Modals/Stake Pay 3/payment-item-tooltip";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken";

import { useModalContext } from "../../Contexts/ModalContext";

import { useStakePay3PaymentDetails } from "../../CustomHooks/Queries";

import { formatCurrency } from "../../Utils/helpers";
import { contactStakeIfErrorPersistsMessage } from "../../Utils/constants";

import { Receipt, RentDetails as RentDetailsIllustration } from "../../Assets/Images";

export default function SelectPaymentItems({ onNext }) {
  const navigate = useNavigate();

  const modalContext = useModalContext();

  const { handleSubmit } = useFormContext();

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const {
    isLoading: isLoadingPaymentDetails,
    data: paymentDetails,
    error: paymentDetailsError,
    isError: isPaymentDetailsError,
  } = useStakePay3PaymentDetails({
    enabled: !isLoadingAuthToken,
  });

  const address = useMemo(() => {
    const addressDetails = paymentDetails?.data?.data?.addressDetails;
    if (!addressDetails || !addressDetails?.address1) return "";

    if (!!addressDetails?.address2) {
      return `${addressDetails.address1} ${addressDetails.address2}\n${addressDetails?.subEntityName ?? ""}`;
    } else {
      return `${addressDetails.address1}\n${addressDetails?.subEntityName ?? ""}`;
    }
  }, [paymentDetails]);

  const rentPaymentDetails = paymentDetails?.data?.data?.rentPaymentDetails;

  const onSumbit = () => {
    onNext(rentPaymentDetails?.[0]);
  };

  return isLoadingAuthToken || isLoadingPaymentDetails ? (
    <div className="loading-content card-section-loader">
      <CircularProgress />
    </div>
  ) : isPaymentDetailsError ? (
    <div className="content error">
      <Receipt />
      <h3 className="error-title">
        {paymentDetailsError?.response?.status === 404 ? "No Lease Found" : "Oops... Something went wrong :("}
      </h3>
      <p className="error-description">
        {paymentDetailsError?.response?.status === 404
          ? "We couldn't find an active lease linked to your account. If you believe this is an error, please contact support."
          : contactStakeIfErrorPersistsMessage}
      </p>
      <PrimaryButton className="blue-theme" onClick={navigate.bind(this, "/renting")}>
        Back to renting
      </PrimaryButton>
    </div>
  ) : (
    <>
      <div className="rent-details">
        <div className="detail">
          <span className="detail-name">My Home</span>
          <span className="detail-value">{address}</span>
        </div>
        <img className="illustration" src={RentDetailsIllustration} alt="" />
      </div>
      <form className="content" onSubmit={handleSubmit(onSumbit)} autoComplete="off">
        <div className="top-container">
          <div className="payment-card">
            {rentPaymentDetails?.map((paymentItem, idx) => (
              <RentPaymentItem
                key={idx}
                rentPaymentLabel={paymentItem?.itemName}
                rentPaymentValue={formatCurrency(paymentItem?.itemTotal)}
                rentPaymentDetails={paymentItem?.itemDetails}
                openCollapse={true}
                onKnowMore={renderPaymentItemTooltip.bind(this, modalContext, paymentItem)}
              />
            ))}
            {rentPaymentDetails?.length === 0 && <span className="no-rent-item-found">No Rent Payments found</span>}
          </div>
        </div>
        <div className="bottom-container">
          <PrimaryButton className="blue-theme mb-4" type="submit" disabled={rentPaymentDetails?.length === 0}>
            Set Up Payment
          </PrimaryButton>
          <PrimaryButton className="secondary-colors mb-4" type="button" onClick={navigate.bind(this, "/renting")}>
            Back to Renting
          </PrimaryButton>
          <p className="text-xxs-regular color--medium-grey text-center m-0">
            Amounts subject to change - late fees or utility payments charged later to your default payment method.
          </p>
        </div>
      </form>
    </>
  );
}
