import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderStakeCheckingDenied(modalContext) {
  const {
    updateModalComponent,
    pushModalStack,
    setModalVariant,
    setModalPromoComponent,
    setModalTitle,
    setModalClassName,
  } = modalContext;

  updateModalComponent(StakeCheckingDenied);
  pushModalStack(() => renderStakeCheckingDenied(modalContext));
  setModalPromoComponent(null);
  setModalTitle("");
  setModalClassName("position-close-button-over-content");
  setModalVariant(modalVariants.flexDialog);
}

const StakeCheckingDenied = () => {
  const { popModalStack } = useModalContext();

  return (
    <>
      <div className="modal-image svd-single-card" src={SVD_Single_Card} alt="">
        <img src={SVD_Single_Card} alt="" />
      </div>
      <div className="modal-dialog-content">
        <h3 className="modal-status-title mt-6 mb-5">Sorry, your virtual Stake Visa Debit application was denied</h3>
        <ul className="benefits-list">
          <li className="alert-list-style">
            We're sorry, but your application for the Stake Visa Debit was not approved at this time. You can still pay
            your rent and earn Cash Back with Stake.
          </li>
        </ul>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={() => popModalStack(1)}>
          Continue
        </PrimaryButton>
      </div>
    </>
  );
};
