import React, { createContext, useContext, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import { modalVariants } from "../Utils/constants";

import { ArrowLeft, CrossMark } from "../Assets/Icons";

const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalClassName, setModalClassName] = useState(null);
  const [preventCloseOnBackdropClick, setPreventCloseOnBackdropClick] = useState(false);
  const [ModalComponent, setModalComponent] = useState(() => React.Fragment);

  const [modalVariant, setModalVariant] = useState(modalVariants.infoDialog);
  const [modalTitle, setModalTitle] = useState("");
  const [modalStep, setModalStep] = useState(1);
  const [ModalPromoComponent, setModalPromoComponent] = useState(null);
  const [modalOnCloseCallBack, setModalOnCloseCallBack] = useState(null);
  const [modalFormDefaultValues, setModalFormDefaultValues] = useState({});

  const [modalStack, setModalStack] = useState([]);

  const removeComponentTimeoutRef = useRef(null);

  const formProps = useForm({ values: modalFormDefaultValues, resetOptions: { keepDirtyValues: true } });

  const updateModalComponent = (Component) => {
    clearTimeout(removeComponentTimeoutRef?.current);
    removeComponentTimeoutRef.current = null;
    setModalComponent(() => Component);
    setModalVisibility(true);
  };

  const removeModalComponent = () => {
    setModalComponent(() => () => React.Fragment);
  };

  const pushModalStack = (renderCallback) => {
    setModalStack((p) => {
      return [...p, renderCallback];
    });
  };

  const popModalStack = (i) => {
    const modalRenderCallbackAtPopIndex = modalStack[modalStack.length - i - 1];
    if (!!modalRenderCallbackAtPopIndex) {
      modalRenderCallbackAtPopIndex();
    } else {
      onModalClose();
    }

    setModalStack((p) => p.slice(0, modalStack.length - i));
  };

  const updateModalFormDefaultValues = (defaultValues) =>
    setModalFormDefaultValues((p) => ({ ...p, ...defaultValues }));

  const updateModalOnCloseCallBack = (callback) => setModalOnCloseCallBack(() => callback);

  const onBackButtonClick = () => {
    if (modalVariant === modalVariants.stepByStepDialog && modalStep > 1) {
      setModalStep((step) => step - 1);
    } else if (modalStack.length > 1) {
      popModalStack(1);
    }
  };

  const onModalClose = ({ callOnClose, abortCallingOnCloseCallback = false } = {}) => {
    if (!!modalOnCloseCallBack && !abortCallingOnCloseCallback) {
      modalOnCloseCallBack();
    }

    if (!!callOnClose) {
      callOnClose();
    }

    setModalVisibility(false);

    removeComponentTimeoutRef.current = setTimeout(() => {
      removeModalComponent();
      setModalStack([]);
      setModalClassName(null);
      setPreventCloseOnBackdropClick(false);
      setModalVariant(modalVariants.infoDialog);
      setModalTitle("");
      setModalStep(1);
      setModalPromoComponent(null);
      setModalOnCloseCallBack(null);
      setModalFormDefaultValues({});
      formProps.reset();
      removeComponentTimeoutRef.current = null;
      document.body.style.overflow = null;
    }, 250);
  };

  return (
    <ModalContext.Provider
      value={{
        modalVisibility,
        setModalVisibility,
        modalClassName,
        setModalClassName,
        preventCloseOnBackdropClick,
        setPreventCloseOnBackdropClick,
        modalVariant,
        setModalVariant,
        modalTitle,
        setModalTitle,
        ModalComponent,
        updateModalComponent,
        removeModalComponent,
        onModalClose,
        modalStep,
        setModalStep,
        ModalPromoComponent,
        setModalPromoComponent,
        modalOnCloseCallBack,
        updateModalOnCloseCallBack,
        modalStack,
        setModalStack,
        pushModalStack,
        popModalStack,
        updateModalFormDefaultValues,
      }}
    >
      {children}
      <FormProvider {...formProps}>
        <Dialog
          className={`app-utility-modal ${modalClassName ?? ""} ${modalVariant}`}
          open={modalVisibility}
          onClose={(_, reason) => {
            if (preventCloseOnBackdropClick && reason === "backdropClick") return;
            onModalClose();
          }}
        >
          {!!ModalPromoComponent && (
            <div className="modal-promo-section">
              <ModalPromoComponent />
            </div>
          )}
          <div className="modal-dialog-header">
            {(modalVariant === modalVariants.stepByStepDialog || modalStack.length > 1) && (
              <button
                className="go-back-button"
                onClick={onBackButtonClick}
                disabled={modalVariant === modalVariants.stepByStepDialog && modalStep === 1 && modalStack.length <= 1}
              >
                <ArrowLeft />
              </button>
            )}
            <h3 className="title">{modalTitle}</h3>
            <button className="close-button" onClick={onModalClose}>
              <CrossMark />
            </button>
          </div>
          <ModalComponent />
        </Dialog>
      </FormProvider>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const modalContextData = useContext(ModalContext);
  if (modalContextData === undefined) {
    throw new Error("useModalConntext must be used within a ModalContextProvider");
  }
  return modalContextData;
};
