import AddFunds from "./add-funds";

import { modalVariants } from "../../../Utils/constants";

export default function renderGetRecurringDepositAmountDetails(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName, pushModalStack } = modalContext;

  pushModalStack(() => renderGetRecurringDepositAmountDetails(modalContext, props));
  updateModalComponent(() => <AddFunds isRecurringDeposit {...props} />);
  setModalTitle("Recurring Deposit");
  setModalClassName("rendering-page-content");
  setModalVariant(modalVariants.fullScreenDialog);
}
