import { useMutation } from "react-query";

import { APIhandler } from "../../Utils/api";

import { convertObjectToFormData, getPublicIPAddress, injectAttributes, LocalStorage } from "../../Utils/helpers";
import { appUtilityKeys } from "../../Utils/constants";

export const useRequestLogin2FACode = (config = {}) => {
  return useMutation((data) => APIhandler.login.requestLogin2FACode(data), config);
};

export const useLoginWith2FACode = (config = {}) => {
  return useMutation((data) => APIhandler.login.loginWith2FACode(data), config);
};

export const useClaimUnclaimedTransactions = (config = {}) => {
  return useMutation(() => APIhandler.unclaimedTransactions.claimUnclaimedTransactions(), config);
};

export const useCreateLinkToken = (config = {}) => {
  return useMutation(() => APIhandler.tokens.createLinkToken(), config);
};

export const useSetAccessToken = (config = {}) => {
  return useMutation((payload) => APIhandler.tokens.setAccessToken(injectAttributes(payload, ["userId"])), config);
};

export const useSaveDefaultConnectedAccount = (config = {}) => {
  return useMutation(
    (payload) => APIhandler.bankConnections.saveDefaultConnectedAcccount(injectAttributes(payload, ["userId"])),
    config
  );
};

export const useResetConnectedAccountSelection = (config = {}) => {
  return useMutation((payload) => {
    return APIhandler.bankConnections.resetConnectedAcccount(payload);
  }, config);
};

export const useAlternativeIdentifyVerify = (config = {}) => {
  return useMutation((data) => APIhandler.alternativeLogin.alternativeIdentifyVerify(data), config);
};

export const useLoginViaAlternativeLogin = (config = {}) => {
  return useMutation((data) => APIhandler.alternativeLogin.loginViaAlternativeLogin(data), config);
};

export const useSaveSpendingFinancialGoals = (config = {}) => {
  return useMutation((data) => APIhandler.onboarding.saveSpendingFinancialGoals(data), config);
};

export const useReplaceCard = (config = {}) => {
  return useMutation((data) => APIhandler.stakeVisaDebit.replaceCard(data), config);
};

export const useInitiateVenmoTransfer = (config = {}) => {
  return useMutation((data) => APIhandler.transfers.initiateVenmoTransfer(data), config);
};

export const useInitiatePlaidWithdrawal = (config = {}) => {
  return useMutation((data) => APIhandler.transfers.initiatePlaidWithdrawal(data), config);
};

export const useInitiateACHDebit = (config = {}) => {
  return useMutation((data) => APIhandler.transfers.initiateACHDebit(data), config);
};

export const useWithdrawal = (config = {}) => {
  return useMutation((data) => APIhandler.transfers.withdrawal(data), config);
};

export const useOptInCreditReporting = (config = {}) => {
  return useMutation((payload) => APIhandler.creditBuilder.optInCreditReporting(payload), config);
};

export const useOptOutCreditReporting = (config = {}) => {
  return useMutation(() => APIhandler.creditBuilder.optOutCreditReporting(), config);
};

export const useUpdateEmailAddress = (config = {}) => {
  return useMutation((data) => APIhandler.resident.updateEmailAddress(data), config);
};

export const useUpdatePhoneNumber = (config = {}) => {
  return useMutation((data) => APIhandler.resident.updatePhoneNumber(data), config);
};

export const useUpdateDateOfBirthOrSNN = (config = {}) => {
  return useMutation((data) => APIhandler.resident.updateDateOfBirthOrSNN(data), config);
};

export const useEmailChangeRequestVerification = (config = {}) => {
  return useMutation((payload) => APIhandler.resident.emailChangeRequestVerification(payload), config);
};

export const useEmailChangeVerification = (config = {}) => {
  return useMutation((payload) => APIhandler.resident.emailChangeVerification(payload), config);
};

export const usePhoneNumberChangeRequestVerification = (config = {}) => {
  return useMutation((payload) => APIhandler.resident.phoneNumberChangeRequestVerification(payload), config);
};

export const usePhoneNumberChangeVerification = (config = {}) => {
  return useMutation((payload) => APIhandler.resident.phoneNumberChangeVerification(payload), config);
};

export const useRequestVirtualStakeDebit = (config = {}) => {
  return useMutation(() => APIhandler.stakeVisaDebit.requestVirtualStakeDebit(), config);
};

export const useRequestStakeDebitCard = (config = {}) => {
  return useMutation(() => APIhandler.stakeVisaDebit.requestStakeDebitCard(), config);
};

export const useCustomerTokenVerificationResponse = (config = {}) => {
  return useMutation((payload) => APIhandler.stakeVisaDebit.getCustomerTokenVerificationResponse(payload), config);
};

export const useDownloadStatement = (config = {}) => {
  return useMutation((statementId) => APIhandler.stakeVisaDebit.getStateDebitStatement(statementId), config);
};

export const useLogout = (config = {}) => {
  return useMutation(() => APIhandler.logout.logout(), config);
};

// New Astra mutation endpoints
export const useGetAstraTokenUsingSessionToken = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.getAstraTokenUsingSessionToken(payload), config);
};

export const useGetNewAstraTokens = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.getNewAstraTokens(payload), config);
};

export const useCreateUserIntent = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createUserIntent(payload), config);
};

export const useCreateTrustedAuthenticationToken = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createTrustedAuthenticationToken(payload), config);
};

export const useCreateTransferRoutine = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createTransferRoutine(payload), config);
};

export const useEnableCardToCardPayment = (config = {}) => {
  const payload = { astraToken: LocalStorage.read(appUtilityKeys.astraToken) };
  return useMutation((cardId) => APIhandler.astra.enableCardToCardPayment(cardId, payload), config);
};

export const useDeleteAstraCard = (config = {}) => {
  return useMutation((cardId) => APIhandler.astra.deleteAstraCard(cardId), config);
};

export const useCreateAccountToCardTransferRoutineWithoutAuthToken = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createAccountToCardTransferRoutineWithoutAuthToken(payload), config);
};

export const useCreateAccountToCardTransferRoutine = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createAccountToCardTransferRoutine(payload), config);
};

export const useCreateAstraAccount = (config = {}) => {
  return useMutation((payload) => APIhandler.astra.createAstraAccount(payload), config);
};

export const usePayrollLinkToken = (config = {}) => {
  return useMutation(() => APIhandler.payroll.getPayrollLinkToken(), config);
};

export const useUpdatePayrollAccountToken = (config = {}) => {
  return useMutation((accountId) => APIhandler.payroll.getUpdatePayrollAccountToken(accountId), config);
};

export const useDisconnectPayrollAccount = (config = {}) => {
  return useMutation((accountId) => APIhandler.payroll.disconnectPayrollAccount(accountId), config);
};

export const useActivateRecurringDeposit = (config = {}) => {
  return useMutation(
    (payload) => APIhandler.recurringDeposits.activateRecurringDeposit(injectAttributes(payload, ["userId"])),
    config
  );
};

export const useClearRecurringDeposit = (config = {}) => {
  return useMutation(
    () => APIhandler.recurringDeposits.clearRecurringDeposit(injectAttributes({}, ["userId"])),
    config
  );
};

export const useAgreeToS = (config = {}) => {
  return useMutation(
    async () =>
      APIhandler.termsOfService.agreeToS(
        convertObjectToFormData({ current_user_ip_address: await getPublicIPAddress() })
      ),
    config
  );
};

//StakePay 3

export const useStakePay3SetupPayment = (config = {}) => {
  return useMutation((payload) => APIhandler.stakepay3.setupPayment(payload), config);
};

export const useStakePay3CancelPayment = (config = {}) => {
  return useMutation((payload) => APIhandler.stakepay3.cancelPayment(payload), config);
};

export const useStakePay3EditPayment = (config = {}) => {
  return useMutation((payload) => APIhandler.stakepay3.editPayment(payload), config);
};

// PAYMENT METHODS
export const useDeleteFinixCard = (config = {}) => {
  return useMutation((payload) => APIhandler.finix.deleteCard(payload), config);
};

export const useCreateFinixSetupIntent = (config = {}) => {
  return useMutation((payload) => APIhandler.finix.createFinixSetupIntent(payload), config);
};

export const useCreateFinixPaymentInstrument = (config = {}) => {
  return useMutation((payload) => APIhandler.finix.createFinixPaymentInstruments(payload), config);
};

export const useCreateStripeSetupIntent = (config = {}) => {
  return useMutation((payload) => APIhandler.stripe.createStripeSetupIntent(payload), config);
};
