import { useState } from "react";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import useApplyStakeDebit from "../../../CustomHooks/useApplyStakeDebit";

import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderApplyStakeChecking(modalContext) {
  const { updateModalComponent, setModalVariant, setModalClassName, setModalPromoComponent } = modalContext;

  updateModalComponent(ApplyStakeChecking);
  setModalPromoComponent(null);
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("position-close-button-over-content");
}

const ApplyStakeChecking = () => {
  const [renderApplyDialog, setRenderApplyDialog] = useState(false);

  const {
    applyingSVD,
    handleStakeCheckingModalNotApproved,
    stakeDebitQuery: { data: stakeDebit },
  } = useApplyStakeDebit();

  const handleNextButtonClick = () => {
    if (!renderApplyDialog) {
      setRenderApplyDialog(true);
    } else {
      handleStakeCheckingModalNotApproved(stakeDebit?.data);
    }
  };

  return (
    <>
      <div className="modal-image-carousel position-relative">
        <div className="modal-image svd-single-card">
          <img src={SVD_Single_Card} alt="" />
        </div>
        <div className="carousel-indicators">
          {Array.from(new Array(2)).map((_, idx) => (
            <button
              key={idx}
              data-bs-target=""
              className={!!renderApplyDialog === !!idx ? "active" : ""}
              onClick={() => setRenderApplyDialog(!!idx)}
            ></button>
          ))}
        </div>
      </div>
      <div className="modal-dialog-content">
        <h3 className="modal-status-title text-center mb-0">
          {!renderApplyDialog ? "Earn more Cash Back" : "Stake just got easier"}
        </h3>
        <span className="modal-content-text text-center mt-1">
          {!renderApplyDialog
            ? "1% Cash Back on everything, including rent, forever."
            : "The rewards you know and love, now on a card. No waiting, no fees, and more rewards."}
        </span>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={handleNextButtonClick} disabled={applyingSVD}>
          {!renderApplyDialog ? "Next" : applyingSVD ? "Applying..." : "Apply Now"}{" "}
          {applyingSVD && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton
          className="secondary-colors"
          onClick={setRenderApplyDialog.bind(this, false)}
          disabled={!renderApplyDialog && applyingSVD}
        >
          Back
        </PrimaryButton>
      </div>
      {renderApplyDialog && !!stakeDebit?.data?.data?.disclosure_text && (
        <span className="text-xxs-regular color--medium-grey text-center mt-0 mb-4 mx-sm-6 mx-4">
          {stakeDebit?.data?.data?.disclosure_text}
        </span>
      )}
    </>
  );
};
