import React from "react";
import { OutlinedInput } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IMaskInput } from "react-imask";

import { validation } from "../../Utils/validations";

import { phoneNumberMask } from "../../Utils/constants";

import { removeControlCharacters } from "../../Utils/helpers";

export default function UserNameField({ disabled }) {
  const { control } = useFormContext();

  return (
    <Controller
      name="username"
      control={control}
      rules={{
        required: true,
        validate: (username) => {
          return (
            validation.email.isAValidEmail(username) ||
            validation.number.isUSPhoneNumber(username) ||
            "Please check that you've entered your email address or phone number correctly"
          );
        },
      }}
      render={({ field }) => (
        <OutlinedInput
          {...field}
          inputRef={field.ref}
          inputComponent={UserNameFieldMaskAdapter}
          autoFocus
          disabled={disabled}
        />
      )}
    />
  );
}
const UserNameFieldMaskAdapter = React.forwardRef((props, ref) => {
  const { onChange, onBlur, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={[
        {
          mask: phoneNumberMask,
          eager: true,
        },
        {
          mask: String,
        },
      ]}
      inputRef={ref}
      onAccept={(value) => onChange?.(removeControlCharacters(value))}
      onBlur={(e) => onBlur(removeControlCharacters(e.target.value))}
    />
  );
});
