import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderCancelRentPayment from "../Stake Pay 3/cancel-rent-payment";

import { useModalContext } from "../../../Contexts/ModalContext";

import { formatCurrency, formatDate, getNumberWithOrdinal } from "../../../Utils/helpers";
import { fullMonthFormat, longMonthDateFormat, modalVariants } from "../../../Utils/constants";

export default function renderRentPaymentDetails(modalContext, props) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;
  const { isAutoPay = false, rentPaymentDetails } = props;

  pushModalStack(() => renderRentPaymentDetails(modalContext, props));
  updateModalComponent(() => <RentPaymentDetails {...props} />);
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("has-transfer-info hide-back-button");
  setModalTitle(
    `${
      isAutoPay ? "Autopay" : formatDate(rentPaymentDetails?.rentPaymentItems?.rentPaymentMonth, fullMonthFormat)
    } Rent Payment`
  );
}

export const RentPaymentDetails = ({ rentPaymentDetails, onEditClick, isAutoPay = false }) => {
  const modalContext = useModalContext();

  return (
    <>
      <div className="modal-dialog-content">
        <div className="transfer-detail">
          <span className="detail-label">Rent Payment</span>
          <span className="detail-value">{formatCurrency(rentPaymentDetails?.paymentAmount)}</span>
        </div>
        <div className="payment-item-list mt-3 mb-4">
          {rentPaymentDetails?.rentPaymentItems?.itemDetails?.map((payment, idx) => {
            return (
              <div key={idx} className="transfer-detail">
                <span className="detail-label">{payment?.subItemName}</span>
                <span className="detail-value">{formatCurrency(payment?.subItemTotal)}</span>
              </div>
            );
          })}
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Preferred Date</span>
          <span className="detail-value">
            {formatDate(rentPaymentDetails?.preferredPaymentDate, longMonthDateFormat)}
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">{isAutoPay ? "Next Payment Date" : "Scheduled Date"}</span>
          <span className="detail-value">
            {formatDate(
              isAutoPay ? rentPaymentDetails?.nextPaymentDate : rentPaymentDetails?.paymentDate,
              longMonthDateFormat
            )}
          </span>
        </div>
        {isAutoPay && (
          <div className="transfer-detail">
            <span className="detail-label">Payment frequency</span>
            <span className="detail-value">
              {getNumberWithOrdinal(parseInt(rentPaymentDetails?.frequency))} of every month
            </span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Payment Method</span>
          <span className="detail-value">
            {rentPaymentDetails?.paymentMethod === "ACH"
              ? rentPaymentDetails?.achDetails?.isStakeChecking === "Y"
                ? "Stake Checking"
                : "Checking Account / ACH"
              : rentPaymentDetails?.paymentMethod === "Card"
              ? "Credit / Debit Card"
              : ""}
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Debit From</span>
          <span className="detail-value">
            {rentPaymentDetails?.paymentMethod === "ACH"
              ? `${
                  rentPaymentDetails?.achDetails?.bankAccountName
                } (...${rentPaymentDetails?.achDetails?.bankAccountNumber?.slice(-4)})`
              : `${rentPaymentDetails?.cardDetails?.cardBrand} (...${rentPaymentDetails?.cardDetails?.cardLast4Digits})`}
          </span>
        </div>
        {rentPaymentDetails?.paymentMethod === "ACH" && (
          <div className="transfer-detail">
            <span className="detail-label">Routing Number</span>
            <span className="detail-value">{rentPaymentDetails?.achDetails?.routingNumber}</span>
          </div>
        )}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={onEditClick}>
          Edit Payment
        </PrimaryButton>
        <PrimaryButton
          className="secondary-colors"
          onClick={renderCancelRentPayment.bind(this, modalContext, {
            isAutoPay,
            paymentMonth: rentPaymentDetails?.rentPaymentItems?.rentPaymentMonth,
            contractPaymentId: rentPaymentDetails?.contractPaymentId,
          })}
        >
          Cancel Payment
        </PrimaryButton>
      </div>
    </>
  );
};
