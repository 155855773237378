import moment from "moment";

import { emailPattern } from "./constants";

export const validation = {
  number: {
    isUSPhoneNumber: (usPhoneNumber) => /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.test(usPhoneNumber),

    isANumber: (number) => /^\d+$/.test(number),
  },

  email: {
    isAValidEmail: (email) => emailPattern.test(email),
    isRandomEmail: (email) => email.startsWith("random-"),
  },

  otp: { isValidOTP: (otp) => /^\d{6}$/.test(otp) },

  SSN: {
    isAValidSSN: (ssn) => /^\d{3} \d{2} \d{4}$/.test(ssn),
  },

  date: {
    isAValidDate: (date) => /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(date) && !isNaN(new Date(date)),
    isAbove18YearsOfAge: (date) => moment().diff(moment(date), "years") >= 18,
  },
};
