import AddFunds from "./add-funds";

import renderAuthorizeAddFunds from "./authorize-add-funds";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

export default function renderGetBankAmountDetails(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName, pushModalStack } = modalContext;

  pushModalStack(() => renderGetBankAmountDetails(modalContext, props));
  updateModalComponent(() => <GetBankAmountDetails {...props} />);
  setModalTitle("Add Funds");
  setModalClassName("rendering-page-content");
  setModalVariant(modalVariants.fullScreenDialog);
}

function GetBankAmountDetails({ onSuccess, ...props }) {
  const modalContext = useModalContext();

  const onFormSubmit = ({ cashAmount, selectedAccount }) =>
    renderAuthorizeAddFunds(modalContext, { cashAmount, selectedAccount, onSuccess });

  return <AddFunds {...props} addFromBank onFormSubmit={onFormSubmit} />;
}
