import { useEffect } from "react";
import { CoralogixRum } from "@coralogix/browser";

import { LocalStorage } from "./helpers";
import { appUtilityKeys, isDevReactEnv } from "./constants";

function initialiseCoralogix() {
  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  const user_context = {
    user_id: user?._id,
    user_email: user?.email,
    user_metadata: { phone_number: user?.phoneNumber },
  };

  CoralogixRum.init({
    public_key: process.env.REACT_APP_CORALOGIX_API_KEY,
    application: "Renter",
    version: "0.1.0",
    coralogixDomain: "EU1",
    traceParentInHeader: {
      enabled: true,
    },
    ...(!!user ? { user_context } : {}),
  });
}

export default function Coralogix() {
  useEffect(() => {
    if (!isDevReactEnv && !CoralogixRum.isInited) {
      // Initialize Coralogix
      initialiseCoralogix();
    }
  }, []);

  return <></>;
}
