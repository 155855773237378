import { useState } from "react";
import { CircularProgress } from "@mui/material";
// import { useNavigate } from "react-router-dom";

import SelectPaymentItems from "./select-payment-items";
import SetupPayment from "./setup-payment";

import withFormProvider from "../../HOC/withFormProvider";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken";

import { ArrowLeftCircle } from "../../Assets/Icons";

const StakePay3 = () => {
  const [formStep, setFormStep] = useState(1);
  const [rentPaymentItem, setRentPaymentItem] = useState(null);

  // const navigate = useNavigate();

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const onNext = (item) => {
    setRentPaymentItem(item);
    setFormStep(2);
  };

  return isLoadingAuthToken ? (
    <div className="loading-content">
      <CircularProgress />
    </div>
  ) : (
    <div className={`stake-pay-3 ${formStep === 1 ? "select" : "pay"}`}>
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            {formStep !== 1 && (
              <div className="section-header">
                <button className="go-back-button" onClick={setFormStep.bind(this, 1)}>
                  <ArrowLeftCircle />
                </button>
                <h3 className="section-title">Set Up Payment</h3>
                <div className="dummy" />
              </div>
            )}
            {formStep === 1 ? (
              <SelectPaymentItems onNext={onNext} />
            ) : (
              <SetupPayment rentPaymentItem={rentPaymentItem} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withFormProvider(StakePay3);
