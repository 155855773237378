import { useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { closeSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

import UserNameField from "../../Components/Form Fields/username-field";
import OTPField from "../../Components/Form Fields/otp-field";
import PrimaryButton from "../../Components/Buttons/primary-button";

import { useLoginWith2FACode, useRequestLogin2FACode } from "../../CustomHooks/Mutations";

import { useRedirectContext } from "../../Contexts/RedirectContext";

import { validation } from "../../Utils/validations";

import {
  dispatchErrorToast,
  dispatchInfoToast,
  dispatchSuccessToast,
  transformUSNumberToCountryCodeFormat,
} from "../../Utils/helpers";
import { debugProps } from "../../Utils/constants";

import { StakeLogo } from "../../Assets/Images";
import { ArrowLeftCircle } from "../../Assets/Icons";

const userNameTypes = { email: "email", phoneNumber: "phoneNumber" };

export default function Login() {
  const resendOtpRef = useRef(false);

  const [renderOtpField, setRenderOtpField] = useState(false);

  const formProps = useForm();

  const { onLogin } = useRedirectContext();

  const {
    handleSubmit,
    getValues,
    watch,
    formState: { isValid },
    resetField,
  } = formProps;

  const userName = watch("username");

  const userNameType = useMemo(() => {
    return validation.email.isAValidEmail(userName)
      ? userNameTypes.email
      : validation.number.isUSPhoneNumber(userName)
      ? userNameTypes.phoneNumber
      : "";
  }, [userName]);

  const { isLoading: isRequestingLogin2FACode, mutate: RequestLogin2FACode } = useRequestLogin2FACode({
    onSuccess: ({ data }) => {
      setRenderOtpField(true);
      if (resendOtpRef.current) {
        resendOtpRef.current = false;
        dispatchSuccessToast(`New OTP code was sent at ${userName}`, { key: "request2FA" });
      } else {
        resetField("otp");
        dispatchInfoToast(data?.data?.msg, { key: "request2FA" });
      }
    },
    onError: () => {
      dispatchErrorToast("Oops! Something went wrong!", { key: "request2FA" });
      if (resendOtpRef.current) {
        resendOtpRef.current = false;
      }
    },
  });

  const { isLoading: isLoggingInWith2FACode, mutate: LoginWith2FACode } = useLoginWith2FACode({
    onSuccess: (data) => {
      dispatchSuccessToast("Verification success");
      onLogin(data);
    },
    onError: (err) =>
      dispatchErrorToast(err?.response?.data?.error?.msg, {
        key: "loginWith2FA",
      }),
  });

  const isLoading = isRequestingLogin2FACode || isLoggingInWith2FACode;

  const onSubmit = () => {
    closeSnackbar();

    let payload = { ...debugProps };

    if (userNameType === userNameTypes.phoneNumber) {
      payload[userNameType] = transformUSNumberToCountryCodeFormat(userName);
    } else {
      payload[userNameType] = userName;
    }

    if (!renderOtpField || resendOtpRef.current) {
      !isLoading && RequestLogin2FACode(payload);
    } else {
      payload.phoneNumberCode = getValues("otp");
      !isLoading && LoginWith2FACode(payload);
    }
  };

  const onFormError = (errors) => {
    if (!renderOtpField) {
      dispatchErrorToast(errors.username.message, { key: "username" });
    } else {
      dispatchErrorToast(errors.otp.message, { key: "otp" });
    }
  };

  const disableSubmitButton = !renderOtpField ? !(watch("username") ?? "") : !isValid;

  return (
    <div className="onboarding-page login">
      <header>
        <StakeLogo className="logo" />
      </header>
      <FormProvider {...formProps}>
        <div className="max-width-container">
          <form className="form-card" onSubmit={handleSubmit(onSubmit, onFormError)} autoComplete="off">
            <div className="card-header">
              {renderOtpField && (
                <button className="mb-2" onClick={setRenderOtpField.bind(this, false)} type="button">
                  <ArrowLeftCircle />
                </button>
              )}
              <h1 className="text-4xl-semibold color--text-primary mt-1 mb-0">
                {!renderOtpField
                  ? "Welcome to Stake"
                  : `Verify ${
                      userNameType === userNameTypes.email
                        ? "Email"
                        : userNameType === userNameTypes.phoneNumber
                        ? "Phone"
                        : ""
                    }`}
              </h1>
              {renderOtpField && (
                <p className="text-lg-regular color--text-primary mt-2 mb-0">
                  Please enter the code sent to <span className="username-badge">{userName}</span>
                </p>
              )}
            </div>

            <div className={`${!renderOtpField ? "username" : "otp"}-field`}>
              {!renderOtpField ? (
                <>
                  <label htmlFor="username">Enter phone or email</label>
                  <UserNameField disabled={isLoading} />
                </>
              ) : (
                <>
                  <OTPField />
                  <button
                    className="resend-otp-button mt-3"
                    onClick={() => {
                      resendOtpRef.current = true;
                      onSubmit();
                    }}
                    type="button"
                  >
                    Didn’t receive a{" "}
                    {userNameType === userNameTypes.email
                      ? "email"
                      : userNameType === userNameTypes.phoneNumber
                      ? "text"
                      : ""}
                    ? Resend code
                  </button>
                </>
              )}
            </div>
            <PrimaryButton className="w-100" type="submit" disabled={disableSubmitButton || isLoading}>
              {!renderOtpField ? "Sign In" : "Next"}
              {isLoading && <CircularProgress />}
            </PrimaryButton>
          </form>
        </div>
      </FormProvider>
      <div className="dummy-footer"></div>
    </div>
  );
}
