import { convertObjectToFormData, LocalStorage } from "./helpers";
import { appUtilityKeys } from "./constants";

export const getFetchAstraTokenPayload = (code = null) => {
  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
  const refreshToken = LocalStorage.read(appUtilityKeys.astraRefreshToken);

  const payload = {
    code,
    redirect_uri: process.env.REACT_APP_ASTRA_REDIRECT_URL,
    grant_type: !!code ? "authorization_code" : "refresh_token",
    refresh_token: !!code ? null : refreshToken,
    user_id: user?._id ?? "",
  };

  return convertObjectToFormData(payload);
};

export const getTransferRoutinePayload = (attributes) => {
  const { sourceCard, destinationCard, amount } = attributes;

  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  const payload = {
    destination_id: destinationCard?.id ?? "",
    destination_user_id: destinationCard?.user_id ?? "",
    amount: amount,
    user_id: user._id ?? "",
  };

  if (!!sourceCard) {
    payload.source_id = sourceCard?.id ?? "";
  }

  return convertObjectToFormData(payload);
};

export const getSetupRentPaymentPayload = ({
  customerPaymentId,
  paymentDate,
  isAutopay,
  paymentMethod,
  rentPaymentItem,
  selectedAccount,
  selectedFinixCard,
  deals,
  stakeDebit,
  userAccount,
}) => {
  const pastDueBalance =
    rentPaymentItem?.itemDetails?.find((item) => item?.subItemName === "Past Due Balance")?.subItemTotal ?? 0;
  const adjustments =
    rentPaymentItem?.itemDetails?.find((item) => item?.subItemName === "Adjustments")?.subItemTotal ?? 0;

  return {
    externalCustomerReferenceId: deals?.data?.data?.currentDeal?.sfid,
    customerPaymentId,
    inputDate: paymentDate,
    selectedPaymentMethod: {
      ...(paymentMethod === "card"
        ? {
            selectedCardDetails: {
              cardId: selectedFinixCard?.cardId,
              cardName: selectedFinixCard?.cardNickName,
              cardLast4Digits: selectedFinixCard?.cardLast4Digits,
            },
          }
        : {
            selectedAchDetails: {
              bankAccountid: 0,
              bankAccountHolderName: `${userAccount?.data?.data?.user?.firstName} ${userAccount?.data?.data?.user?.lastName}`,
              bankAccountNickName: paymentMethod === "stake-checking" ? "Stake Checking" : selectedAccount?.name,
              accountType: !!selectedAccount && selectedAccount?.subtype !== "checking" ? "S" : "C",
              bankAccountNumber:
                paymentMethod === "stake-checking" ? stakeDebit?.data?.data?.account_number : selectedAccount?.mask,
              routingNumber:
                paymentMethod === "stake-checking" ? stakeDebit?.data?.data.routing_number : selectedAccount?.routing,
              accountClassification: "P",
              isDefaultBankAccountSetting: true,
              isStakeChecking: paymentMethod === "stake-checking" ? "Y" : "N",
              ...(paymentMethod === "bank-account" ? { plaidAccountId: selectedAccount?.account_id } : {}),
            },
          }),
    },
    paymentAmount: rentPaymentItem?.itemTotal,
    isAutoPaySelected: isAutopay,
    pastDueBalance,
    adjustments,
    paymentOption: rentPaymentItem?.paymentOption,
  };
};
