import { RecurringDeposits } from "../../../Pages/Recurring Deposits";

import { getRecurringDepositDefaultPreferences } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

export default function renderGetRecurringDepositPreferences(modalContext, props) {
  const { updateModalComponent, updateModalFormDefaultValues, setModalTitle, setModalClassName, setModalVariant } =
    modalContext;

  updateModalComponent(() => <RecurringDeposits {...props} />);
  updateModalFormDefaultValues(getRecurringDepositDefaultPreferences());
  setModalTitle("Recurring Deposit");
  setModalClassName("rendering-page-content");
  setModalVariant(modalVariants.fullScreenDialog);
}
