import { useMemo } from "react";

import PrimaryButton from "../../Components/Buttons/primary-button";

import renderSetupStakePay2 from "../../Organisms/Modals/Stake Pay 2/setup-stake-pay-2";

import { useModalContext } from "../../Contexts/ModalContext";

import { useActivities, useConnectedAccounts, useDeals, useStakeDebit } from "../../CustomHooks/Queries";

import { formatCurrency, isSVDApproved, isSVDDenied, isSVDIneligible } from "../../Utils/helpers";

import { PersonWithKeyIllustration } from "../../Assets/Images";

export default function PayRentWithStakePay2() {
  const modalContext = useModalContext();
  const { isLoading: isLoadingDeals, data: deals } = useDeals();
  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();
  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();
  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { rentAmount, cashBackAmount } = useMemo(() => {
    const currentDeal = deals?.data?.data?.currentDeal || {};
    const rentAmount = currentDeal?.rent_amount || 0;
    // Calculate rent match
    const cashBackPercent = currentDeal.cash_back_percent || 0;
    const cashBackAmount = rentAmount * (cashBackPercent / 100);
    return { rentAmount, cashBackAmount };
  }, [deals]);

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const currentBalance = useMemo(() => {
    return activitiesData?.data?.data?.currentBalance || 0;
  }, [activitiesData]);

  const isCurrentBalanceEnough = useMemo(() => {
    return currentBalance >= rentAmount;
  }, [currentBalance, rentAmount]);

  const isBankConnected = useMemo(() => {
    return (connectedAccounts?.data?.data?.accounts?.length ?? 0) > 0;
  }, [connectedAccounts?.data?.data?.accounts?.length]);

  const IsSVDDenied = useMemo(() => {
    return isSVDDenied(stakeDebit?.data);
  }, [stakeDebit]);

  const IsSVDIneligible = useMemo(() => {
    return isSVDIneligible(stakeDebit?.data);
  }, [stakeDebit]);

  const isLoading = isLoadingDeals || isLoadingActivities || isLoadingStakeDebit || isLoadingConnectedAccounts;

  return isLoading || IsSVDDenied || IsSVDIneligible ? (
    <></>
  ) : (
    <div className="pay-rent-with-stake mt-6">
      <img className="person-with-key-illustration" src={PersonWithKeyIllustration} alt="" />
      <div className="content">
        <h3 className="title mb-2">Pay rent with Stake, earn {formatCurrency(cashBackAmount)} extra Cash Back</h3>
        <span className="description mb-6">
          Earn an extra {formatCurrency(cashBackAmount)} Cash Back instantly. Stake has no fees, and refunds all fees
          for on-time payments.
        </span>
        <h5 className="steps-title mb-3">3 easy steps to start</h5>
        <div className="steps">
          <div className="step">
            {IsSVDApproved ? <span className="symbol">✅</span> : <span className="symbol">1️⃣</span>}
            Open a Stake Checking Account
          </div>
          <div className="step">
            {IsSVDApproved && isBankConnected && isCurrentBalanceEnough ? (
              <span className="symbol">✅</span>
            ) : (
              <span className="symbol">2️⃣</span>
            )}
            Deposit rent amount
          </div>
          <div className="step">
            <span className="symbol">3️⃣</span> Update payment portal
          </div>
        </div>
        <PrimaryButton className="blue-theme mt-6" onClick={() => renderSetupStakePay2(modalContext)}>
          {IsSVDApproved || isCurrentBalanceEnough ? "Continue" : "Start"}
        </PrimaryButton>
      </div>
    </div>
  );
}
