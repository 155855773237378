import { useRoutes } from "react-router-dom";

import Login from "./Pages/Login";
import LoginWithUniqueContactID from "./Pages/Login/login-with-unique-contact-ID";
import Welcome from "./Pages/Welcome";
import Unclaimed from "./Pages/Unclaimed";
import Banking from "./Pages/Banking";
import BankStatements from "./Pages/Bank Statements";
import Renting from "./Pages/Renting";
import Profile from "./Pages/Profile";
import PersonalInformation from "./Pages/Personal Information";
import ConnectedAccountsPage from "./Pages/Connected Accounts";
// import DebitCardsPage from "./Pages/Debit Cards";
import AddCash from "./Pages/Cash Transaction Pages/add-cash";
import CashOut from "./Pages/Cash Transaction Pages/cash-out";
import BankingLimits from "./Pages/Banking Limits";
import DirectDeposit from "./Pages/Direct Deposit";
import RecurringDepositsPage from "./Pages/Recurring Deposits";
import ManageCards from "./Pages/Manage Cards";
import StakePay3 from "./Pages/StakePay 3";
import ManagePayments from "./Pages/StakePay 3/manage-payments";
import EditPayment from "./Pages/StakePay 3/edit-payment";
import SetupAutopay from "./Pages/StakePay 3/setup-autopay";
// import RentPayments from "./Pages/StakePay 3/rent-payments";
import ErrorPage from "./Components/Others/error-page";

import AppComponentWrapper from "./Organisms/Wrappers/app-component-wrapper";

import { RedirectContextProvider } from "./Contexts/RedirectContext";

function Routes() {
  let routes = useRoutes([
    {
      path: "/",
      element: <RedirectContextProvider />,
      children: [
        { path: "login", element: <Login /> },
        { path: "welcome", element: <Welcome /> },
        { path: "unclaimed", element: <Unclaimed /> },
        {
          path: "/",
          element: <AppComponentWrapper />,
          children: [
            {
              path: "banking",
              children: [
                { path: "", element: <Banking /> },
                { path: "statement", element: <BankStatements /> },
                { path: "connected-accounts", element: <ConnectedAccountsPage /> },
                // { path: "debit-cards", element: <DebitCardsPage /> },
                { path: "add-cash", element: <AddCash /> },
                { path: "cash-out", element: <CashOut /> },
                { path: "banking-limits", element: <BankingLimits /> },
                { path: "direct-deposit", element: <DirectDeposit /> },
                { path: "recurring-deposits", element: <RecurringDepositsPage /> },
                { path: "manage-cards", element: <ManageCards /> },
              ],
            },
            {
              path: "renting",
              children: [
                { path: "", element: <Renting /> },
                {
                  path: "stakepay-3",
                  children: [
                    {
                      path: "",
                      element: <StakePay3 />,
                    },
                    { path: "manage", element: <ManagePayments /> },
                    { path: "edit/:paymentId", element: <EditPayment /> },
                    { path: "setup-autopay", element: <SetupAutopay /> },
                    // { path: "rent-payments", element: <RentPayments /> },
                  ],
                },
              ],
            },
            {
              path: "profile",
              children: [
                { path: "", element: <Profile /> },
                { path: "personal-information", element: <PersonalInformation /> },
              ],
            },
          ],
        },
      ],
    },
    { path: "login-with-unique-id", element: <LoginWithUniqueContactID /> },
    { path: "*", element: <ErrorPage isPageNotFound /> },
  ]);
  return routes;
}

export default Routes;
