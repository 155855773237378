import React, { useMemo } from "react";

import RentDetails from "./rent-details";
import PayRentWithStakePay2 from "./pay-rent-with-stakepay-2";
import PayRentWithStakePay3 from "./pay-rent-with-stakepay-3.js";
import MyReturnOnRent from "./my-return-on-rent";
import CreditBuilder from "./credit-builder";
import { RentTrackerItem } from "../../Components/Others/rent-tracker-item";
import RentCalendar from "./rent-calendar";

import CreditBuilderSkeleton from "../../Components/Skeleton/credit-builder-skeleton";
import RentDetailsSkeleton from "../../Components/Skeleton/rent-details-skeleton";
import RentTrackerSkeleton from "../../Components/Skeleton/rent-tracker-skeleton";

import renderRentTracker from "../../Organisms/Modals/Renting/Rent Tracker/rent-tracker";

import { useModalContext } from "../../Contexts/ModalContext";
import { useUserAccount, usePaymentHistories, useDeals, useCreditReporting } from "../../CustomHooks/Queries";

import { sortedAndGroupedPayments } from "../../Utils/api-data-transforms";

import "./renting.scss";
import "./renting-responsive.scss";
import "./renting-skeleton.scss";

export default function Renting() {
  const { isLoading: isLoadingDeals, data: deals } = useDeals();
  const { isLoading: isLoadingUserAccount, data: userAccount } = useUserAccount();
  const { isLoading: isLoadingPaymentHistory, data: paymentHistory } = usePaymentHistories();
  const { isLoading: isLoadingCreditReporting, data: creditReporting } = useCreditReporting();

  const modalContext = useModalContext();

  const isCreditReportingEligible = useMemo(() => {
    return creditReporting?.data?.data?.is_eligible;
  }, [creditReporting?.data?.data?.is_eligible]);

  const sortedPaymentsList = useMemo(() => sortedAndGroupedPayments(paymentHistory?.data?.data), [paymentHistory]);

  const address = useMemo(() => {
    const user = userAccount?.data?.data?.user;
    if (!user || !user?.address1) return "";

    if (!!user?.address2) {
      return `${user.address1} ${user.address2}`;
    } else {
      return user.address1;
    }
  }, [userAccount]);

  const { rentAmount, rentMatch } = useMemo(() => {
    const currentDeal = deals?.data?.data?.currentDeal || {};
    const rentAmount = currentDeal?.rent_amount || 0;
    const rewardPercentage = currentDeal?.reward_percent || 0;
    // Calculate rent match
    const rentMatch = rentAmount * (rewardPercentage / 100);
    return { rentAmount, rentMatch };
  }, [deals]);

  const isNotStakeOneUser = useMemo(() => {
    return !deals?.data?.data?.currentDeal?.stake_one;
  }, [deals]);

  const isMember = useMemo(() => {
    return deals?.data?.data?.currentDeal?.is_member__c;
  }, [deals]);

  const isLoading = isLoadingDeals || isLoadingUserAccount || isLoadingPaymentHistory || isLoadingCreditReporting;

  return (
    <div className={`renting ${isLoading ? "skeleton" : ""}`}>
      <section>
        <div className="max-width-container">
          {isLoadingUserAccount ? <RentDetailsSkeleton /> : <RentDetails address={address} price={rentAmount} />}

          {!deals?.data?.data?.currentDeal?.is_stakepay_enabled ? (
            <PayRentWithStakePay2 rentAmount={rentAmount} />
          ) : (
            <PayRentWithStakePay3 rentAmount={rentAmount} />
          )}

          {isMember && isNotStakeOneUser && (
            <>
              <div className="section-header mt-6">
                <h3 className="section-title">My Return on Rent</h3>
              </div>
              <MyReturnOnRent rentMatch={rentMatch} />
            </>
          )}
          {isLoadingCreditReporting ? (
            <CreditBuilderSkeleton />
          ) : isCreditReportingEligible ? (
            <CreditBuilder optedIn={creditReporting?.data?.data?.opted_in} />
          ) : (
            <></>
          )}
          <div className="section-header mt-6">
            <h3 className="section-title">Rent Tracker</h3>
            {sortedPaymentsList.length > 6 && (
              <button className="see-all-button" onClick={renderRentTracker.bind(this, modalContext)}>
                See All
              </button>
            )}
          </div>
          {isLoadingPaymentHistory || isLoadingCreditReporting ? (
            <RentTrackerSkeleton />
          ) : sortedPaymentsList.length === 0 ? (
            <div className="rent-tracker-item no-records">No Rent Payments</div>
          ) : (
            <RentTrackerItem
              paymentList={sortedPaymentsList?.slice(0, 6)}
              showReporting={isCreditReportingEligible && creditReporting?.data?.data?.opted_in}
            />
          )}
          {isNotStakeOneUser && (
            <>
              <div className="section-header mt-6">
                <h3 className="section-title">Rent Calendar</h3>
              </div>
              <RentCalendar rentMatch={rentMatch} />
            </>
          )}
        </div>
      </section>
    </div>
  );
}
