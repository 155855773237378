import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useStakeDebit } from "../../../CustomHooks/Queries";

import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderStakeCheckingPending(modalContext) {
  const {
    updateModalComponent,
    pushModalStack,
    setModalVariant,
    setModalPromoComponent,
    setModalTitle,
    setModalClassName,
  } = modalContext;

  updateModalComponent(StakeCheckingPending);
  pushModalStack(() => renderStakeCheckingPending(modalContext));
  setModalPromoComponent(null);
  setModalTitle("");
  setModalClassName("position-close-button-over-content");
  setModalVariant(modalVariants.flexDialog);
}

const StakeCheckingPending = () => {
  const { modalStack, popModalStack } = useModalContext();

  const { data: stakeDebit } = useStakeDebit();

  return (
    <>
      <div className="modal-image svd-single-card" src={SVD_Single_Card} alt="">
        <img src={SVD_Single_Card} alt="" />
      </div>
      <div className="modal-dialog-content">
        <h3 className="modal-status-title mt-6 mb-5">Your virtual Stake Visa Debit application is pending</h3>
        <ul className="benefits-list">
          <li className="alert-list-style">Your Stake Debit application is under review.</li>
        </ul>
      </div>
      <div className="action-container">
        <PrimaryButton
          className="blue-theme"
          onClick={() => window.open(stakeDebit?.data?.data?.application_link ?? "", "_blank")}
        >
          Check Status
        </PrimaryButton>
        {modalStack.length > 1 && (
          <PrimaryButton className="secondary-colors" onClick={() => popModalStack(1)}>
            Back
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
