import { useEffect } from "react";

import { useDeals, useStakePay3Auth } from "./Queries";

import { appUtilityKeys } from "../Utils/constants";
import { LocalStorage } from "../Utils/helpers";

export default function useStakePay3AuthToken(enabled = true) {
  const { isLoading: isLoadingDeals, data: deals } = useDeals({ enabled });

  const allowStakePay3AuthFetch =
    enabled &&
    !isLoadingDeals &&
    !!deals?.data?.data?.currentDeal?.sfid &&
    deals?.data?.data?.currentDeal?.is_stakepay_enabled === true;

  const stakePay3AuthData = useStakePay3Auth(deals?.data?.data?.currentDeal?.sfid, {
    enabled: allowStakePay3AuthFetch,
    staleTime: 30 * 60 * 1000,
    refetchOnWindowFocus: true,
    onSuccess: ({ data }) => {
      LocalStorage.write(appUtilityKeys.stakePay3AuthToken, data?.data?.Token);
    },
  });

  const { isLoading: isLoadingAuthToken, isStale: isStaleAuthToken, refetch: refetchAuthToken } = stakePay3AuthData;

  useEffect(() => {
    if (allowStakePay3AuthFetch && isStaleAuthToken) {
      refetchAuthToken();
    } //eslint-disable-next-line
  }, [allowStakePay3AuthFetch, isStaleAuthToken]);

  return { isLoadingAuthToken, stakePay3AuthData };
}
