import { useQueryClient } from "react-query";
import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useGetRecurringDeposit } from "../../../CustomHooks/Queries";
import { useResetConnectedAccountSelection } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

export function renderDeleteConnectedAccount(modalContext, account) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <DeleteConnectedAccount account={account} />);
  pushModalStack(() => renderDeleteConnectedAccount(modalContext));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
  setModalClassName("");
}

const DeleteConnectedAccount = ({ account }) => {
  const queryClient = useQueryClient();

  const { popModalStack } = useModalContext();

  const { isLoading: isResettingConnectedAccount, mutate: ResetConnectedAccount } = useResetConnectedAccountSelection({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.connectedAccounts);
      queryClient.refetchQueries(APIQueryKeys.connectedAccountsSelection);
      popModalStack(1);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const { isLoading: isLoadingRecurringDeposit, data: recurringDeposit } = useGetRecurringDeposit();

  return isLoadingRecurringDeposit ? (
    <ModalSkeleton className="mt-sm-6 mt-4" />
  ) : (
    <AlertConfirmationModal
      {...(recurringDeposit?.data?.data?.active &&
      account.account_id === recurringDeposit?.data?.data?.originating_account_id
        ? {
            variant: "alert-neutral",
            title: "Unable to remove bank account",
            description:
              "There is an active recurring deposit or scheduled payment linked to this account. Cancel them before removing the account.",
            CTAButtonText: "Got It",
            onCTAButtonClick: () => popModalStack(1),
            hideSecondaryCTAButton: true,
          }
        : {
            title: "Remove Account",
            description: `Are you sure you want to remove ${account?.name} (...${account?.mask})?`,
            CTAButtonText: "Remove",
            secondaryCTAButtonText: "Cancel",
            onCTAButtonClick: () => ResetConnectedAccount(),
            onSecondaryCTAButtonClick: () => popModalStack(1),
            isCTALoading: isResettingConnectedAccount,
          })}
    />
  );
};
