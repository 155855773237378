import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import SetupPayment from "./setup-payment";

import withFormProvider from "../../HOC/withFormProvider";

import useStakePay3AuthToken from "../../CustomHooks/useStakePay3AuthToken";

import { useAutopayPaymentSetupSummary, useDeals } from "../../CustomHooks/Queries";

import { ArrowLeftCircle } from "../../Assets/Icons";

const SetupAutoPay = () => {
  const [rentPaymentItem, setRentPaymentItem] = useState(null);

  const navigate = useNavigate();

  const { isLoadingAuthToken } = useStakePay3AuthToken();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const sfid = deals?.data?.data?.currentDeal?.sfid;

  const NavigateToManageRenting = () => navigate("/renting/stakepay-3/manage");

  const { isLoading: isLoadingAutopayPaymentSetupSummary } = useAutopayPaymentSetupSummary(sfid, {
    enabled: !isLoadingAuthToken && !!sfid,
    onSuccess: ({ data }) => {
      if (!!data?.data?.paymentDetails && data?.data?.paymentDetails?.isAutopayEligible) {
        setRentPaymentItem(data?.data?.paymentDetails);
      } else {
        NavigateToManageRenting();
      }
    },
    onError: NavigateToManageRenting,
  });

  const isLoading = isLoadingDeals || isLoadingAuthToken || isLoadingAutopayPaymentSetupSummary;

  return (
    <div className="stake-pay-3 pay">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={navigate.bind(this, -1)}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Set Up AutoPay</h3>
              <div className="dummy" />
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <SetupPayment rentPaymentItem={rentPaymentItem} shouldConfirmAutoPay />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withFormProvider(SetupAutoPay);
