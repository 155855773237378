import { useEffect } from "react";
import branch from "branch-sdk";

import { LocalStorage } from "./helpers";
import { appUtilityKeys } from "./constants";

function initialiseBranchIO() {
  branch.init(process.env.REACT_APP_BRANCH_IO_KEY, function (_, data) {
    const deepLinkPath = data.data_parsed.$deeplink_path.trim();

    if (!!deepLinkPath) {
      LocalStorage.write(appUtilityKeys.deeplink, deepLinkPath);
    }
  });
}

export default function BranchIOLib() {
  useEffect(initialiseBranchIO, []);

  return <></>;
}
