import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import ErrorPage from "../Components/Others/error-page";

import { useAppUtilityContext } from "./AppUtilityContext";

import usePrefetchOnLogin from "../CustomHooks/usePrefetchOnLogin";

import { getHasVisitedWelcomeScreenKey, isOnboardingModule, LocalStorage } from "../Utils/helpers";
import { APIQueryKeys } from "../Utils/constants";

const RedirectContext = createContext();

export const RedirectContextProvider = () => {
  const [hasVisitedWelcomeScreen, setHasVisitedWelcomeScreen] = useState(
    LocalStorage.read(getHasVisitedWelcomeScreenKey())
  );

  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { onUserCredentialsUpdate, appUtilityProps } = useAppUtilityContext();

  const {
    isLoading: isPrefetchingData,
    isSuccess: isDataPrefetchSuccess,
    isError: isDataPrefetchError,
    hasUnclaimedTransactionsData,
    errorStatus,
  } = usePrefetchOnLogin({ authKey: appUtilityProps.authKey, hasVisitedWelcomeScreen });

  const onLogin = (data) => {
    onUserCredentialsUpdate(data);
    queryClient.resetQueries();
    setHasVisitedWelcomeScreen(LocalStorage.read(getHasVisitedWelcomeScreenKey()));
  };

  const markVisitedWelcomePage = () => {
    LocalStorage.write(getHasVisitedWelcomeScreenKey(), true);
    setHasVisitedWelcomeScreen(true);
  };

  useEffect(() => {
    if (!!appUtilityProps.authKey) {
      queryClient.refetchQueries(APIQueryKeys.deals);
      queryClient.refetchQueries(APIQueryKeys.userAccount);
      queryClient.refetchQueries(APIQueryKeys.transferToken);
      if (!hasVisitedWelcomeScreen) {
        queryClient.refetchQueries(APIQueryKeys.mobileAppSummary);
        queryClient.refetchQueries(APIQueryKeys.platformSummaryStats);
      }
    }
  }, [queryClient, appUtilityProps.authKey, hasVisitedWelcomeScreen]);

  useEffect(() => {
    if (isOnboardingModule(location.pathname) && !isPrefetchingData && isDataPrefetchSuccess) {
      if (!hasVisitedWelcomeScreen) {
        navigate("/welcome");
      } else if (hasUnclaimedTransactionsData) {
        navigate("/unclaimed");
      } else {
        navigate("/banking");
      }
    }
  }, [
    location.pathname,
    isPrefetchingData,
    isDataPrefetchSuccess,
    hasVisitedWelcomeScreen,
    hasUnclaimedTransactionsData,
    navigate,
  ]);

  if (!appUtilityProps.authKey && location.pathname !== "/login") {
    return <Navigate to="/login" />;
  }

  const moduleType = isOnboardingModule(location.pathname) ? "onboarding" : "authenticated";

  const moduleTextColor = isOnboardingModule(location.pathname) ? "text-primary" : "charcoal";

  return (
    <RedirectContext.Provider value={{ onLogin, markVisitedWelcomePage }}>
      {isPrefetchingData ? (
        <div className={`${moduleType}-page justify-content-center`}>
          <CircularProgress sx={{ color: `var(--${moduleTextColor})` }} />
        </div>
      ) : isDataPrefetchError ? (
        <ErrorPage errorStatus={errorStatus} />
      ) : (
        <Outlet />
      )}
    </RedirectContext.Provider>
  );
};

export const useRedirectContext = () => {
  const modalContextData = useContext(RedirectContext);
  if (modalContextData === undefined) {
    throw new Error("useRedirectContext must be used within a RedirectContextProvider");
  }
  return modalContextData;
};
