import { AuthorizeTransfer, setAuthorizeTransferModalProps } from "../Transfer/authorize-transfer";

import renderStatusModal from "../status-modal";
import renderInstantTransferLimitReached from "../Cards/instant-transfer-limit-reached";

import { useModalContext } from "../../../Contexts/ModalContext";

import useAstra from "../../../CustomHooks/useAstra";

import { useDeals, useStakeDebit } from "../../../CustomHooks/Queries";
import { useCreateTransferRoutine, useInitiateACHDebit } from "../../../CustomHooks/Mutations";

import { getTransferRoutinePayload } from "../../../Utils/api-payload-generators";
import { getActiveSVDAstraDebitCard } from "../../../Utils/helpers";
import { FailedTransferStatusModalProps, SuccessfulTransferStatusModalProps } from "../../../Utils/constants";

export default function renderAuthorizeAddFunds(modalContext, props) {
  const { updateModalComponent, pushModalStack } = modalContext;

  pushModalStack(() => renderAuthorizeAddFunds(modalContext, props));
  updateModalComponent(() => <AuthorizeAddFunds {...props} />);
  setAuthorizeTransferModalProps(modalContext);
}

const AuthorizeAddFunds = ({ cashAmount, selectedAstraCard, selectedAccount, onSuccess }) => {
  const modalContext = useModalContext();

  const { popModalStack } = modalContext;

  const { data: deals } = useDeals();

  const { data: stakeDebit } = useStakeDebit();

  const { astraDebitCards } = useAstra();

  const onAddCashSuccess = (description) =>
    renderStatusModal(modalContext, {
      ...SuccessfulTransferStatusModalProps,
      description,
      onCTAButtonClick: onSuccess,
    });

  const onAddCashError = (errorProps = {}) =>
    renderStatusModal(modalContext, {
      ...FailedTransferStatusModalProps,
      ...errorProps,
      CTAButtonText: "Retry",
      onCTAButtonClick: () => popModalStack(1),
    });

  const { mutate: InitiateACHDebit } = useInitiateACHDebit({
    onSuccess: () =>
      onAddCashSuccess(`Your transfer is expected to arrive on ${deals?.data?.content?.transaction_clearing_date}.`),
    onError: (err) => onAddCashError(err?.response?.status === 422 ? { description: err?.response?.data?.error } : {}),
  });

  const { mutate: CreateTransferRoutine } = useCreateTransferRoutine({
    onSuccess: () => onAddCashSuccess("Your transfer is expected to arrive in a few minutes."),
    onError: (err) => {
      if (err?.response?.status === 429) renderInstantTransferLimitReached(modalContext);
      else onAddCashError();
    },
  });

  return (
    <AuthorizeTransfer
      stake_account_number_last4={stakeDebit?.data?.data?.account_number?.slice(-4)}
      stake_routing_number={stakeDebit?.data?.data?.routing_number}
      {...(!!selectedAstraCard ? { selectedAstraCard } : { selectedAccount })}
      clearingTransactionDate={deals?.data?.content?.transaction_clearing_date}
      amount={cashAmount}
      AuthorizeCallback={() => {
        if (!!selectedAstraCard) {
          const svdAstraCard = getActiveSVDAstraDebitCard(astraDebitCards, stakeDebit);
          CreateTransferRoutine(
            getTransferRoutinePayload({
              sourceCard: svdAstraCard,
              destinationCard: selectedAstraCard,
              amount: cashAmount,
            })
          );
        } else {
          InitiateACHDebit({
            accountId: selectedAccount.account_id,
            amount: cashAmount,
          });
        }
      }}
    />
  );
};
