import { useCallback, useEffect, useRef, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useQueryClient } from "react-query";

import { useCreateLinkToken, useSaveDefaultConnectedAccount, useSetAccessToken } from "./Mutations";

import { dispatchAPIErrorToast, dispatchErrorToast } from "../Utils/helpers";
import { APIQueryKeys } from "../Utils/constants";

export default function useConnectBankAccount({ onSuccess, onError, onExit, onInitiatingSave } = {}) {
  const hasAttemptedTokenCreation = useRef(false);
  const selectedAccount = useRef(null);

  const [linkToken, setLinkToken] = useState(null);

  const queryClient = useQueryClient();

  const { isLoading: isSavedDefaultConnectedAccount, mutate: SavedDefaultConnectedAccount } =
    useSaveDefaultConnectedAccount({
      onSuccess: (data) => {
        queryClient.refetchQueries(APIQueryKeys.connectedAccounts);
        queryClient.refetchQueries(APIQueryKeys.connectedAccountsSelection);
        onSuccess?.(data);
      },
      onError: !!onError ? onError : () => dispatchAPIErrorToast(),
    });

  const { isLoading: isAddedLinkToken, mutate: AddedLinkToken } = useSetAccessToken({
    onSuccess: (data) => {
      SavedDefaultConnectedAccount({ account_id: selectedAccount.current });
      selectedAccount.current = null;
    },
    onError: !!onError ? onError : () => dispatchAPIErrorToast(),
  });

  const { mutate: createLinkToken } = useCreateLinkToken({
    onSuccess: ({ data }) => {
      setLinkToken(data?.data?.link_token);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  useEffect(() => {
    if (!linkToken && !hasAttemptedTokenCreation.current) {
      hasAttemptedTokenCreation.current = true;
      createLinkToken();
    }
  }, [linkToken, createLinkToken]);

  const onPlaidSuccess = useCallback(
    (public_token, metadata) => {
      let chimeSavingAccountId = null;
      let chimeCheckingAccountId = null;

      const institutionId = metadata.institution?.id || "";

      metadata.accounts.forEach((account) => {
        const accountId = account.id;

        try {
          // ins_35 is provided on Plaid Docs to be Chime institution id
          if (institutionId === "ins_35" && account.subtype?.account_type === "depository") {
            switch (account.subtype?.type) {
              case "savings":
                if (!chimeSavingAccountId) {
                  chimeSavingAccountId = accountId;
                }
                break;
              default:
                if (!chimeCheckingAccountId) {
                  chimeCheckingAccountId = accountId;
                }
                break;
            }
          }
        } catch (e) {
          console.error("Error validating if result is a Chime account:", e);
        }
      });

      // If we only have a Chime Savings account, then reject the action
      if (chimeSavingAccountId && !chimeCheckingAccountId) {
        // Replace this with your error handling mechanism
        dispatchErrorToast(
          "We're sorry, but we can't connect to Chime Savings accounts at this time. Please connect your Chime Checking account instead."
        );
      }
      // If we have a Chime Savings and Checking account, then default it to Checking account
      else if (chimeSavingAccountId && chimeCheckingAccountId) {
        selectedAccount.current = chimeCheckingAccountId;
        onInitiatingSave?.();
        AddedLinkToken({ publicToken: public_token });
      }
      // Else, continue with normal selection
      else {
        const accountId = metadata.accounts.length > 0 ? metadata.accounts[metadata.accounts.length - 1].id : null;
        selectedAccount.current = accountId;
        onInitiatingSave?.();
        AddedLinkToken({ publicToken: public_token });
      }
    },
    [AddedLinkToken, onInitiatingSave]
  );

  const config = {
    token: linkToken,
    onSuccess: onPlaidSuccess,
    onExit,
  };

  const { open, ready, error, exit } = usePlaidLink(config);

  useEffect(() => {
    if (!!error) {
      !!onError ? onError() : dispatchAPIErrorToast();
      exit();
    }
  }, [error, onError, exit]);

  const openPlaid = useCallback(() => ready && open(), [ready, open]);

  return {
    isSavingAccount: isAddedLinkToken || isSavedDefaultConnectedAccount,
    plaidReady: ready,
    openPlaid,
    saveAccountAsDefaultAccount: SavedDefaultConnectedAccount,
  };
}
