import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { ViewportProvider } from "./Contexts/ViewportContext";
import { AppUtilityContextProvider } from "./Contexts/AppUtilityContext";
import { SupportProvider } from "./Contexts/SupportProvider";
import { ModalContextProvider } from "./Contexts/ModalContext";
import { CustomSnackbarProvider } from "./Contexts/CustomSnackbarContext";

import Routes from "./routes";

import ScrollToTop from "./Components/Others/scroll-to-top";

import UXCamLib from "./Utils/ux-cam-lib";
import BranchIOLib from "./Utils/branch-io";
import HeapAnalytics from "./Utils/heap-analytics-lib";
import Coralogix from "./Utils/coralogix";

import "./App.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <div id="portal-target-node">
      <QueryClientProvider client={queryClient}>
        <ViewportProvider>
          <BrowserRouter>
            <AppUtilityContextProvider>
              <SupportProvider>
                <ModalContextProvider>
                  <CustomSnackbarProvider>
                    <ScrollToTop />
                    <Routes />
                  </CustomSnackbarProvider>
                </ModalContextProvider>
              </SupportProvider>
            </AppUtilityContextProvider>
          </BrowserRouter>
        </ViewportProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <UXCamLib />
      <BranchIOLib />
      <HeapAnalytics />
      <Coralogix />
    </div>
  );
}

export default App;
