import { useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { closeSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

import OTPField from "../../Components/Form Fields/otp-field";
import PrimaryButton from "../../Components/Buttons/primary-button";

import { useSendVerificationCodeUsingUniqueID } from "../../CustomHooks/Queries";
import { useLoginWith2FACode, useRequestLogin2FACode } from "../../CustomHooks/Mutations";

import {
  dispatchErrorToast,
  dispatchSuccessToast,
  saveAndUpdateUserOnLogin,
  transformCountryCodeFormatToUSNumber,
} from "../../Utils/helpers";
import { debugProps } from "../../Utils/constants";

import { StakeLogo } from "../../Assets/Images";
import { Close } from "../../Assets/Icons";

const userNameTypes = { email: "email", phoneNumber: "phoneNumber" };

export default function LoginWithUniqueContactID() {
  const resendOtpRef = useRef(false);

  const navigate = useNavigate();

  const formProps = useForm();

  const uniqueContactID = useMemo(() => new URLSearchParams(window.location.search).get("uniqueContactId"), []);

  const redirectTo = useMemo(() => {
    const redirectTo = new URLSearchParams(window.location.search).get("redirectTo");
    return (redirectTo ?? "").length === 0 ? "/login" : redirectTo;
  }, []);

  const {
    isFetching: isSendingVerificationCodeUsingUniqueID,
    data: sendVerificationCodeResponse,
    error: sendVerificationCodeError,
    isError: isSendVerificationCodeError,
    isSuccess: isSendVerificationCodeSuccess,
  } = useSendVerificationCodeUsingUniqueID(uniqueContactID, { enabled: !!uniqueContactID });

  const {
    handleSubmit,
    getValues,
    formState: { isValid },
  } = formProps;

  const userName = sendVerificationCodeResponse?.data?.phoneNumber;

  const formattedPhoneNumber = useMemo(() => transformCountryCodeFormatToUSNumber(userName ?? ""), [userName]);

  const { isLoading: isRequestingLogin2FACode, mutate: RequestLogin2FACode } = useRequestLogin2FACode({
    onSuccess: () => {
      dispatchSuccessToast(`New OTP code was sent at ${formattedPhoneNumber}`, { key: "request2FA" });
    },
    onError: () => {
      dispatchErrorToast("Oops! Something went wrong!", { key: "request2FA" });
    },
    onSettled: () => (resendOtpRef.current = false),
  });

  const { isLoading: isLoggingInWith2FACode, mutate: LoginWith2FACode } = useLoginWith2FACode({
    onSuccess: ({ data }) => {
      saveAndUpdateUserOnLogin(data);
      dispatchSuccessToast("Verification success");
      navigate(redirectTo);
    },
    onError: (err) =>
      dispatchErrorToast(err?.response?.data?.error?.msg, {
        key: "loginWith2FA",
      }),
  });

  const isLoading = isRequestingLogin2FACode || isLoggingInWith2FACode;

  const onSubmit = () => {
    closeSnackbar();

    let payload = { ...debugProps, [userNameTypes.phoneNumber]: userName };

    if (resendOtpRef.current) {
      !isLoading && RequestLogin2FACode(payload);
    } else {
      payload.phoneNumberCode = getValues("otp");
      !isLoading && LoginWith2FACode(payload);
    }
  };

  const onFormError = (errors) => {
    dispatchErrorToast(errors.otp.message, { key: "otp" });
  };

  return (
    <div
      className={`onboarding-page login-with-unique-id${
        isSendingVerificationCodeUsingUniqueID
          ? " justify-content-center"
          : isSendVerificationCodeError || !uniqueContactID
          ? "  justify-content-between"
          : ""
      }`}
    >
      {isSendingVerificationCodeUsingUniqueID && <CircularProgress sx={{ color: "var(--text-primary)" }} />}
      {!isSendingVerificationCodeUsingUniqueID && (
        <>
          <header>
            <StakeLogo className="logo" />
          </header>
          {(isSendVerificationCodeError || !uniqueContactID) && (
            <>
              <div className="status-container max-width-container">
                <Close className="status-icon" />
                <div className="error-title mt-6">
                  {isSendVerificationCodeError
                    ? sendVerificationCodeError?.response?.data?.message ?? "Network Error"
                    : "Link Invalid"}
                </div>
              </div>
              <div className="max-width-container">
                <Link className="primary-button text-decoration-none my-6" to="/login">
                  Return to Login
                </Link>
              </div>
            </>
          )}
          {isSendVerificationCodeSuccess && (
            <>
              <FormProvider {...formProps}>
                <div className="max-width-container">
                  <form className="form-card" onSubmit={handleSubmit(onSubmit, onFormError)} autoComplete="off">
                    <div className="card-header">
                      <h1 className="text-4xl-semibold color--text-primary mt-1 mb-0">Verify Phone</h1>
                      <p className="text-lg-regular color--text-primary mt-2 mb-0">
                        Please enter the code sent to <span className="username-badge">{formattedPhoneNumber}</span>
                      </p>
                    </div>
                    <div className="otp-field">
                      <OTPField />
                      <button
                        className="resend-otp-button mt-3"
                        onClick={() => {
                          resendOtpRef.current = true;
                          onSubmit();
                        }}
                        type="button"
                      >
                        Didn’t receive a text? Resend code
                      </button>
                    </div>
                    <PrimaryButton className="w-100" type="submit" disabled={!isValid || isLoading}>
                      Next {isLoading && <CircularProgress />}
                    </PrimaryButton>
                  </form>
                </div>
              </FormProvider>
              <div className="dummy-footer"></div>
            </>
          )}
        </>
      )}
    </div>
  );
}
