import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import BankAccountInformation from "./bank-account-information";
import BankingActionButtons from "./banking-action-buttons";
import BankingActivity from "./banking-activity";
import EarnMoreCards from "./earn-more-cards";
import BankingServices from "./banking-services";

import BankAccountInformationSkeleton from "../../Components/Skeleton/bank-account-information-skeleton";
import BankActionButtonsSkeleton from "../../Components/Skeleton/banking-action-buttons-skeleton";
import BankingActivitySkeleton from "../../Components/Skeleton/banking-activity-skeleton";
import EarnMoreCardSkeleton from "../../Components/Skeleton/earn-more-card-skeleton";

import renderStakePay3Experience from "../../Organisms/Modals/Stake Pay 3/stake-pay-3-experience";

import useAstra from "../../CustomHooks/useAstra";
import usePinWheel from "../../CustomHooks/usePinWheel";

import { useDeals, useActivities, useStakeDebit, useConnectedAccounts } from "../../CustomHooks/Queries";

import { useModalContext } from "../../Contexts/ModalContext";

import { getHasSeenStakePay3Experience, isSVDApproved, LocalStorage } from "../../Utils/helpers";
import { appUtilityKeys } from "../../Utils/constants";

import "./banking.scss";
import "./banking-responsive.scss";
import "./banking-skeleton.scss";

export default function Banking() {
  const navigate = useNavigate();
  const modalContext = useModalContext();

  useEffect(() => {
    const deepLinkData = LocalStorage.read(appUtilityKeys.deeplink);

    if (deepLinkData) {
      if (deepLinkData === "beginCreditBuilder") {
        navigate("/renting?openCreditBuilder=true");
      }
      LocalStorage.remove(appUtilityKeys.deeplink);
    }
  }, [navigate]);

  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();

  const { isLoading: isLoadingDeals, data: deals } = useDeals({
    onSuccess: ({ data }) => {
      if (
        !(LocalStorage.read(getHasSeenStakePay3Experience()) ?? false) &&
        data?.data?.currentDeal?.is_stakepay_enabled
      ) {
        renderStakePay3Experience(modalContext);
      }
    },
  });

  const { isLoading: isLoadingConnectedAccounts, data: ConnectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const { isLoadingPayrollAccounts, payrollAccountsData, ...payrollProps } = usePinWheel(IsSVDApproved);

  const { isInitialisingAstra, isLoadingAstraCards, astraDebitCards } = useAstra();

  const isLoadingBankingActionButtons =
    isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingAstraCards || isInitialisingAstra;

  const isLoadingEarnMore =
    isLoadingDeals || isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingPayrollAccounts;

  const isLoadingBankingServices =
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingPayrollAccounts ||
    isLoadingAstraCards ||
    isInitialisingAstra;

  const availableBalance = useMemo(() => {
    return stakeDebit?.data?.data?.available ?? 0;
  }, [stakeDebit]);

  return (
    <div className="banking">
      <section className="banking-details">
        <div className="max-width-container">
          {isLoadingActivities || isLoadingStakeDebit || !stakeDebit ? (
            <BankAccountInformationSkeleton />
          ) : (
            <BankAccountInformation
              currentBalance={activitiesData?.data?.data?.currentBalance ?? 0}
              availableBalance={availableBalance}
              stakeDebit={stakeDebit}
            />
          )}
          {isLoadingBankingActionButtons ? (
            <BankActionButtonsSkeleton />
          ) : (
            <BankingActionButtons
              stakeDebit={stakeDebit}
              connectedAccounts={ConnectedAccounts}
              astraDebitCards={astraDebitCards}
            />
          )}
          {isLoadingActivities ? (
            <BankingActivitySkeleton />
          ) : (
            <BankingActivity bankActivityList={activitiesData ?? []} />
          )}
        </div>
      </section>
      <section className="earn-more-section">
        <div className="max-width-container">
          <div className="section-header">
            <h3 className="section-title">Earn More</h3>
          </div>
          {isLoadingEarnMore ? (
            <EarnMoreCardSkeleton />
          ) : (
            <EarnMoreCards
              connectedAccounts={ConnectedAccounts}
              stakeDebit={stakeDebit}
              deals={deals}
              payrollAccountsData={payrollAccountsData}
              payrollProps={payrollProps}
            />
          )}
        </div>
      </section>
      {!isLoadingBankingServices && (
        <section className="banking-services-section pt-0">
          <div className="max-width-container">
            <div className="section-header">
              <h3 className="section-title">Banking Services</h3>
            </div>
            <BankingServices
              connectedAccounts={ConnectedAccounts}
              stakeDebit={stakeDebit}
              payrollAccountsData={payrollAccountsData}
              astraDebitCards={astraDebitCards}
            />
          </div>
        </section>
      )}
    </div>
  );
}
