import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import EarnMore from "../../Organisms/Earn More Card";
import CustomRadioButton from "../../Components/Form Fields/radio-button.js";
import PrimaryButton from "../../Components/Buttons/primary-button.js";

import { renderDeleteConnectedAccount } from "../../Organisms/Modals/Bank Account/delete-connected-account.js";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext.js";

import { useConnectedAccounts, useConnectedAccountSelection, useGetRecurringDeposit } from "../../CustomHooks/Queries";

import { AddABank } from "../../Assets/Images";
import { ArrowLeftCircle, AddCircle, AddCircleFilled, Trash } from "../../Assets/Icons";

import useConnectBankAccount from "../../CustomHooks/useConnectBankAccount.js";

import { dispatchAPIErrorToast } from "../../Utils/helpers.js";

import "./connected-accounts.scss";
import "./connected-accounts-responsive.scss";

const BankAccountItem = ({ account, isDefaultAccount, selected, selectable, setAccountAsDefault, onSelect }) => {
  const modalContext = useModalContext();

  const handleResetConnectedAccount = (e) => {
    e.stopPropagation();
    renderDeleteConnectedAccount(modalContext, account);
  };

  const selectCardForPayment = (account) => {
    if (selectable) {
      onSelect?.(account.account_id);
    }
  };

  return (
    <div
      className={`bank-account-item ${selectable ? "selectable" : ""} ${selected ? "selected" : ""}`}
      onClick={() => selectCardForPayment(account)}
    >
      <div className="info">
        {selectable && <CustomRadioButton checked={selected} onChange={() => selectCardForPayment(account)} />}
        {!!account.logo_url_base64_encoded ? (
          <img className="bank-logo" src={`data:image/png;base64,${account.logo_url_base64_encoded}`} alt="" />
        ) : (
          <div className="bank-logo-placeholder">{account.institution_name}</div>
        )}
        <div className="account-details">
          <h4 className="account-name">{account.name ?? ""}</h4>
          <span className="account-number">(...{account.mask ?? "****"})</span>
        </div>
      </div>
      <div className="actions">
        {isDefaultAccount ? (
          <span className="default-badge">Default</span>
        ) : (
          <button className="set-default" onClick={setAccountAsDefault}>
            Set as Default
          </button>
        )}
        <button className="delete" onClick={handleResetConnectedAccount}>
          <Trash />
        </button>
      </div>
    </div>
  );
};

export default function ConnectedAccountsPage() {
  return (
    <div className="connected-accounts">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <ConnectedAccounts />
          </div>
        </div>
      </section>
    </div>
  );
}

export const ConnectedAccounts = ({ presentedForSelection = false }) => {
  const { popModalStack, setModalClassName } = useModalContext();

  const { appUtilityProps, setAppUtilityProps } = useAppUtilityContext();

  const navigate = useNavigate();

  const { isFetching: isFetchingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isFetching: isFetchingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const { isLoading: isLoadingRecurringDeposit } = useGetRecurringDeposit();

  const { isSavingAccount, plaidReady, openPlaid, saveAccountAsDefaultAccount } = useConnectBankAccount({
    onError: () => dispatchAPIErrorToast({ alignToAppContent: true }),
    onInitiatingSave: () => presentedForSelection && setModalClassName("rendering-page-content"),
    onExit: () => presentedForSelection && setModalClassName("rendering-page-content"),
  });

  const connectedAccountItems = useMemo(() => {
    const accounts = connectedAccounts?.data?.data?.accounts || [];
    return accounts.map((account, index) => (
      <BankAccountItem
        key={account.account_id || index}
        account={account}
        isDefaultAccount={connectedAccountSelection?.data?.data?.selected === account.account_id}
        setAccountAsDefault={() => saveAccountAsDefaultAccount({ account_id: account.account_id })}
        selectable={presentedForSelection}
        selected={account.account_id === (appUtilityProps?.selectedAccountId ?? accounts[0]?.account_id)}
        onSelect={(account_id) => setAppUtilityProps((p) => ({ ...p, selectedAccountId: account_id }))}
      />
    )); //eslint-disable-next-line
  }, [
    connectedAccounts?.data?.data?.accounts,
    connectedAccountSelection?.data?.data?.selected,
    saveAccountAsDefaultAccount,
    presentedForSelection,
    appUtilityProps?.selectedAccountId,
  ]);

  const isConnected = useMemo(() => {
    return connectedAccountItems.length > 0;
  }, [connectedAccountItems]);

  const handleAddButtonClick = () => {
    if (presentedForSelection) {
      setModalClassName("d-none");
    }
    openPlaid();
  };

  const isFetching =
    isFetchingConnectedAccounts || isFetchingConnectedAccountSelection || isSavingAccount || isLoadingRecurringDeposit;

  return (
    <>
      {!presentedForSelection && (
        <div className="section-header">
          <button className="go-back-button" onClick={() => navigate(-1)}>
            <ArrowLeftCircle />
          </button>
          <h3 className="section-title">Connected Accounts</h3>
          {!plaidReady ? (
            <CircularProgress />
          ) : (
            <button className="add-button" onClick={handleAddButtonClick}>
              <AddCircle />
            </button>
          )}
        </div>
      )}
      {isFetching || (!isConnected && !plaidReady) ? (
        <div className="loading-content">
          <CircularProgress />
        </div>
      ) : isConnected ? (
        <div className="content connected-bank-accounts">
          <div className="top-container">
            {connectedAccountItems}
            {presentedForSelection && (
              <button className="add-account" onClick={handleAddButtonClick}>
                <AddCircleFilled />
                Add New Account
              </button>
            )}
          </div>
          {presentedForSelection && (
            <div className="bottom-container stick-to-modal-bottom">
              <PrimaryButton className="blue-theme" onClick={() => popModalStack(1)}>
                Confirm
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <div className="content">
          <div className="connect-your-bank">
            <EarnMore
              title={`Transfer to your bank or debit card`}
              cardButtonText="Get Started"
              CardIllustrationComponent={AddABank}
              onCardButtonClick={openPlaid}
            />
          </div>
        </div>
      )}
    </>
  );
};
