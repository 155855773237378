import React, { useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment";

import { RentPaymentItem, StakePayCashBackDay, StakePayCashBackToolBar, StakePayDateField } from "./components.js";
import CustomRadioButton from "../../Components/Form Fields/radio-button.js";
import DatePickerField, { daySxProp } from "../../Components/Form Fields/date-picker-field.js";
import FormSwitch from "../../Components/Form Fields/switch.js";
import PrimaryButton from "../../Components/Buttons/primary-button.js";

import renderPaymentItemTooltip from "../../Organisms/Modals/Stake Pay 3/payment-item-tooltip.js";
import renderSelectConnectedAccounts from "../../Organisms/Modals/Bank Account/select-connected-accounts.js";
import renderAddStripeCardForm from "../../Organisms/Modals/Cards/add-stripe-card-form.js";
import renderManageFinixCards from "../../Organisms/Modals/Cards/manage-finix-cards.js";
import renderMakeCashPayment from "../../Organisms/Modals/Cash/make-cash-payment.js";
import renderAlertConfirmationModal from "../../Organisms/Modals/alert-confirmation-modal.js";
import renderAuthorizeRentPayment from "../../Organisms/Modals/Transfer/authorize-rent-payment.js";
import renderStatusModal from "../../Organisms/Modals/status-modal.js";
import renderAutopayRentPaymentSuccess from "../../Organisms/Modals/Transfer/autopay-rent-payment-success.js";

import useApplyStakeDebit from "../../CustomHooks/useApplyStakeDebit.js";
import useConnectBankAccount from "../../CustomHooks/useConnectBankAccount.js";

import {
  useConnectedAccounts,
  useConnectedAccountSelection,
  useDeals,
  useFinixConnectedCards,
  useUserAccount,
} from "../../CustomHooks/Queries/index.js";
import { useStakePay3SetupPayment, useStakePay3EditPayment } from "../../CustomHooks/Mutations/index.js";

import { useModalContext } from "../../Contexts/ModalContext.js";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext.js";

import { getSetupRentPaymentPayload } from "../../Utils/api-payload-generators.js";
import {
  formatCurrency,
  formatNumberWithCentsToUSD,
  isSVDApproved,
  isSVDDenied,
  isSVDEligible,
  isSVDIneligible,
} from "../../Utils/helpers.js";
import {
  APIQueryKeys,
  FailedRentPaymentStatusDescription,
  FailedRentPaymentStatusModalProps,
  LateRentPayemtStatusModalProps,
  SuccessfulRentPaymentStatusAutoPayEligibleModalProps,
  SuccessfulRentPaymentStatusDescription,
  SuccessfulRentPaymentStatusModalProps,
  ConfirmTurnOffAutopayModalProps,
  StakeCheckingLowAvailableBalanceModalProps,
  StakeCheckingLowAvailableBalanceDescription,
} from "../../Utils/constants.js";

import { Blitz, Card, Cash, Edit, WarningTriangle } from "../../Assets/Icons";
import { BankIcon } from "../../Assets/Images";

export default function SetupPayment({
  rentPaymentItem,
  isEditing,
  shouldConfirmAutoPay,
  onCancelRentPayment,
  customerPaymentId,
}) {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { appUtilityProps } = useAppUtilityContext();

  const modalContext = useModalContext();
  const { onModalClose, popModalStack, updateModalOnCloseCallBack } = modalContext;

  const { register, watch, handleSubmit, setValue } = useFormContext();

  const paymentMethodProps = register("payment-method");

  const paymentMethod = watch("payment-method");
  const paymentDate = watch("payment-date");
  const isAutopay = watch("autopay");

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const {
    applyingSVD,
    handleStakeCheckingModalNotApproved,
    stakeDebitQuery: { isLoading: isLoadingStakeDebit, data: stakeDebit },
  } = useApplyStakeDebit();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const { isLoading: isLoadingUserAccount, data: userAccount } = useUserAccount();

  const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount();

  const { isLoading: isLoadingConnectedCards, data: connectedFinixCards } = useFinixConnectedCards();

  const stakePay = deals?.data?.data?.currentDeal?.stake_pay;

  const isAutopayEligible = rentPaymentItem?.isAutopayEligible;

  useEffect(() => {
    if (!isEditing) {
      if (isSVDIneligible(stakeDebit?.data) || isSVDDenied(stakeDebit?.data)) {
        setValue("payment-method", "bank-account");
      } else {
        setValue("payment-method", "stake-checking");
      }
    }
    //eslint-disable-next-line
  }, [isEditing, stakeDebit?.data]);

  useEffect(() => {
    if (!isEditing && !!rentPaymentItem) {
      setValue("payment-date", rentPaymentItem?.defaultDate);
      if (isAutopayEligible || shouldConfirmAutoPay) {
        setValue("autopay", true);
      } else {
        setValue("autopay", false);
      }
    } //eslint-disable-next-line
  }, [isEditing, rentPaymentItem, isAutopayEligible, shouldConfirmAutoPay]);

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) =>
          account.account_id === appUtilityProps?.selectedAccountId ?? connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [
      appUtilityProps?.selectedAccountId,
      connectedAccounts?.data?.data?.accounts,
      connectedAccountSelection?.data?.data?.selected,
    ]
  );

  const selectedFinixCard = useMemo(
    () =>
      connectedFinixCards?.data?.data?.data?.find((card) => card.cardId === appUtilityProps?.selectedFinixCardId) ??
      connectedFinixCards?.data?.data?.data?.[0] ??
      null,
    [appUtilityProps?.selectedFinixCardId, connectedFinixCards?.data?.data?.data]
  );

  const isCashBackDate = useMemo(
    () =>
      moment(paymentDate).isBetween(moment(rentPaymentItem?.startDate), moment(rentPaymentItem?.endDate), "days", "[]"),
    [paymentDate, rentPaymentItem]
  );

  const paymentDetailsProps = {
    ...(paymentMethod === "stake-checking"
      ? { stakeDebit: stakeDebit?.data?.data }
      : paymentMethod === "bank-account"
      ? { selectedAccount }
      : paymentMethod === "card"
      ? { selectedFinixCard }
      : {}),
    paymentTotal: rentPaymentItem?.itemTotal,
    paymentSplitUp: rentPaymentItem?.itemDetails,
    paymentDate,
    isAutopay,
    isInsufficientFunds:
      paymentMethod === "stake-checking" ? rentPaymentItem?.itemTotal > stakeDebit?.data?.data?.available : false,
  };

  const onRentPaymentSuccess = ({ data }) => {
    queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentDetails);
    queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentMonths);
    isAutopay && queryClient.refetchQueries(APIQueryKeys.stakePay3PaymentSetupSummary);
    const GoToManageRentPayment = () => navigate("/renting/stakepay-3/manage");
    const closeModalAndNavigateToConfirmAutoPay = () =>
      onModalClose({ callOnClose: () => navigate("/renting/stakepay-3/setup-autopay") });
    const closeModalAndNavigateToRenting = () => onModalClose({ callOnClose: GoToManageRentPayment });
    updateModalOnCloseCallBack(GoToManageRentPayment);
    if (isAutopay) {
      renderAutopayRentPaymentSuccess(modalContext, {
        ...paymentDetailsProps,
        onCTAButtonClick: closeModalAndNavigateToRenting,
      });
    } else {
      renderStatusModal(modalContext, {
        ...SuccessfulRentPaymentStatusModalProps,
        description: SuccessfulRentPaymentStatusDescription({
          isAutopayEligible: data?.data?.isAutopayEligible,
          amount: rentPaymentItem?.itemTotal,
          date: data?.data?.paymentDate,
        }),
        ...(data?.data?.isAutopayEligible
          ? {
              ...SuccessfulRentPaymentStatusAutoPayEligibleModalProps,
              onCTAButtonClick: closeModalAndNavigateToConfirmAutoPay,
              onSecondaryCTAButtonClick: closeModalAndNavigateToRenting,
            }
          : { onCTAButtonClick: closeModalAndNavigateToRenting }),
      });
    }
  };

  const onRentPaymentError = () => {
    renderStatusModal(modalContext, {
      ...FailedRentPaymentStatusModalProps,
      description: FailedRentPaymentStatusDescription(isAutopay),
      onCTAButtonClick: () => popModalStack(0),
      onSecondaryCTAButtonClick: () => onModalClose(),
    });
  };

  const { mutate: SetupPayment } = useStakePay3SetupPayment({
    onSuccess: onRentPaymentSuccess,
    onError: onRentPaymentError,
  });

  const { mutate: EditPayment } = useStakePay3EditPayment({
    onSuccess: onRentPaymentSuccess,
    onError: onRentPaymentError,
  });

  const openAuthorizeRentPayment = () => {
    const Pay = isEditing ? EditPayment : SetupPayment;

    renderAuthorizeRentPayment(modalContext, {
      ...paymentDetailsProps,
      AuthorizeCallback: () =>
        Pay(
          getSetupRentPaymentPayload({
            customerPaymentId,
            paymentDate,
            isAutopay,
            paymentMethod,
            rentPaymentItem,
            selectedAccount,
            selectedFinixCard,
            deals,
            stakeDebit,
            userAccount,
          })
        ),
    });
  };

  const handleToggleAutopay = () => {
    if (isAutopay) {
      renderAlertConfirmationModal(modalContext, {
        ...ConfirmTurnOffAutopayModalProps,
        onCTAButtonClick: () => {
          setValue("autopay", false);
          onModalClose();
        },
        onSecondaryCTAButtonClick: onModalClose,
      });
    } else {
      setValue("autopay", true);
    }
  };

  const MakePayment = () => {
    if (!isCashBackDate) {
      renderAlertConfirmationModal(modalContext, {
        ...LateRentPayemtStatusModalProps,
        onCTAButtonClick: openAuthorizeRentPayment,
        onSecondaryCTAButtonClick: onModalClose,
      });
    } else {
      openAuthorizeRentPayment();
    }
  };

  const onSubmit = () => {
    if (paymentMethod === "stake-checking" && !isSVDApproved(stakeDebit?.data)) {
      handleStakeCheckingModalNotApproved(stakeDebit?.data);
    } else if (paymentMethod === "bank-account" && !selectedAccount) {
      openPlaid();
    } else if (paymentMethod === "card" && !selectedFinixCard) {
      renderAddStripeCardForm(modalContext);
    } else if (paymentMethod === "cash") {
      renderMakeCashPayment(modalContext);
    } else if (
      paymentMethod === "stake-checking" &&
      stakeDebit?.data?.data?.available / 100 < rentPaymentItem?.itemTotal
    ) {
      renderAlertConfirmationModal(modalContext, {
        ...StakeCheckingLowAvailableBalanceModalProps,
        description: StakeCheckingLowAvailableBalanceDescription(paymentDate),
        onCTAButtonClick: () => {
          onModalClose();
          navigate("/banking/add-cash");
        },
        onSecondaryCTAButtonClick: MakePayment,
      });
    } else {
      MakePayment();
    }
  };

  const isLoading =
    isLoadingDeals ||
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingConnectedAccountSelection ||
    isLoadingConnectedCards ||
    isLoadingUserAccount ||
    !plaidReady;

  const stakeCheckingDescription = useMemo(() => {
    if (isSVDEligible(stakeDebit?.data)) {
      return "Apply in 2 minutes to unlock huge rent savings with instant Cash Back.";
    } else if (isSVDApproved(stakeDebit?.data)) {
      return (
        <>
          Available Balance:{" "}
          <span className="color--charcoal">{formatNumberWithCentsToUSD(stakeDebit?.data?.data?.available ?? 0)}</span>
        </>
      );
    } else {
      return stakeDebit?.data?.data?.home_card_body;
    }
  }, [stakeDebit?.data]);

  const bankAccountDescription = useMemo(() => {
    return (
      <>
        {!selectedAccount ? "Fee: " : "Available Balance: "}
        <span className="color--charcoal">
          {!selectedAccount ? "Free" : formatCurrency(selectedAccount?.balances?.available ?? 0)}
        </span>
      </>
    );
  }, [selectedAccount]);

  const payButtonLabel = useMemo(() => {
    var labelText = "";
    if (paymentMethod === "stake-checking" && isSVDEligible(stakeDebit?.data)) {
      labelText = "Apply Stake Checking & ";
    } else if (paymentMethod === "bank-account" && !selectedAccount) {
      labelText = "Link your Bank & ";
    } else if (paymentMethod === "card" && !selectedFinixCard) {
      labelText = "Link your Card & ";
    }
    labelText += isEditing
      ? "Save Changes"
      : `${isAutopay ? "Schedule" : "Pay"} ${formatCurrency(rentPaymentItem?.itemTotal)}`;
    return labelText;
  }, [
    paymentMethod,
    stakeDebit?.data,
    selectedAccount,
    selectedFinixCard,
    isAutopay,
    rentPaymentItem?.itemTotal,
    isEditing,
  ]);

  const disablePayRentButton = (!paymentMethod && !paymentDate) || applyingSVD || isSavingAccount;

  return isLoading ? (
    <div className="loading-content">
      <CircularProgress />
    </div>
  ) : (
    <form className="content" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="top-container">
        <div className="text-lg-medium color--charcoal">Payment Details</div>
        <div className="payment-card py-4 mt-3">
          <RentPaymentItem
            rentPaymentLabel={rentPaymentItem?.itemName}
            rentPaymentValue={formatCurrency(rentPaymentItem?.itemTotal)}
            rentPaymentDetails={rentPaymentItem?.itemDetails}
            onKnowMore={renderPaymentItemTooltip.bind(this, modalContext, rentPaymentItem)}
          />
        </div>
        <div className="text-lg-medium color--charcoal mt-5">Payment Method</div>
        <div className="payment-card mt-3">
          {!(isSVDIneligible(stakeDebit?.data) || isSVDDenied(stakeDebit?.data)) && (
            <>
              <div className="card-row">
                <div className="payment-method">
                  <div className="payment-method-icon stake-checking">
                    <Blitz />
                  </div>
                  <div className="info">
                    <h6 className="title">
                      Stake Checking Account{" "}
                      {isSVDApproved(stakeDebit?.data)
                        ? `(... ${stakeDebit?.data?.data?.account_number?.slice(-4)})`
                        : ""}
                    </h6>
                    {stakePay?.stake_checking?.is_cashback_eligible && isCashBackDate && (
                      <span className="cashback-summary">
                        Earn {formatCurrency(stakePay?.stake_checking?.cashback_amount)} Cash Back instantly
                      </span>
                    )}
                    <span className="description">{stakeCheckingDescription}</span>
                  </div>
                </div>
                <CustomRadioButton value="stake-checking" {...paymentMethodProps} />
              </div>
              <Divider />
            </>
          )}
          <div className="card-row">
            <div className="payment-method">
              <div className="payment-method-icon">
                <BankIcon />
              </div>
              <div className="info">
                <h6 className="title">
                  {!selectedAccount
                    ? "Checking Account / ACH"
                    : `${selectedAccount?.name} (...${selectedAccount?.mask})`}
                  {!!selectedAccount && (
                    <button
                      className="select-payment-entity"
                      type="button"
                      onClick={renderSelectConnectedAccounts.bind(this, modalContext)}
                    >
                      Change <Edit />
                    </button>
                  )}
                </h6>
                {stakePay?.other_ach?.is_cashback_eligible && isCashBackDate && (
                  <span className="cashback-summary">
                    Earn {formatCurrency(stakePay?.other_ach?.cashback_amount)} Cash Back
                  </span>
                )}
                <span className="description">{bankAccountDescription}</span>
              </div>
            </div>
            <CustomRadioButton value="bank-account" {...paymentMethodProps} />
          </div>
          <Divider />
          <div className="card-row">
            <div className="payment-method">
              <div className="payment-method-icon">
                <Card />
              </div>
              <div className="info">
                <h6 className="title text-capitalize">
                  {!selectedFinixCard
                    ? "Debit/Credit card"
                    : `${selectedFinixCard.cardBrand?.toLowerCase()} (...${selectedFinixCard.cardLast4Digits})`}
                  {!!selectedFinixCard && (
                    <button
                      className="select-payment-entity"
                      type="button"
                      onClick={renderManageFinixCards.bind(this, modalContext)}
                    >
                      Change <Edit />
                    </button>
                  )}
                </h6>
                {stakePay?.other_card?.is_cashback_eligible && isCashBackDate && (
                  <span className="cashback-summary">
                    Earn {formatCurrency(stakePay?.other_card?.cashback_amount)} Cash Back
                  </span>
                )}
                <span className="description">
                  Fee:{" "}
                  <span className="color--charcoal">
                    {stakePay?.debit_card_fee}% debit & {stakePay?.credit_card_fee}% credit
                  </span>
                </span>
              </div>
            </div>
            <CustomRadioButton value="card" {...paymentMethodProps} />
          </div>
          <Divider />
          <div className="card-row">
            <div className="payment-method">
              <div className="payment-method-icon">
                <Cash />
              </div>
              <div className="info">
                <h6 className="title text-capitalize">Cash</h6>
                {stakePay?.cash?.is_cashback_eligible && isCashBackDate && (
                  <span className="cashback-summary">
                    Earn {formatCurrency(stakePay?.cash?.cashback_amount)} Cash Back
                  </span>
                )}
              </div>
            </div>
            <CustomRadioButton value="cash" {...paymentMethodProps} />
          </div>
        </div>
        {paymentMethod !== "cash" && (
          <>
            <div className="text-lg-medium color--charcoal mt-5">
              {isAutopayEligible && !shouldConfirmAutoPay && "Autopay & "}Payment Date
            </div>
            <div className="payment-card mt-3">
              {isAutopayEligible && !shouldConfirmAutoPay && (
                <div className="card-row mb-4">
                  <div className="text-sm-medium color--charcoal">Autopay rent</div>
                  <FormSwitch checked={isAutopay} onClick={handleToggleAutopay} />
                </div>
              )}
              <div className="card-row">
                <div className="text-sm-medium color--charcoal">{isAutopay ? "Recurring Date" : "Change Date"}</div>
                <DatePickerField
                  name="payment-date"
                  datePickerProps={{
                    minDate: moment(isAutopay ? rentPaymentItem?.rentPaymentMonth : rentPaymentItem?.startDate),
                    maxDate: moment(rentPaymentItem?.lastPaymentDate),
                  }}
                  miniField={true}
                  required={true}
                  slots={{
                    day: StakePayCashBackDay,
                    toolbar: StakePayCashBackToolBar,
                    textField: StakePayDateField,
                  }}
                  slotProps={{
                    day: {
                      sx: { ...daySxProp, borderRadius: "var(--rounded-sm)" },
                      cashBackDays: {
                        startDate: rentPaymentItem?.startDate,
                        endDate: rentPaymentItem?.endDate,
                      },
                    },
                    field: { shouldRespectLeadingZeros: true },
                    toolbar: { hidden: false },
                  }}
                />
              </div>
              {!isCashBackDate && (
                <span className="info-alert error mt-4">
                  <WarningTriangle />
                  Additional fees will be applied and no Cash Back if paid after{" "}
                  {moment(rentPaymentItem?.endDate).format(`Do${isAutopay ? "" : " MMMM"}`)}
                  {isAutopay && " of every month"}.
                </span>
              )}
            </div>
          </>
        )}
      </div>
      <div className="bottom-container">
        <PrimaryButton className="blue-theme" type="submit" disabled={disablePayRentButton}>
          {payButtonLabel} {(applyingSVD || isSavingAccount) && <CircularProgress />}
        </PrimaryButton>
        {isEditing && (
          <PrimaryButton className="secondary-colors mt-4" type="button" onClick={onCancelRentPayment}>
            Cancel Payment
          </PrimaryButton>
        )}
      </div>
    </form>
  );
}
