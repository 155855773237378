import PrimaryButton from "../../../Components/Buttons/primary-button";

import { formatCurrency, formatDate, getNumberWithOrdinal } from "../../../Utils/helpers";
import { modalVariants, longMonthDateFormat } from "../../../Utils/constants";

import { CheckCircleWithShadow } from "../../../Assets/Icons";

export default function renderAutopayRentPaymentSuccess(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <AutopayRentPaymentSuccess {...props} />);
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("has-transfer-info hide-header");
  setModalTitle("Authorize this transaction");
}

export const AutopayRentPaymentSuccess = (props) => {
  const { stakeDebit, selectedAccount, selectedFinixCard, paymentTotal, paymentDate, onCTAButtonClick } = props;

  return (
    <>
      <div className="modal-dialog-content mt-6">
        <CheckCircleWithShadow className="modal-status-icon" />
        <h3 className="text-2xl-semibold color-charcoal text-center mt-5 mb-6">Autopay scheduled successfully</h3>
        <div className="transfer-detail">
          <span className="detail-label">Starts From</span>
          <span className="detail-value">{formatDate(paymentDate, longMonthDateFormat)}</span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Total Amount</span>
          <span className="detail-value">{formatCurrency(paymentTotal)}</span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Payment Frequency</span>
          <span className="detail-value">
            {getNumberWithOrdinal(parseInt(paymentDate?.split("-")?.[2]))} of every month
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Payment Method</span>
          <span className="detail-value">
            {!!stakeDebit
              ? "Stake Checking"
              : !!selectedAccount
              ? "Checking Account / ACH"
              : !!selectedFinixCard
              ? "Credit / Debit Card"
              : ""}
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Debit From</span>
          <span className="detail-value">
            {!!stakeDebit
              ? `Stake Checking (... ${stakeDebit?.account_number?.slice(-4)})`
              : !!selectedAccount
              ? `${selectedAccount?.name} (...${selectedAccount?.mask})`
              : !!selectedFinixCard
              ? `${selectedFinixCard.cardBrand?.toLowerCase()} (...${selectedFinixCard.cardLast4Digits})`
              : ""}
          </span>
        </div>
        {(!!stakeDebit || !!selectedAccount) && (
          <div className="transfer-detail">
            <span className="detail-label">Routing Number</span>
            <span className="detail-value">
              {!!stakeDebit ? stakeDebit.routing_number : !!selectedAccount ? selectedAccount?.routing : ""}
            </span>
          </div>
        )}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={onCTAButtonClick}>
          Done
        </PrimaryButton>
      </div>
    </>
  );
};
