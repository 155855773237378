import CopyToClipboard from "react-copy-to-clipboard";

import { Copy } from "../../Assets/Icons";

export default function CopyButton(props) {
  return (
    <CopyToClipboard {...props}>
      <div className="copy-button">
        <Copy className="icon" />
        Copy
      </div>
    </CopyToClipboard>
  );
}
