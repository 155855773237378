import { useRef, useState } from "react";

import renderStakeCheckingApproved from "../Organisms/Modals/Stake Checking Status/stake-checking-approved";
import renderStakeCheckingDenied from "../Organisms/Modals/Stake Checking Status/stake-checking-denied";
import renderStakeCheckingPending from "../Organisms/Modals/Stake Checking Status/stake-checking-pending";
import renderStakeCheckingVerificationPending from "../Organisms/Modals/Stake Checking Status/stake-checking-verification-pending";

import { useModalContext } from "../Contexts/ModalContext";

import { useStakeDebit } from "./Queries";

import {
  isSVDApproved,
  isSVDAwaitingDocuments,
  isSVDDenied,
  isSVDEligible,
  isSVDPending,
  isSVDPendingVerification,
} from "../Utils/helpers";

export default function useApplyStakeDebit(stakeDebitQueryProps = {}) {
  const modalContext = useModalContext();

  const SVDApplicationSession = useRef(null);
  const SVDStatus = useRef(null);

  const [applyingSVD, setApplyingSVD] = useState(false);

  const handleStakeCheckingModalNotApproved = (data) => {
    if (isSVDEligible(data)) {
      const newWindow = window.open(data?.data?.application_link ?? "", "_blank");

      if (!newWindow) {
        alert("Failed to open a new tab. Please allow popups.");
      } else {
        SVDApplicationSession.current = newWindow;
        setApplyingSVD(true);
      }
    } else if (isSVDDenied(data)) {
      renderStakeCheckingDenied(modalContext);
    } else if (isSVDPendingVerification(data)) {
      renderStakeCheckingPending(modalContext);
    } else if (isSVDPending(data)) {
      renderStakeCheckingVerificationPending(modalContext, SVDApplicationSession.current);
    }
  };

  const stakeDebitQuery = useStakeDebit({
    refetchInterval: applyingSVD ? 3 * 1000 : false,
    refetchIntervalInBackground: applyingSVD,
    refetchOnWindowFocus: applyingSVD,
    onSuccess: ({ data }) => {
      const status = data?.data?.status;
      if (applyingSVD) {
        if (isSVDApproved(data)) {
          renderStakeCheckingApproved(modalContext);
        } else if (!isSVDEligible(data)) {
          handleStakeCheckingModalNotApproved(data);
        }

        if (SVDStatus.current !== status || SVDApplicationSession?.current?.closed) {
          setApplyingSVD(false);
        }

        if (SVDStatus.current !== status && !isSVDAwaitingDocuments(data) && !SVDApplicationSession?.current?.closed) {
          SVDApplicationSession?.current?.close();
        }
      }
      SVDStatus.current = status;
    },
    ...stakeDebitQueryProps,
  });

  return { applyingSVD, handleStakeCheckingModalNotApproved, stakeDebitQuery };
}
