import { dispatchAPIErrorToast, LocalStorage } from "./helpers";

import { appUtilityKeys } from "./constants";

export const openAstraPortal = (actionType, props = {}) => {
  if (!!window.Astra) {
    const clientId = process.env.REACT_APP_ASTRA_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_ASTRA_REDIRECT_URL;
    const userIntentId = LocalStorage.read(appUtilityKeys.astraIntentId);

    try {
      const handler = window.Astra.create({
        actionType,
        clientId,
        redirectUri,
        bypassConnect: false,
        debitDirect: true,
        ...props,
        ...(!!userIntentId ? { userIntentId } : {}),
      });

      handler.open();
    } catch (e) {
      dispatchAPIErrorToast({ key: "openAstraPortal" });
    }
  }
};
