import { useQueryClient } from "react-query";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useDeleteFinixCard } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

export default function renderDeleteFinixCardConfirmationModal(modalContext, props) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle, setModalClassName } = modalContext;

  updateModalComponent(() => <DeleteFinixCardConfirmationModal {...props} />);
  pushModalStack(() => renderDeleteFinixCardConfirmationModal(modalContext));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
  setModalClassName("");
}

const DeleteFinixCardConfirmationModal = ({ card }) => {
  const queryClient = useQueryClient();

  const { popModalStack } = useModalContext();

  const { isLoading: isDeleingFinixCard, mutate: DeleteFinixCard } = useDeleteFinixCard({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.finixConnectedCards);
      popModalStack(1);
    },
    onError: (err) =>
      dispatchAPIErrorToast(err?.response?.status === 422 ? { message: err?.response?.data?.error } : {}),
  });

  return (
    <AlertConfirmationModal
      variant="alert-error"
      title="Remove Card"
      description={`Are you sure you want to delete ${card.cardBrand} (...${card.cardLast4Digits})?`}
      CTAButtonText="Remove"
      onCTAButtonClick={() => DeleteFinixCard(card.cardId)}
      isCTALoading={isDeleingFinixCard}
      secondaryCTAButtonText="Cancel"
      onSecondaryCTAButtonClick={() => popModalStack(1)}
    />
  );
};
