import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";
import AmountField from "../../../Components/Form Fields/amount-field";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderSelectConnectedAccounts from "../Bank Account/select-connected-accounts";
import renderSelectDebitCards from "../Cards/select-debit-cards";

import useAstra from "../../../CustomHooks/useAstra";

import { useModalContext } from "../../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../../Contexts/AppUtilityContext";

import {
  useConnectedAccounts,
  useConnectedAccountSelection,
  useDeals,
  useStakeDebit,
} from "../../../CustomHooks/Queries";

import { dispatchErrorToast, formatCurrency, getSelectedAstraCard } from "../../../Utils/helpers";

import { Edit } from "../../../Assets/Icons";

export default function AddFunds(props) {
  const { isRecurringDeposit, addFromBank, addFromCard, onFormSubmit } = props;

  const modalContext = useModalContext();

  const { watch, handleSubmit } = useFormContext();

  const { appUtilityProps } = useAppUtilityContext();

  const { isLoadingAstraCards, astraDebitCards } = useAstra();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) => account.account_id === connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [connectedAccounts?.data?.data?.accounts, connectedAccountSelection?.data?.data?.selected]
  );

  const selectedAstraCard = useMemo(
    () => getSelectedAstraCard(astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId),
    [astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId]
  );

  const dailyAddFundsLimit = useMemo(() => {
    const dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_add_cash_limit ?? 0) / 100;
    return dailyLimit;
  }, [stakeDebit]);

  const accountDescription = useMemo(() => {
    return !!selectedAccount ? `${selectedAccount?.name} (...${selectedAccount?.mask})` : "";
  }, [selectedAccount]);

  const instantAccountDescription = useMemo(() => {
    return !!selectedAstraCard ? `${selectedAstraCard?.card_company} (...${selectedAstraCard?.last_four_digits})` : "";
  }, [selectedAstraCard]);

  const feeLabel = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_OUT_OF_STAKE_FEE_RATE ?? 0.0175;

    if (addFromCard && isNaN(cashAmount)) {
      return `${(feePercentage * 100).toFixed(2)}%`;
    } else if (addFromCard) {
      return formatCurrency(cashAmount * feePercentage);
    } else {
      return "Free";
    }
  }, [deals, cashAmount, addFromCard]);

  const cashAmountIncludingFees = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_INTO_STAKE_FEE_RATE ?? 0;

    if (addFromCard) {
      if (isNaN(cashAmount)) {
        return 0;
      }

      return cashAmount + cashAmount * feePercentage;
    } else {
      return cashAmount;
    }
  }, [cashAmount, addFromCard, deals]);

  const transferAmountLabel = useMemo(() => {
    if (addFromCard) {
      return `Add Cash ${formatCurrency(cashAmountIncludingFees)}`;
    } else {
      return isRecurringDeposit ? "Schedule" : "Add Cash";
    }
  }, [addFromCard, isRecurringDeposit, cashAmountIncludingFees]);

  const onSubmit = () => {
    if ((addFromBank && !selectedAccount) || (addFromCard && !selectedAstraCard)) {
      dispatchErrorToast(addFromCard ? "Please add a debit card to proceed" : "Please add a bank account to proceed", {
        key: addFromCard ? "noAstraDebitCard" : "noConnectedAccount",
      });
    } else if (dailyAddFundsLimit > 0 && cashAmount > dailyAddFundsLimit) {
      dispatchErrorToast("Amount cannot exceed the daily limit.", {
        key: "amountExceedsThreshold",
      });
    } else {
      onFormSubmit({
        cashAmount,
        ...(addFromCard ? { selectedAstraCard } : { selectedAccount }),
      });
    }
  };

  const handleSelectPaymentEntity = () => {
    if (addFromCard) {
      renderSelectDebitCards(modalContext);
    } else {
      renderSelectConnectedAccounts(modalContext);
    }
  };

  const isLoading =
    isLoadingDeals ||
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingConnectedAccountSelection ||
    isLoadingAstraCards;

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  return isLoading ? (
    <ModalSkeleton />
  ) : (
    <form className="recurring-deposit content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="top-container">
        <AmountField />
        {!!dailyAddFundsLimit && (
          <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyAddFundsLimit)}</span>
        )}
        {addFromCard && <span className="max-no-of-transfers-badge mt-2">Max Transfer Limit: 2 per day</span>}
      </div>
      <div className="bottom-container">
        <div className="details">
          <div className="detail-row">
            <span className="detail-label">Account</span>
            <span className="detail-value">
              {addFromCard
                ? !!selectedAstraCard
                  ? instantAccountDescription
                  : "Add Card"
                : !!selectedAccount
                ? accountDescription
                : "Connect a bank account"}
              <button className="edit" type="button" onClick={handleSelectPaymentEntity}>
                <Edit />
              </button>
            </span>
          </div>
          <div className="detail-row">
            <span className="detail-label">Fee</span>
            <span className="detail-value">{feeLabel}</span>
          </div>
          <div className="detail-row">
            <div className="info">
              {addFromCard
                ? "Transfer speed depends on your bank and could take up to 30 minutes."
                : isRecurringDeposit
                ? "Transfers take one business day to process.\nTransfers set up on a weekend, or holiday, will occur on the next business day."
                : addFromBank
                ? "2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."
                : ""}
            </div>
          </div>
        </div>
        <PrimaryButton className="blue-theme mt-sm-6 mt-4" type="submit" disabled={disableSubmitButton}>
          {transferAmountLabel}
        </PrimaryButton>
      </div>
    </form>
  );
}
