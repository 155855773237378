import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import AmountField from "../../Components/Form Fields/amount-field";
import PrimaryButton from "../../Components/Buttons/primary-button";

import renderInstantTransferIneligible from "../../Organisms/Modals/Cards/instant-transfer-ineligible";
import renderAstraSignUp from "../../Organisms/Modals/Cards/astra-sign-up";
import renderStatusModal from "../../Organisms/Modals/status-modal";
import renderInstantTransferLimitReached from "../../Organisms/Modals/Cards/instant-transfer-limit-reached";
import renderSelectConnectedAccounts from "../../Organisms/Modals/Bank Account/select-connected-accounts";
import renderSelectDebitCards from "../../Organisms/Modals/Cards/select-debit-cards";

import {
  useActivities,
  useConnectedAccounts,
  useConnectedAccountSelection,
  useDeals,
  useStakeDebit,
} from "../../CustomHooks/Queries";
import {
  useInitiatePlaidWithdrawal,
  useWithdrawal,
  useCreateAccountToCardTransferRoutine,
  useCreateAccountToCardTransferRoutineWithoutAuthToken,
} from "../../CustomHooks/Mutations";

import useAstra from "../../CustomHooks/useAstra";

import withFormProvider from "../../HOC/withFormProvider";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext";

import { getTransferRoutinePayload } from "../../Utils/api-payload-generators";
import {
  dispatchErrorToast,
  formatCurrency,
  getTransferTypes,
  isSVDApproved,
  getSelectedAstraCard,
  isSelectedTransferMethodInstant,
  isSelectedTransferMethodBank,
  getAstraCardsWithSystemCardFiltered,
} from "../../Utils/helpers";
import { FailedTransferStatusModalProps, SuccessfulTransferStatusModalProps } from "../../Utils/constants";

import { ArrowLeftCircle, Edit } from "../../Assets/Icons";

function CashOut() {
  const modalContext = useModalContext();

  const navigate = useNavigate();

  const { appUtilityProps, setAppUtilityProps } = useAppUtilityContext();

  const [selectedTransferType, setSelectedTransferType] = useState(appUtilityProps?.preferredTransferType ?? "ach");

  const { watch, handleSubmit } = useFormContext();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const {
    isCreatingUserIntent: isAstraCreatingUserIntent,
    isLoadingRoutesCapabilities,
    isCreatingTrustedAuthenticationToken,
    isLoadingAstraCards,
    isInstantTransferIneligible,
    openAstraPortal,
    astraDebitCards,
  } = useAstra();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const isAstraAccountConnected = useMemo(
    () => getAstraCardsWithSystemCardFiltered(astraDebitCards, stakeDebit).length > 0,
    [astraDebitCards, stakeDebit]
  );

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) =>
          account.account_id === appUtilityProps?.selectedAccountId ?? connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [
      appUtilityProps?.selectedAccountId,
      connectedAccounts?.data?.data?.accounts,
      connectedAccountSelection?.data?.data?.selected,
    ]
  );

  const selectedAstraCard = useMemo(
    () => getSelectedAstraCard(astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId),
    [astraDebitCards, stakeDebit, appUtilityProps?.selectedCardId]
  );

  const SVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const instantAccountDescription = useMemo(() => {
    return !!selectedAstraCard ? `${selectedAstraCard?.card_company} (...${selectedAstraCard?.last_four_digits})` : "";
  }, [selectedAstraCard]);

  const accountDescription = useMemo(() => {
    return !!selectedAccount ? `${selectedAccount?.name} (...${selectedAccount?.mask})` : "";
  }, [selectedAccount]);

  const dailyCashOutLimit = useMemo(() => {
    let dailyLimit;
    if (SVDApproved) {
      dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_cash_out_limit ?? 0) / 100;
    } else {
      dailyLimit = deals?.data?.content?.transfer_out_limit ?? 1000;
    }
    return dailyLimit;
  }, [stakeDebit, deals, SVDApproved]);

  const transferTypes = useMemo(() => getTransferTypes(deals?.data), [deals]);

  const onWithDrawSuccess = () => {
    const GoToBanking = () => navigate("/banking");
    modalContext.updateModalOnCloseCallBack(GoToBanking);
    renderStatusModal(modalContext, {
      ...SuccessfulTransferStatusModalProps,
      description: isSelectedTransferMethodInstant(selectedTransferType)
        ? `Your transfer is expected to arrive in a few minutes.`
        : `Your transfer is expected to arrive on ${deals?.data?.content?.transaction_clearing_date}.`,
      onCTAButtonClick: () => modalContext.onModalClose({ callOnClose: GoToBanking }),
    });
  };

  const onWithDrawError = (errorProps = {}) =>
    renderStatusModal(modalContext, {
      ...FailedTransferStatusModalProps,
      ...errorProps,
      onCTAButtonClick: modalContext.onModalClose,
    });

  const onInstantWithDrawError = (err) => {
    if (err?.response?.status === 429) renderInstantTransferLimitReached(modalContext);
    else onWithDrawError();
  };

  const { isLoading: isWithdrawing, mutate: Withdraw } = useWithdrawal({
    onSuccess: onWithDrawSuccess,
    onError: () => onWithDrawError(),
  });

  const { isLoading: isInitiatingPlaidWithdrawal, mutate: InitiatePlaidWithdrawal } = useInitiatePlaidWithdrawal({
    onSuccess: () => {
      if (SVDApproved) onWithDrawSuccess();
      else Withdraw({ amount: String(cashAmount), withdrawMode: "Stripe" });
    },
    onError: (err) => onWithDrawError(err?.response?.status === 422 ? { description: err?.response?.data?.error } : {}),
  });

  const { isLoading: isCreatingAccountToCardRoutine, mutate: CreateAccountToCardRoutine } =
    useCreateAccountToCardTransferRoutine({
      onSuccess: () => onWithDrawSuccess(),
      onError: onInstantWithDrawError,
    });

  const { isLoading: isCreatingAccountToCardRoutineWithoutAuth, mutate: CreateAccountToCardRoutineWithoutAuth } =
    useCreateAccountToCardTransferRoutineWithoutAuthToken({
      onSuccess: () => onWithDrawSuccess(),
      onError: () => onWithDrawError(),
    });

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  useEffect(() => {
    if (isSelectedTransferMethodInstant(selectedTransferType) && isInstantTransferIneligible) {
      setSelectedTransferType("ach");
    }
  }, [selectedTransferType, isInstantTransferIneligible]);

  const onSubmit = () => {
    if (
      (isSelectedTransferMethodBank(selectedTransferType) && !selectedAccount) ||
      (isSelectedTransferMethodInstant(selectedTransferType) && !selectedAstraCard)
    ) {
      dispatchErrorToast(
        isSelectedTransferMethodInstant(selectedTransferType)
          ? "Please add a debit card to proceed"
          : "Please add a bank account to proceed",
        {
          alignToAppContent: true,
          key: isSelectedTransferMethodInstant(selectedTransferType) ? "noAstraDebitCard" : "noConnectedAccount",
        }
      );
    } else if (dailyCashOutLimit > 0 && cashAmount > dailyCashOutLimit) {
      dispatchErrorToast("Amount cannot exceed the daily limit.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else if (cashAmount > activitiesData?.data?.data?.currentBalance) {
      dispatchErrorToast("Amount exceeds what you have available to transfer.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else {
      if (isSelectedTransferMethodInstant(selectedTransferType)) {
        const request = getTransferRoutinePayload({
          destinationCard: selectedAstraCard,
          amount: cashAmount,
        });
        if (SVDApproved) {
          CreateAccountToCardRoutine(request);
        } else {
          CreateAccountToCardRoutineWithoutAuth(request);
        }
      } else {
        InitiatePlaidWithdrawal({
          acctName: selectedAccount.name,
          acctId: selectedAccount?.account_id,
          amount: cashAmount,
        });
      }
    }
  };

  const handleChangeTransferType = (selectedType) => {
    if (isSelectedTransferMethodInstant(selectedType) && isInstantTransferIneligible) {
      renderInstantTransferIneligible(modalContext);
      return;
    }

    if (isSelectedTransferMethodInstant(selectedType) && !isAstraAccountConnected) {
      renderAstraSignUp(modalContext, openAstraPortal);
    }

    setSelectedTransferType(selectedType);
    setAppUtilityProps((p) => ({
      ...p,
      preferredTransferType: selectedType,
    }));
  };

  const handleSelectPaymentEntity = () => {
    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      renderSelectDebitCards(modalContext);
    } else {
      renderSelectConnectedAccounts(modalContext);
    }
  };

  const feeLabel = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_OUT_OF_STAKE_FEE_RATE ?? 0;

    if (isSelectedTransferMethodInstant(selectedTransferType) && isNaN(cashAmount)) {
      return `${(feePercentage * 100).toFixed(2)}%`;
    } else if (isSelectedTransferMethodInstant(selectedTransferType)) {
      return formatCurrency(cashAmount * feePercentage);
    } else {
      return "Free";
    }
  }, [deals, cashAmount, selectedTransferType]);

  const cashAmountIncludingFees = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_OUT_OF_STAKE_FEE_RATE ?? 0;

    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      if (isNaN(cashAmount)) {
        return 0;
      }

      return cashAmount + cashAmount * feePercentage;
    } else {
      return cashAmount;
    }
  }, [cashAmount, selectedTransferType, deals]);

  const transferAmountLabel = useMemo(() => {
    if (isSelectedTransferMethodInstant(selectedTransferType)) {
      return `Transfer Cash ${formatCurrency(cashAmountIncludingFees)}`;
    } else {
      return "Transfer Cash";
    }
  }, [selectedTransferType, cashAmountIncludingFees]);

  const isLoading =
    isLoadingDeals ||
    isLoadingActivities ||
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingConnectedAccountSelection ||
    isLoadingAstraCards;

  const isSettingUpAstra =
    isAstraCreatingUserIntent || isLoadingRoutesCapabilities || isCreatingTrustedAuthenticationToken;

  const isSubmitting =
    isInitiatingPlaidWithdrawal ||
    isWithdrawing ||
    isCreatingAccountToCardRoutine ||
    isCreatingAccountToCardRoutineWithoutAuth;

  return (
    <div className="cash-out">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={navigate.bind(this, -1)}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Cash Out</h3>
              <div className="dummy"></div>
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <form className="content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="top-container">
                  <AmountField />
                  {!!dailyCashOutLimit && (
                    <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyCashOutLimit)}</span>
                  )}
                  {isSelectedTransferMethodInstant(selectedTransferType) && (
                    <span className="max-no-of-transfers-badge mt-2">Max Transfer Limit: 2 per day</span>
                  )}
                </div>
                <div className="bottom-container">
                  <div className="transfer-types mb-6">
                    {transferTypes.map((transferType, idx) => (
                      <button
                        key={idx}
                        className={`transfer-type ${transferType.type}${
                          transferType.type === selectedTransferType ? " selected" : ""
                        }`}
                        type="button"
                        onClick={handleChangeTransferType.bind(this, transferType.type)}
                        disabled={isSelectedTransferMethodInstant(transferType.type) && isSettingUpAstra}
                      >
                        <span className="icon-wrapper">
                          <transferType.Icon className="icon" />
                        </span>
                        <div className="title">{transferType.title}</div>
                        {isSelectedTransferMethodInstant(transferType.type) && isSettingUpAstra ? (
                          <CircularProgress />
                        ) : (
                          <span className="description">{transferType.description}</span>
                        )}
                        {isSelectedTransferMethodInstant(transferType.type) && isInstantTransferIneligible && (
                          <span className="restricted-badge">Restricted</span>
                        )}
                      </button>
                    ))}
                  </div>
                  <div className="details">
                    <div className="detail-row">
                      <span className="detail-label">Account</span>
                      <span className="detail-value">
                        {isSelectedTransferMethodInstant(selectedTransferType)
                          ? !!selectedAstraCard
                            ? instantAccountDescription
                            : "Add Card"
                          : !!selectedAccount
                          ? accountDescription
                          : "Connect a bank account"}
                        <button className="edit" type="button" onClick={handleSelectPaymentEntity}>
                          <Edit />
                        </button>
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Fee</span>
                      <span className="detail-value">{feeLabel}</span>
                    </div>
                    <div className="detail-row">
                      <div className="info">
                        {isSelectedTransferMethodInstant(selectedTransferType)
                          ? "Transfer speed depends on your bank and could take up to 30 minutes."
                          : "2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."}
                      </div>
                    </div>
                  </div>
                  <PrimaryButton
                    className="blue-theme mt-sm-6 mt-4"
                    type="submit"
                    disabled={disableSubmitButton || isSubmitting}
                  >
                    {transferAmountLabel} {isSubmitting && <CircularProgress />}
                  </PrimaryButton>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default withFormProvider(CashOut);
