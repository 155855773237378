import { useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import moment from "moment";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useDeals } from "../../../CustomHooks/Queries";

import { appendISOTime, formatCurrency, formatDate } from "../../../Utils/helpers";
import { modalVariants, nilYearLongMonthDateFormat, longMonthDateFormat } from "../../../Utils/constants";

export default function renderAuthorizeTransfer(modalContext, props) {
  const { updateModalComponent, pushModalStack } = modalContext;

  pushModalStack(() => renderAuthorizeTransfer(modalContext, props));
  updateModalComponent(() => <AuthorizeTransfer {...props} />);
  setAuthorizeTransferModalProps(modalContext);
}

export const setAuthorizeTransferModalProps = (modalContext) => {
  const { setModalVariant, setModalTitle, setModalClassName } = modalContext;
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("has-transfer-info full-modal-width hide-back-button");
  setModalTitle("Authorize this transaction");
};

export const AuthorizeTransfer = (props) => {
  const {
    stake_account_number_last4,
    stake_routing_number,
    selectedAccount,
    selectedAstraCard,
    clearingTransactionDate,
    amount,
    isRecurringDeposit = false,
    recurringDepositPreferences,
    AuthorizeCallback,
  } = props;

  const modalContext = useModalContext();

  const { popModalStack } = modalContext;

  const [isLoading, setIsLoading] = useState(false);

  const { data: deals } = useDeals();

  const formattedCurrentDate = useMemo(() => {
    return formatDate(new Date(), longMonthDateFormat);
  }, []);

  const formattedRecurringStartDate = useMemo(() => {
    return formatDate(appendISOTime(recurringDepositPreferences?.start_date), nilYearLongMonthDateFormat);
  }, [recurringDepositPreferences?.start_date]);

  const recurringDayOfTheWeek = useMemo(() => {
    if (!!recurringDepositPreferences?.start_date) {
      const date = moment(recurringDepositPreferences.start_date);
      return date.format("dddd");
    }
    return null;
  }, [recurringDepositPreferences?.start_date]);

  const feeLabel = useMemo(() => {
    const feePercentage = deals?.data?.data?.currentDeal?.INSTANT_TRANSFER_OUT_OF_STAKE_FEE_RATE ?? 0.0175;

    if (!!selectedAstraCard) {
      return formatCurrency(amount * feePercentage);
    } else {
      return "Free";
    }
  }, [deals, amount, selectedAstraCard]);

  const handleAuthorize = () => {
    setIsLoading(true);
    AuthorizeCallback();
  };

  return (
    <>
      <div className="modal-dialog-content">
        <div className="transfer-detail">
          <span className="detail-label">{isRecurringDeposit ? "Starts From" : "Debit On"}</span>
          <span className="detail-value">
            {isRecurringDeposit ? formattedRecurringStartDate : formattedCurrentDate}
          </span>
        </div>
        {isRecurringDeposit && (
          <div className="transfer-detail">
            <span className="detail-label">Frequency</span>
            <span className="detail-value">
              {recurringDepositPreferences?.frequency_type}
              {recurringDepositPreferences?.frequency_type !== "Monthly" && `, ${recurringDayOfTheWeek}`}
            </span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Payment Method</span>
          <span className="detail-value">{!!selectedAstraCard ? "Debit Card" : "Bank Account"}</span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Debit From</span>
          <span className="detail-value">
            {!!selectedAstraCard ? selectedAstraCard.card_company : selectedAccount?.name}
            (...
            {!!selectedAstraCard ? selectedAstraCard.last_four_digits : selectedAccount?.mask})
          </span>
        </div>
        {!!selectedAccount && (
          <div className="transfer-detail">
            <span className="detail-label">Bank Routing Number</span>
            <span className="detail-value">{selectedAccount?.routing}</span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Credit To</span>
          <span className="detail-value">
            Stake Checking (...
            {stake_account_number_last4})
          </span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Stake Routing Number</span>
          <span className="detail-value">{stake_routing_number}</span>
        </div>
        {!!selectedAccount && (
          <div className="transfer-detail">
            <span className="detail-label">Expected Arrival On</span>
            <span className="detail-value">{clearingTransactionDate}</span>
          </div>
        )}
        <div className="transfer-detail">
          <span className="detail-label">Amount{isRecurringDeposit && " per period"}</span>
          <span className="detail-value">{formatCurrency(amount)}</span>
        </div>
        <div className="transfer-detail">
          <span className="detail-label">Fees</span>
          <span className="detail-value">{feeLabel}</span>
        </div>
        <span className="disclaimer mt-4">
          *
          {!!selectedAstraCard
            ? "Transfer speed depends on your bank and could take up to 30 minutes."
            : isRecurringDeposit
            ? "Transfers take one business day to process. Transfers set up on a weekend, or holiday, will occur on the next business day."
            : "2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day."}
          *
        </span>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={handleAuthorize} disabled={isLoading}>
          Authorize {isLoading && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={() => popModalStack(1)}>
          Back
        </PrimaryButton>
      </div>
    </>
  );
};
