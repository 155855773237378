import { DebitCards } from "../../../Pages/Debit Cards";

import { modalVariants } from "../../../Utils/constants";

export default function renderSelectDebitCards(modalContext, props) {
  const { updateModalComponent, setModalTitle, setModalClassName, setModalVariant, pushModalStack } = modalContext;

  pushModalStack(() => renderSelectDebitCards(modalContext, props));
  updateModalComponent(() => <DebitCards presentedForSelection {...props} />);
  setModalTitle("Debit Cards");
  setModalClassName("rendering-page-content");
  setModalVariant(modalVariants.formDialog);
}
