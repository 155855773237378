import { Collapse, Box, TextField } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import { useFormContext } from "react-hook-form";
import moment from "moment";

import useCollapseComponents from "../../CustomHooks/useCollapseComponents";

import { formatCurrency, getNumberWithOrdinal } from "../../Utils/helpers";

import { CashBack, CircleExclamationOutlined } from "../../Assets/Icons";

export const RentPaymentItem = ({
  rentPaymentLabel,
  rentPaymentValue,
  rentPaymentDetails,
  onKnowMore,
  openCollapse = false,
}) => {
  const { showListItem, CollapseButton } = useCollapseComponents(openCollapse);

  return (
    <div className="rent-payment-item">
      <div className="info">
        <div className="item-detail">
          <span className="item-label">
            {rentPaymentLabel}
            {!!onKnowMore && (
              <button className="know-more" type="button" onClick={onKnowMore}>
                <CircleExclamationOutlined />
              </button>
            )}
          </span>
          <CollapseButton className="item-value">{rentPaymentValue}</CollapseButton>
        </div>
        {!!rentPaymentDetails && (
          <Collapse in={showListItem}>
            <div className="payment-item-list mt-2">
              {rentPaymentDetails?.map(({ subItemName, subItemDetails }, idx) => {
                return (
                  <div key={idx} className="item-detail-group">
                    <div className="item-detail highlight">
                      <span className="item-label">{subItemName}</span>
                    </div>
                    {subItemDetails?.map(({ chargeName, chargeValue }, idx) => {
                      return (
                        <div key={idx} className="item-detail">
                          <span className="item-label">{chargeName}</span>
                          <span className="item-value pad-to-align">{formatCurrency(chargeValue)}</span>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
};

export function StakePayCashBackDay(props) {
  const { cashBackDays: { startDate, endDate } = {}, day, outsideCurrentMonth, selected, ...other } = props;

  const isCashBackDay = !outsideCurrentMonth && day.isBetween(moment(startDate), moment(endDate), "days", "[]");

  return (
    <PickersDay {...other} selected={selected} outsideCurrentMonth={outsideCurrentMonth} day={day}>
      {day.date()}
      {isCashBackDay ? (
        <Box
          className="cashback-badge"
          sx={{
            display: "flex",
            position: "absolute",
            top: "2px",
            right: "1px",
            ...(isCashBackDay && selected ? { "& svg path": { fill: "var(--white)" } } : {}),
          }}
        >
          <CashBack />
        </Box>
      ) : undefined}
    </PickersDay>
  );
}

export function StakePayCashBackToolBar(props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="8px"
      className={props.className}
      sx={{
        "&.MuiPickersLayout-toolbar": {
          gridRow: "3 !important",
          gridColumn: "2 !important",
          marginTop: "12px",
          font: "normal normal 600 12px/20px Poppins, sans-serif",
          padding: "12px",
          borderRadius: "var(--rounded-md)",
          background: "var(--secondary-50)",
          "& svg": { height: "20px", width: "20px" },
        },
      }}
    >
      <CashBack />
      Earn Cash Back by paying on time
    </Box>
  );
}

export const StakePayDateField = (props) => {
  const { watch } = useFormContext();

  const paymentDate = watch("payment-date");
  const isAutopay = watch("autopay");

  return (
    <TextField
      {...props}
      value={
        isAutopay && !!paymentDate
          ? `${getNumberWithOrdinal(moment(paymentDate).date())} of every month`
          : moment(paymentDate).format("MMMM D, YYYY")
      }
    />
  );
};
